<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/red/album/list"><i class="fas fa-album fa-fw" style="margin-right: 5px;"></i>
                      アルバム一覧
                    </router-link>
                  </li>
                  <li v-if="form && form.id" class="breadcrumb-item">
                    <router-link :to="'/red/album/disp/' + form.id"><i class="fas fa-eye" style="margin-right: 5px;"></i>閲覧
                    </router-link>
                  </li>
                  <li v-if="form && form.id" class="breadcrumb-item active" aria-current="page"><i class="fas fa-edit" style="margin-right: 5px;"></i>編集</li>
                  <li v-else class="breadcrumb-item active" aria-current="page"><i class="fas fa-plus" style="margin-right: 5px;"></i>追加</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <button @click="save" class="btn btn-primary" style="margin-left: 5px;"><i class="fas fa-save fa-fw" style="margin-right: 5px;"></i>保存</button>
              <button v-if="showDeleteButton" @click="openDeleteModal" class="btn btn-danger" style="margin-left: 5px;"><i class="fas fa-trash-alt fa-fw" style="margin-right: 5px;"></i>削除</button>
              <button v-if="form && form.id" @click="showDeleteButton = !showDeleteButton" class="btn" style="padding-right: 0;"><i class="fas fa-caret-left"></i></button>
            </div>
          </div>
        </div>
        <div v-if="form" class="text-start" style="position: fixed; top: 65px; left: 270px; right: 30px; bottom: 50px; overflow: auto;">
          <div class="container">
            <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger"
                style="margin-right: 5px;"></i>タイトル</div>
            <div class="form-edit-line">
              <input v-model="form.name" type="text" class="form-control">
            </div>
            <div class="form-label align-bottom">カナ</div>
            <div class="form-edit-line">
              <input v-model="form.kana" type="text" class="form-control">
            </div>
            <div class="form-label align-bottom">英語タイトル</div>
            <div class="form-edit-line">
              <input v-model="form.nameEn" type="text" class="form-control">
            </div>
            <div class="form-label align-bottom">アーティスト</div>
            <div class="form-edit-line">
              <div class="input-group">
                <input v-model="form.artistName" type="text" class="form-control" readonly="readonly">
                <button v-if="form.artistId" @click="clearSelectedArtist" class="btn btn-outline-primary"><i class="fas fa-undo"></i></button>
                <button @click="openArtistSearch" class="btn btn-primary"><i class="fas fa-search"></i></button>
              </div>
            </div>
            <div class="form-label align-bottom">プロジェクト</div>
            <div class="form-edit-line">
              <div class="input-group">
                <input v-model="form.projectName" type="text" class="form-control" readonly="readonly">
                <button v-if="form.projectId" @click="clearSelectedProject" class="btn btn-outline-primary"><i class="fas fa-undo"></i></button>
                <button @click="openProjectSearch" class="btn btn-primary"><i class="fas fa-search"></i></button>
              </div>
            </div>
            <div class="form-label align-bottom">
              <button @click="openMakerSearch" class="btn btn-outline-primary btn-sm" style="margin-bottom: 5px;"><i class="fas fa-plus"></i></button>
              レコード会社 / レーベル
            </div>
            <div class="form-edit-line">
              <table class="table table-responsive">
                <tbody>
                  <tr v-for="(maker, index) in form.makerList" :key="maker.userId">
                    <td>{{maker.userName}}</td>
                    <td style="width: 60px;" class="text-end">
                      <button @click="form.makerList.splice(index, 1)" class="btn btn-outline-danger btn-sm"><i class="fas fa-trash-alt"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="form-label align-bottom">管理コード</div>
            <div class="form-edit-line">
              <input v-model="form.code" type="text" class="form-control" maxlength="20" style="width: 250px;">
            </div>
            <div class="form-label align-bottom">
              <button @click="addCatalogNo" class="btn btn-outline-primary btn-sm" style="margin-bottom: 5px;"><i class="fas fa-plus"></i></button>
              製品番号
            </div>
            <div v-for="(catalogNo, index) in form.catalogNoList" :key="index" class="form-edit-line">
              <div class="input-group">
                <input v-model="catalogNo.catalogNo" type="text" class="form-control" style="flex: 0 1 250px;" maxlength="30">
                <button @click="deleteCatalogNo(index)" class="btn btn-outline-danger"><i class="fas fa-trash-alt"></i></button>
              </div>
            </div>
            <div class="form-label align-bottom">
              <button @click="addJanCode" class="btn btn-outline-primary btn-sm" style="margin-bottom: 5px;"><i class="fas fa-plus"></i></button>
              JANコード
            </div>
            <div v-for="(janCode, index) in form.janCodeList" :key="index" class="form-edit-line">
              <div class="input-group">
                <input v-model="janCode.janCode" type="text" class="form-control" style="flex: 0 1 250px;" maxlength="30">
                <button @click="deleteJanCode(index)" class="btn btn-outline-danger"><i class="fas fa-trash-alt"></i></button>
              </div>
            </div>
            <div class="form-label align-bottom">発売日</div>
            <div class="form-edit-line">
              <input v-model="form.salesDate" type="date" class="form-control" style="width: 150px;">
            </div>
            <div class="form-label align-bottom">販売価格</div>
            <div class="form-edit-line">
              <div class="input-group">
                <span class="input-group-text">税抜</span>
                <input v-model="form.salesPriceOutTax" type="number" class="form-control" style="flex: 0 1 120px;">
                <span class="input-group-text">税込</span>
                <input v-model="form.salesPriceInTax" type="number" class="form-control" style="flex: 0 1 120px;">
              </div>
            </div>
            <div class="form-label align-bottom">卸価格</div>
            <div class="form-edit-line">
              <div class="input-group">
                <span class="input-group-text">税抜</span>
                <input v-model="form.wholePriceOutTax" type="number" class="form-control" style="flex: 0 1 120px;">
                <span class="input-group-text">税込</span>
                <input v-model="form.wholePriceInTax" type="number" class="form-control" style="flex: 0 1 120px;">
              </div>
            </div>
            <div class="form-label align-bottom">総収録曲数</div>
            <div class="form-edit-line">
              <input v-model="form.allRecordMusicCount" type="number" class="form-control" style="width: 80px;">
            </div>
            <div class="form-label align-bottom">総収録時間</div>
            <div class="form-edit-line">
              <div class="input-group">
                <input v-model="form.playTimeH" type="number" class="form-control" min="0" style="flex: 0 1 65px;">
                <span class="input-group-text">時間</span>
                <input v-model="form.playTimeM" type="number" class="form-control" min="0" max="59" style="flex: 0 1 65px;">
                <span class="input-group-text">分</span>
                <input v-model="form.playTimeS" type="number" class="form-control" min="0" max="59" style="flex: 0 1 65px;">
                <span class="input-group-text">秒</span>
              </div>
            </div>
            <div class="card" style="margin-bottom: 10px;">
              <div class="card-header">計算</div>
              <div class="card-body" style="padding: 10px 5px 5px 10px;">
                <div class="form-label align-bottom">計算単位</div>
                <div class="form-edit-line">
                  <select v-model="form.masterBreakDown" class="form-select">
                    <option value="false">アルバム単位</option>
                    <option value="true">原盤単位</option>
                  </select>
                </div>
                <div class="form-label align-bottom">価格取得元</div>
                <div class="form-edit-line">
                  <select v-model="form.priorityPrice" class="form-select">
                    <option value="data">実績データに価格情報がある場合、これを優先</option>
                    <option value="master">本画面で登録された価格を優先</option>
                  </select>
                </div>
                <div v-if="form.masterBreakDown === false || form.masterBreakDown === 'false'" class="form-label align-bottom">管理割合</div>
                <div v-if="form.masterBreakDown === false || form.masterBreakDown === 'false'" class="form-edit-line">
                  <div class="input-group">
                    <input v-model="form.portionC" class="form-control" type="number" min="1" style="flex: 0 1 100px;">
                    <span class="input-group-text">/</span>
                    <input v-model="form.portionM" class="form-control" type="number" min="1" style="flex: 0 1 100px;">
                  </div>
                </div>
                <div class="form-label align-bottom">計算対象</div>
                <div class="form-edit-line">
                  <select v-model="form.calculateTarget" class="form-select">
                    <option value="true">分配計算の対象とする</option>
                    <option value="false">分配計算の対象外とし、本アルバムに対する入金は全て自社取り分とする</option>
                  </select>
                </div>
                <div v-if="form.calculateTarget === true || form.calculateTarget === 'true'" class="form-label align-bottom">計算保留</div>
                <div v-if="form.calculateTarget === true || form.calculateTarget === 'true'" class="form-edit-line">
                  <select v-model="form.suspension" class="form-select">
                    <option value="false">分配計算の対象とする</option>
                    <option value="true">分配計算を保留する</option>
                  </select>
                </div>
              </div>
            </div>
            <div v-if="form.masterBreakDown === true || form.masterBreakDown === 'true'" class="card" style="margin-bottom: 10px;">
              <div class="card-header">
                収録原盤
                <button @click="openMasterSearch" class="btn btn-outline-primary btn-sm" style="margin-right: 5px;"><i class="fas fa-plus"></i></button>
              </div>
              <div class="card-body" style="padding: 5px;">
                <table v-if="form.recordMusicList && form.recordMusicList.length > 0" class="table table-responsive">
                  <tbody>
                  <tr v-for="(record, index) in form.recordMusicList" :key="index" draggable="true"
                      @dragstart="dragList($event, index)" @drop="dropList($event, index)" @dragover.prevent
                      @dragenter.prevent :class="{'deleted-row': record.deleted}">
                    <td style="width: 40px; vertical-align: middle;">
                      {{index + 1}}
                    </td>
                    <td>
                      <span v-if="record.isrcList.length > 0" style="font-size: 80%;">{{record.isrcList.map(x => x.isrc).join(' / ')}}<br></span>
                      {{record.masterName}}
                      <span v-if="record.masterArtistName" style="font-size: 80%;"><br>{{record.masterArtistName}}</span>
                      <div class="input-group" style="margin-top: 5px;">
                        <select v-model="record.managed" class="form-select form-select-sm" style="flex: 0 1 80px;" :disabled="record.deleted">
                          <option value="true">管理</option>
                          <option value="false">-</option>
                        </select>
                        <span class="input-group-text">時間</span>
                        <input v-model="record.playTimeH" type="number" class="form-control form-control-sm" style="flex: 0 1 60px;" :disabled="record.deleted">
                        <span class="input-group-text">:</span>
                        <input v-model="record.playTimeM" type="number" class="form-control form-control-sm" style="flex: 0 1 60px;" :disabled="record.deleted">
                        <span class="input-group-text">:</span>
                        <input v-model="record.playTimeS" type="number" class="form-control form-control-sm" style="flex: 0 1 60px;" :disabled="record.deleted">
                      </div>
                    </td>
                    <td style="width: 47px; vertical-align: middle;" class="text-end">
                      <button @click="record.deleted = !record.deleted" class="btn btn-outline-danger btn-sm"><i class="fas fa-trash-alt"></i></button>
                    </td>
                    <td style="width: 20px; cursor: grab; vertical-align: middle;"><i class="fas fa-grip-vertical"></i></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-if="form.userCodeList && form.userCodeList.length > 0" class="card" style="margin-bottom: 10px;">
              <div class="card-header">報告コード</div>
              <div class="card-body" style="padding: 5px;">
                <table class="table table-responsive">
                  <tbody>
                  <tr v-for="(userCode, index) in form.userCodeList" :key="index" :class="{ 'deleted-row': userCode.deleted }">
                    <td>{{userCode.userName}}</td>
                    <td>{{userCode.code}}</td>
                    <td style="width: 60px;" class="text-end">
                      <button @click="userCode.deleted = !userCode.deleted" class="btn btn-outline-danger btn-sm"><i class="fas fa-trash-alt"></i></button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card" style="margin-bottom: 10px;">
              <div class="card-header">頒布形態別利用種別指定（取込時に利用種別を省略した場合のみ使用）<button @click="addUserSalesUseTypeList" class="btn btn-outline-primary btn-sm"><i class="fas fa-plus"></i></button></div>
              <div v-if="userSalesUseTypeList.length > 0" class="card-body" style="padding: 10px 5px 5px 10px;">
                <div v-for="(userSalesUseType, index) in userSalesUseTypeList" :key="index">
                  <div class="input-group">
                    <span class="input-group-text">利用者</span>
                    <select v-model="userSalesUseType.userId" @change="changeUser(index, true)" class="form-select">
                      <option v-for="user in userList" :key="user.id" :value="user.id">{{user.name}}</option>
                    </select>
                    <span class="input-group-text">頒布形態</span>
                    <select v-model="userSalesUseType.userSalesId" class="form-select">
                      <option v-for="userSales in userSalesUseType.userSalesList" :key="userSales.id" :value="userSales.id">{{userSales.name}}</option>
                    </select>
                    <span class="input-group-text">利用種別</span>
                    <select v-model="userSalesUseType.useTypeId" class="form-select">
                      <option v-for="useType in useTypeList" :key="useType.id" :value="useType.id">{{useType.name}}</option>
                    </select>
                    <button @click="userSalesUseTypeList.splice(index, 1)" class="btn btn-danger"><i class="fas fa-trash-alt"></i></button>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="suspensionList && suspensionList.length > 0" class="card" style="margin-bottom: 10px;">
              <div class="card-header">保留期</div>
              <div class="card-body" style="padding: 5px;">
                <table class="table table-responsive">
                  <tbody>
                    <tr v-for="suspension in suspensionList" :key="suspension.id">
                      <td>{{suspension.divYear}}年{{suspension.divTerm}}期</td>
                      <td class="text-end">
                        <div class="form-check form-check-inline">
                          <input v-model="suspension.retroactiveTarget" class="form-check-input" type="radio" name="suspensionTarget" id="suspensionTargetPay" value="true">
                          <label class="form-check-label" for="suspensionTargetPay">解除時支払対象</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input v-model="suspension.retroactiveTarget" class="form-check-input" type="radio" name="suspensionTarget" id="suspensionTargetHold" value="false">
                          <label class="form-check-label" for="suspensionTargetHold">解除しても支払わない</label>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="form-label align-bottom">
              ジャケ写
              <button @click="openAddFile" class="btn btn-outline-primary btn-sm"><i class="fas fa-plus"></i></button>
            </div>
            <div class="form-edit-line">
              <table class="table table-responsive">
                <tbody>
                <tr v-for="(blob, index) in blobList" :key="blob.blob">
                  <td>
                    <img v-if="blob.url" :src="blob.url" style="max-width: 300px;">
                  </td>
                  <td style="width: 80px;" class="text-end">
                    <button @click="deleteFile(index)" class="btn btn-danger btn-sm"><i class="fas fa-trash-alt"></i></button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="form-label align-bottom">メモ</div>
            <div class="form-edit-line">
              <textarea class="form-control" rows="8"></textarea>
            </div>
            <div style="height: 50px;"></div>
          </div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="deleteModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">アルバムの削除</h5>
          </div>
          <div v-if="form" class="modal-body text-start">
            <div class="alert alert-danger">
              以下のアルバムを削除しようとしています。削除すると、アルバム情報の確認ができなくなり、該当アルバムが紐づいているアルバム、アルバム、アルバムのアルバム情報がクリアされます。また、該当アルバムに関連する分配結果も削除されますが、過去の支払明細からは削除されません。
            </div>
            <div class="form-label align-bottom">名前</div>
            <div class="form-edit-line">
              {{ form.name }}
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="executeDelete" type="button" class="btn btn-danger" style="margin-right: 5px;"><i class="fas fa-trash-alt" style="margin-right: 5px;"></i>注意事項を理解した上で削除を実行する</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="artistModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">アーティスト選択</h5>
          </div>
          <div class="modal-body text-start">
            <div class="input-group" style="margin-top: 15px;">
              <input @keypress.enter="artistCondition.page = 0; artistFind();" v-model="artistCondition.searchString" type="text" class="form-control" placeholder="検索条件">
              <button v-show="artistCondition.searchString" @click="artistClearSearch" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
              <button @click="artistCondition.page = 0; artistFind();" class="btn btn-primary"><i class="fas fa-search"></i></button>
            </div>
            <table v-if="artistSearchResult && artistSearchResult.allRecords > 0" class="table table-responsive table-hover">
              <thead>
                <tr>
                  <th>名前</th>
                  <th style="width: 70px;"></th>
                </tr>
              </thead>
              <tbody v-if="artistSearchList">
                <tr v-for="(artist, index) in artistSearchList" :key="artist.id" class="text-start">
                  <td>{{ artist.name }}</td>
                  <td class="text-end">
                    <button @click="selectArtist(index)" class="btn btn-outline-primary btn-sm"><i class="fas fa-plus"></i></button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="row">
              <div class="col-6 d-flex justify-content-start">
                <span v-if="artistSearchResult && artistSearchResult.allRecords > 0">{{ artistSearchResult.allRecords }}件の一致データ</span>
                <span v-else style="margin-top: 20px;">一致データが存在しません</span>
              </div>
              <div v-if="artistSearchResult && artistSearchResult.allRecords > 0" class="col-6 d-flex justify-content-end">
                <nav v-if="artistSearchResult" aria-label="Page navigation">
                  <ul class="pagination">
                    <li v-if="artistCondition.page !== 0" class="page-item">
                      <button @click="artistFirstPage" class="page-link" href="#"><i class="fas fa-fast-backward"></i></button>
                    </li>
                    <li v-if="artistCondition.page !== 0" class="page-item">
                      <button @click="artistPrevPage" class="page-link" href="#"><i class="fas fa-chevron-left"></i></button>
                    </li>
                    <li class="page-item"><span class="page-link">{{ artistCondition.page + 1 }}/{{ artistSearchResult.allPages }}ページ</span></li>
                    <li v-if="artistCondition.page !== artistSearchResult.allPages - 1" class="page-item">
                      <button @click="artistNextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button>
                    </li>
                    <li v-if="artistCondition.page !== artistSearchResult.allPages - 1" class="page-item">
                      <button @click="artistLastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="projectModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">プロジェクト選択</h5>
          </div>
          <div class="modal-body text-start">
            <div class="input-group" style="margin-top: 15px;">
              <input @keypress.enter="projectCondition.page = 0; projectFind();" v-model="projectCondition.searchString" type="text" class="form-control" placeholder="検索条件">
              <button v-show="projectCondition.searchString" @click="projectClearSearch" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
              <button @click="projectCondition.page = 0; projectFind();" class="btn btn-primary"><i class="fas fa-search"></i></button>
            </div>
            <table v-if="projectSearchResult && projectSearchResult.allRecords > 0" class="table table-responsive table-hover">
              <thead>
                <tr>
                  <th>名前</th>
                  <th style="width: 70px;"></th>
                </tr>
              </thead>
              <tbody v-if="projectSearchList">
                <tr v-for="(project, index) in projectSearchList" :key="project.id" class="text-start">
                  <td>{{ project.name }}</td>
                  <td class="text-end">
                    <button @click="selectProject(index)" class="btn btn-outline-primary btn-sm"><i class="fas fa-plus"></i></button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="row">
              <div class="col-6 d-flex justify-content-start">
                <span v-if="projectSearchResult && projectSearchResult.allRecords > 0">{{ projectSearchResult.allRecords }}件の一致データ</span>
                <span v-else style="margin-top: 20px;">一致データが存在しません</span>
              </div>
              <div v-if="projectSearchResult && projectSearchResult.allRecords > 0" class="col-6 d-flex justify-content-end">
                <nav v-if="projectSearchResult" aria-label="Page navigation">
                  <ul class="pagination">
                    <li v-if="projectCondition.page !== 0" class="page-item">
                      <button @click="projectFirstPage" class="page-link" href="#"><i class="fas fa-fast-backward"></i></button>
                    </li>
                    <li v-if="projectCondition.page !== 0" class="page-item">
                      <button @click="projectPrevPage" class="page-link" href="#"><i class="fas fa-chevron-left"></i></button>
                    </li>
                    <li class="page-item"><span class="page-link">{{ projectCondition.page + 1 }}/{{ projectSearchResult.allPages }}ページ</span></li>
                    <li v-if="projectCondition.page !== projectSearchResult.allPages - 1" class="page-item">
                      <button @click="projectNextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button>
                    </li>
                    <li v-if="projectCondition.page !== projectSearchResult.allPages - 1" class="page-item">
                      <button @click="projectLastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="makerModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">利用者選択</h5>
          </div>
          <div class="modal-body text-start">
            <div class="input-group" style="margin-top: 15px;">
              <input @keypress.enter="userCondition.page = 0; makerFind();" v-model="userCondition.searchString" type="text" class="form-control" placeholder="検索条件">
              <button v-show="userCondition.searchString" @click="makerClearSearch" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
              <button @click="userCondition.page = 0; makerFind();" class="btn btn-primary"><i class="fas fa-search"></i></button>
            </div>
            <table v-if="makerSearchResult && makerSearchResult.allRecords > 0" class="table table-responsive table-hover">
              <thead>
              <tr>
                <th>名前</th>
                <th style="width: 70px;"></th>
              </tr>
              </thead>
              <tbody v-if="makerSearchList">
              <tr v-for="(maker, index) in makerSearchList" :key="maker.id" class="text-start">
                <td>{{ maker.name }}</td>
                <td class="text-end">
                  <button @click="selectMaker(index)" class="btn btn-outline-primary btn-sm"><i class="fas fa-plus"></i></button>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="row">
              <div class="col-6 d-flex justify-content-start">
                <span v-if="makerSearchResult && makerSearchResult.allRecords > 0">{{ makerSearchResult.allRecords }}件の一致データ</span>
                <span v-else style="margin-top: 20px;">一致データが存在しません</span>
              </div>
              <div v-if="makerSearchResult && makerSearchResult.allRecords > 0" class="col-6 d-flex justify-content-end">
                <nav v-if="makerSearchResult" aria-label="Page navigation">
                  <ul class="pagination">
                    <li v-if="userCondition.page !== 0" class="page-item">
                      <button @click="makerFirstPage" class="page-link" href="#"><i class="fas fa-fast-backward"></i></button>
                    </li>
                    <li v-if="userCondition.page !== 0" class="page-item">
                      <button @click="makerPrevPage" class="page-link" href="#"><i class="fas fa-chevron-left"></i></button>
                    </li>
                    <li class="page-item"><span class="page-link">{{ userCondition.page + 1 }}/{{ makerSearchResult.allPages }}ページ</span></li>
                    <li v-if="userCondition.page !== makerSearchResult.allPages - 1" class="page-item">
                      <button @click="makerNextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button>
                    </li>
                    <li v-if="userCondition.page !== makerSearchResult.allPages - 1" class="page-item">
                      <button @click="makerLastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="masterModalDiv">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">原盤選択</h5>
          </div>
          <div class="modal-body text-start">
            <div class="input-group" style="margin-top: 15px;">
              <input @keypress.enter="masterSearchList = []; masterCondition.page = 0; masterFind();" v-model="masterCondition.searchString" type="text" class="form-control" placeholder="検索条件">
              <button v-show="masterCondition.searchString" @click="masterSearchList = []; masterCondition.searchString = ''; masterCondition.page = 0; masterFind();" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
              <button @click="masterSearchList = []; masterCondition.page = 0; masterFind();" class="btn btn-primary"><i class="fas fa-search"></i></button>
            </div>
            <div class="text-end" style="margin-top: 10px;">
              <span v-if="masterSearchResult && masterSearchResult.allRecords > 0">{{ masterSearchResult.allRecords.toLocaleString() }}件の一致データ</span>
              <span v-else style="margin-top: 20px;">一致データが存在しません</span>
            </div>
            <table v-if="masterSearchResult && masterSearchResult.allRecords > 0" class="table table-responsive table-hover">
              <thead>
              <tr>
                <th style="width: 55px;"></th>
                <th style="width: 150px;">ISRC</th>
                <th>原盤名</th>
                <th>アーティスト</th>
              </tr>
              </thead>
              <tbody v-if="masterSearchList">
              <tr v-for="master in masterSearchList" :key="master.id" class="text-start align-middle" @click="master.selected = !master.selected" :class="{ 'selected-row': master.selected }">
                <td style="width: 55px; vertical-align: middle;" class="text-end">
                  <span v-if="!master.selected"><button class="btn btn-light"><i class="far fa-square"></i></button></span>
                  <span v-if="master.selected"><button class="btn btn-light"><i class="far fa-check-square"></i></button></span>
                </td>
                <td style="width: 150px;">
                  <span v-html="master.isrc"></span>
                </td>
                <td>{{master.displayName}}</td>
                <td>{{master.artistName}}</td>
              </tr>
              </tbody>
              <tfoot v-if="masterSearchResult.allRecords > masterSearchList.length">
              <tr>
                <td colspan="5">
                  <div class="d-grid gap-2">
                    <button @click="masterCondition.page = masterSearchResult.page + 1; masterFind();" class="btn btn-primary"><i class="fas fa-arrow-down" style="margin-right: 5px;"></i>もっと読み込む</button>
                  </div>
                </td>
              </tr>
              </tfoot>
            </table>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="selectMaster" class="btn btn-outline-primary"><i class="fas fa-plus fa-fw" style="margin-right: 5px;"></i>追加</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="fileModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">添付ファイル追加</h5>
          </div>
          <div class="modal-body text-start">
            <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger"
                style="margin-right: 5px;"></i>ファイル</div>
            <div class="form-edit-line">
              <input class="form-control" type="file" id="uploadFile" accept="image/*" ref="uploadFile"
                multiple="multiple">
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i
                  class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="addFile" type="button" class="btn btn-primary"><i
                  class="fas fa-cloud-upload fa-fw"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="processModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">処理中</h5>
          </div>
          <div class="modal-body text-start">
            {{ processMessage }}
          </div>
        </div>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-danger" role="alert" aria-live="assertive" aria-atomic="true"
      ref="errorToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">エラー</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(error, index) in errors" :key="index">{{ error }}</p>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-success" role="alert" aria-live="assertive" aria-atomic="true" ref="messageToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-info-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">メッセージ</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(message, index) in messages" :key="index">{{message}}</p>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { exFunction } from '@/functions/exception'
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import navComponent from '@/components/Nav'
import axios from 'axios'
import { Modal, Toast } from 'bootstrap'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import footerComponent from '@/components/Footer.vue'
import { albumFunction } from './function'
import { masterFunction } from '../master/function'
import { userFunction } from '../user/function'
import { useTypeFunction } from '../usetype/function'
import { artistFunction } from '../../common/artist/function'
import { projectFunction } from '../../common/project/function'
import { redCompanyFunction } from '@/functions/common'
import { artistStore, projectStore, masterStore, userStore } from './store'

export default defineComponent({
  components: { footerComponent, navComponent },
  setup () {
    const ex = exFunction()
    const auth = authFunction()
    const session = sessionStore()
    const route = useRoute()
    const router = useRouter()
    const errors = ref([])
    const errorToastDiv = ref(null)
    let errorToast = null
    const messages = ref([])
    const messageToastDiv = ref(null)
    let messageToast = null
    const deleteModalDiv = ref(null)
    let deleteModal = null
    const showDeleteButton = ref(false)
    const processMessage = ref('')
    const processModalDiv = ref(null)
    let processModal = null
    const fileModalDiv = ref(null)
    let fileModal = null
    const albumFunc = albumFunction()
    const companyFunc = redCompanyFunction()
    const masterFunc = masterFunction()
    const userfunc = userFunction()
    const artistfunc = artistFunction()
    const projectFunc = projectFunction()
    const useTypeFunc = useTypeFunction()
    // Form
    const form = ref(null)
    const isMakeMaster = ref(false)
    const rightAreaMode = ref('MASTER')
    const blobList = ref([])
    // ジャケ写追加
    const uploadFile = ref(null)
    // アーティスト選択
    const artistCondition = artistStore()
    const artistModalDiv = ref(null)
    let artistModal = null
    const artistSearchResult = ref(null)
    const artistSearchList = ref([])
    // プロジェクト選択
    const projectCondition = projectStore()
    const projectModalDiv = ref(null)
    let projectModal = null
    const projectSearchResult = ref(null)
    const projectSearchList = ref([])
    // メーカー選択
    const userCondition = userStore()
    const makerModalDiv = ref(null)
    let makerModal = null
    const makerSearchResult = ref(null)
    const makerSearchList = ref([])
    // 原盤選択
    const masterCondition = masterStore()
    const masterModalDiv = ref(null)
    let masterModal = null
    const masterSearchResult = ref(null)
    const masterSearchList = ref([])
    // 保留
    const suspensionList = ref([])
    // 頒布形態別利用種別
    const userSalesUseTypeList = ref([])
    const userList = ref([])
    const useTypeList = ref([])
    // メソッド
    const save = async () => {
      errors.value = []
      if (!form.value.name) {
        errors.value.push('名前を入力してください')
      }
      if (form.value.masterBreakDown === 'true' || form.value.masterBreakDown === true) {
        if (form.value.recordMusicList.length === 0) {
          errors.value.push('計算単位が原盤単位の時は一つ以上の収録原盤を設定してください')
        }
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        processMessage.value = '保存処理中です。しばらくこのままでお待ちください。'
        processModal.show()
        form.value.photoList = blobList.value
        if (form.value.masterBreakDown === 'false' || form.value.masterBreakDown === false) {
          form.value.allRecordMusicCount = 1
        } else {
          form.value.portionC = 1
          form.value.portionM = 1
        }
        form.value.userSalesUseTypeList = userSalesUseTypeList.value
        try {
          if (!form.value.id) {
            form.value.id = await albumFunc.insert(session.session.company.id, form.value)
          } else {
            await albumFunc.update(form.value.id, form.value)
          }
          albumFunc.indexUpdate(form.value.id)
          const term = await companyFunc.getCurrentDivTerm(session.session.company.id)
          albumFunc.matching(form.value.id, term.id, function () {
            albumFunc.calculate(form.value.id)
          })
          processModal.hide()
          await router.push('/red/album/disp/' + form.value.id)
        } catch (e) {
          processModal.hide()
          if (e.response?.status === 491) {
            errors.value.push('既に登録済みの製品番号です')
            errorToast.show()
          } else if (e.response?.status === 492) {
            errors.value.push('既に登録済みのJANコードです')
            errorToast.show()
          } else {
            console.error(e)
            errors.value = ['システムエラーが発生したため保存されませんでした']
            errorToast.show()
          }
        }
      }
    }
    const openDeleteModal = () => {
      deleteModal.show()
    }
    const executeDelete = async () => {
      await albumFunc.remove(form.value.id)
      deleteModal.hide()
      await router.push('/red/album/list')
    }
    const openArtistSearch = () => {
      artistSearchResult.value = null
      artistSearchList.value = []
      artistCondition.page = 0
      artistCondition.searchString = ''
      artistModal.show()
    }
    const artistFind = async () => {
      artistSearchResult.value = await artistfunc.find(artistCondition)
      artistSearchList.value = artistSearchResult.value.list
    }
    const artistClearSearch = () => {
      artistCondition.searchString = ''
      artistCondition.page = 0
      artistFind()
    }
    const artistFirstPage = () => {
      artistCondition.page = 0
      artistFind()
    }
    const artistPrevPage = () => {
      artistCondition.page = artistCondition.page - 1
      artistFind()
    }
    const artistNextPage = () => {
      artistCondition.page = artistCondition.page + 1
      artistFind()
    }
    const artistLastPage = () => {
      artistCondition.page = artistSearchResult.value.allPages - 1
      artistFind()
    }
    const selectArtist = (index) => {
      const artist = artistSearchList.value[index]
      form.value.artistId = artist.id
      form.value.artistName = artist.name
      artistModal.hide()
    }
    const clearSelectedArtist = () => {
      form.value.artistId = null
      form.value.artistName = ''
    }
    const openProjectSearch = () => {
      projectSearchResult.value = null
      projectSearchList.value = []
      projectCondition.page = 0
      projectCondition.searchString = ''
      projectModal.show()
    }
    const projectFind = async () => {
      projectSearchResult.value = await projectFunc.find(projectCondition)
      projectSearchList.value = projectSearchResult.value.list
    }
    const projectClearSearch = () => {
      projectCondition.searchString = ''
      projectCondition.page = 0
      projectFind()
    }
    const projectFirstPage = () => {
      projectCondition.page = 0
      projectFind()
    }
    const projectPrevPage = () => {
      projectCondition.page = projectCondition.page - 1
      projectFind()
    }
    const projectNextPage = () => {
      projectCondition.page = projectCondition.page + 1
      projectFind()
    }
    const projectLastPage = () => {
      projectCondition.page = projectSearchResult.value.allPages - 1
      projectFind()
    }
    const selectProject = (index) => {
      const project = projectSearchList.value[index]
      form.value.projectId = project.id
      form.value.projectName = project.name
      projectModal.hide()
    }
    const clearSelectedProject = () => {
      form.value.projectId = null
      form.value.projectName = ''
    }
    const openMakerSearch = () => {
      makerSearchResult.value = null
      makerSearchList.value = []
      userCondition.page = 0
      userCondition.searchString = ''
      makerModal.show()
    }
    const makerFind = async () => {
      makerSearchResult.value = await userfunc.find(session.session.company.id, userCondition)
      makerSearchList.value = makerSearchResult.value.list
    }
    const makerClearSearch = () => {
      userCondition.searchString = ''
      userCondition.page = 0
      makerFind()
    }
    const makerFirstPage = () => {
      userCondition.page = 0
      makerFind()
    }
    const makerPrevPage = () => {
      userCondition.page = userCondition.page - 1
      makerFind()
    }
    const makerNextPage = () => {
      userCondition.page = userCondition.page + 1
      makerFind()
    }
    const makerLastPage = () => {
      userCondition.page = makerSearchResult.value.allPages - 1
      makerFind()
    }
    const selectMaker = (index) => {
      const maker = makerSearchList.value[index]
      form.value.makerList.push({
        userId: maker.id,
        makerName: maker.name
      })
      makerModal.hide()
    }
    const openMasterSearch = () => {
      masterSearchResult.value = null
      masterSearchList.value = []
      masterCondition.page = 0
      masterCondition.searchString = ''
      masterModal.show()
    }
    const masterFind = async () => {
      masterSearchResult.value = await masterFunc.find(session.session.company.id, masterCondition)
      masterSearchList.value = masterSearchList.value.concat(masterSearchResult.value.list)
    }
    const masterClearSearch = () => {
      masterCondition.searchString = ''
      masterCondition.page = 0
      masterFind()
    }
    const masterFirstPage = () => {
      masterCondition.page = 0
      masterFind()
    }
    const masterPrevPage = () => {
      masterCondition.page = masterCondition.page - 1
      masterFind()
    }
    const masterNextPage = () => {
      masterCondition.page = masterCondition.page + 1
      masterFind()
    }
    const masterLastPage = () => {
      masterCondition.page = masterSearchResult.value.allPages - 1
      masterFind()
    }
    const selectMaster = () => {
      masterSearchList.value.forEach(master => {
        if (master.selected) {
          const item = {
            masterId: master.id,
            isrcList: [],
            masterName: master.displayName,
            artistName: master.artistName,
            managed: true,
            playTimeH: master.playTimeH,
            playTimeM: master.playTimeM,
            playTimeS: master.playTimeS
          }
          if (master.isrc) {
            item.isrcList = [{ isrc: master.isrc }]
          }
          form.value.recordMusicList.push(item)
        }
      })
      masterModal.hide()
    }
    const dragList = (event, dragIndex) => {
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.setData('drag-index', dragIndex)
    }
    const dropList = (event, dropIndex) => {
      const dragIndex = parseInt(event.dataTransfer.getData('drag-index'))
      const deleteList = form.value.recordMusicList.splice(dragIndex, 1)
      form.value.recordMusicList.splice(dropIndex, 0, deleteList[0])
    }
    const addCatalogNo = () => {
      form.value.catalogNoList.push({
        catalogNo: ''
      })
    }
    const deleteCatalogNo = (index) => {
      form.value.catalogNoList.splice(index, 1)
      if (form.value.catalogNoList.length === 0) {
        addCatalogNo()
      }
    }
    const addJanCode = () => {
      form.value.janCodeList.push({
        janCode: ''
      })
    }
    const deleteJanCode = (index) => {
      form.value.janCodeList.splice(index, 1)
      if (form.value.janCodeList.length === 0) {
        addJanCode()
      }
    }
    const openAddFile = () => {
      uploadFile.value.value = ''
      fileModal.show()
    }
    const addFile = async () => {
      errors.value = []
      if (uploadFile.value.files.length === 0) {
        errors.value.push('ファイルを選択してください')
        errorToast.show()
      } else {
        processMessage.value = 'ファイルのアップロード中です。しばらくお待ちください。'
        processModal.show()
        fileModal.hide()
        for (const file of uploadFile.value.files) {
          const blobName = await getBlobName(file.name)
          const urlUl = await getUrlForUpload(blobName)
          await axios.put(urlUl.toString(), file, {
            headers: { 'Content-Type': 'application/octet-stream' }
          }).catch(error => {
            ex.identifyEx(error)
          })
          const urlDl = await getUrlForDownload(blobName)
          blobList.value.push({
            blobName: blobName,
            url: urlDl
          })
        }
        processModal.hide()
      }
    }
    const deleteFile = (index) => {
      blobList.value.splice(index, 1)
    }
    async function getBlobName (fileName) {
      const { data } = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/attachment/blob', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
        params: {
          fileType: 'artist_photo',
          fileName: fileName
        }
      })
      return data
    }
    async function getUrlForUpload (blobName) {
      const { data } = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/attachment/url/ul', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
        params: { blobName: blobName }
      })
      return data
    }
    async function getUrlForDownload (blobName) {
      const { data } = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/attachment/url/dl', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
        params: { blobName: blobName }
      })
      return data
    }
    const addUserSalesUseTypeList = async () => {
      const userSalesUseType = {}
      if (userList.value.length > 0) {
        userSalesUseType.userId = userList.value[0].id
        userSalesUseType.userSalesList = await findUserSales(userSalesUseType.userId)
        if (userSalesUseType.userSalesList.length > 0) {
          userSalesUseType.userSalesId = userSalesUseType.userSalesList[0].id
        }
      }
      if (useTypeList.value.length > 0) {
        userSalesUseType.useTypeId = useTypeList.value[0].id
      }
      userSalesUseTypeList.value.push(userSalesUseType)
    }
    const findUserSales = async (userId) => {
      return await userfunc.findUserSalesByUser(userId)
    }
    const changeUser = async (index, reload) => {
      if (reload) {
        userSalesUseTypeList.value[index].userSalesId = null
      }
      userSalesUseTypeList.value[index].userSalesList = await findUserSales(userSalesUseTypeList.value[index].userId)
      if (!userSalesUseTypeList.value[index].userSalesId && userSalesUseTypeList.value[index].userSalesList.length > 0) {
        userSalesUseTypeList.value[index].userSalesId = userSalesUseTypeList.value[index].userSalesList[0].id
      }
    }
    const init = async (id) => {
      showDeleteButton.value = false
      if (route.path.includes('/add')) {
        form.value = {
          id: null,
          code: '',
          name: '',
          kana: '',
          nameEn: '',
          artistId: null,
          artistName: '',
          projectId: null,
          projectName: '',
          allRecordMusicCount: null,
          playTimeH: null,
          playTimeM: null,
          playTimeS: null,
          memo: '',
          masterBreakDown: false,
          portionC: 1,
          portionM: 1,
          priorityPrice: 'data',
          calculateTarget: 'true',
          catalogNoList: [{
            catalogNo: ''
          }],
          janCodeList: [{
            janCode: ''
          }],
          makerList: [],
          recordMusicList: [],
          suspension: 'false'
        }
      } else {
        form.value = await albumFunc.get(id)
        userSalesUseTypeList.value = form.value.userSalesUseTypeList
        for (const [index, userSalesUseType] of Object.entries(userSalesUseTypeList.value)) {
          const userSales = await userfunc.getUserSales(userSalesUseType.userSalesId)
          userSalesUseType.userId = userSales.userId
          await changeUser(index, false)
        }
        if (route.path.includes('/copy')) {
          form.value.id = null
          form.value.code = null
          form.value.catalogNoList = [{ catalogNo: '' }]
          form.value.janCodeList = [{ janCode: '' }]
          form.value.name = 'コピー 〜 ' + form.value.name
          messages.value = ['コピーされました。保存ボタンを押すまでは登録されていませんのでご注意ください。']
          messageToast.show()
        }
      }
      userList.value = (await userfunc.findAll(session.session.company.id)).list
      useTypeList.value = await useTypeFunc.find(session.session.company.id)
    }
    onMounted(async () => {
      session.session = await auth.getSession()
      errorToast = new Toast(errorToastDiv.value, {})
      messageToast = new Toast(messageToastDiv.value, {})
      deleteModal = new Modal(deleteModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      artistModal = new Modal(artistModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      projectModal = new Modal(projectModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      makerModal = new Modal(makerModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      masterModal = new Modal(masterModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      processModal = new Modal(processModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      fileModal = new Modal(fileModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      await init(route.params.id)
    })
    return {
      router,
      errors,
      errorToastDiv,
      messages,
      messageToastDiv,
      deleteModalDiv,
      processMessage,
      processModalDiv,
      fileModalDiv,
      showDeleteButton,
      rightAreaMode,
      form,
      isMakeMaster,
      artistModalDiv,
      artistSearchResult,
      artistSearchList,
      projectModalDiv,
      projectSearchResult,
      projectSearchList,
      makerModalDiv,
      makerSearchResult,
      makerSearchList,
      masterModalDiv,
      masterSearchResult,
      masterSearchList,
      save,
      openDeleteModal,
      executeDelete,
      openArtistSearch,
      artistFind,
      artistClearSearch,
      artistFirstPage,
      artistPrevPage,
      artistNextPage,
      artistLastPage,
      selectArtist,
      clearSelectedArtist,
      openProjectSearch,
      projectFind,
      projectClearSearch,
      projectFirstPage,
      projectPrevPage,
      projectNextPage,
      projectLastPage,
      selectProject,
      clearSelectedProject,
      openMakerSearch,
      makerFind,
      makerClearSearch,
      makerFirstPage,
      makerPrevPage,
      makerNextPage,
      makerLastPage,
      selectMaker,
      openMasterSearch,
      masterFind,
      masterClearSearch,
      masterFirstPage,
      masterPrevPage,
      masterNextPage,
      masterLastPage,
      selectMaster,
      addCatalogNo,
      deleteCatalogNo,
      addJanCode,
      deleteJanCode,
      blobList,
      uploadFile,
      openAddFile,
      addFile,
      deleteFile,
      dragList,
      dropList,
      suspensionList,
      artistCondition,
      projectCondition,
      userCondition,
      masterCondition,
      userSalesUseTypeList,
      addUserSalesUseTypeList,
      userList,
      findUserSales,
      changeUser,
      useTypeList
    }
  }
})
</script>
