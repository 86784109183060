<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-6 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-exclamation-triangle fa-fw" style="margin-right: 5px;"></i>利用種別不明一覧</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
            </div>
          </div>
        </div>
        <div style="position: fixed; top: 60px; left: 270px; right: 30px; bottom: 120px; overflow: auto;">
          <table v-if="resultResult && resultResult.allRecords > 0" class="table table-responsive table-hover">
            <thead style="font-size: 90%;">
            <tr>
              <th style="width: 50px;"></th>
              <th>アルバム</th>
              <th>利用者</th>
              <th>頒布形態</th>
            </tr>
            </thead>
            <tbody v-if="resultList" style="font-size: 85%;">
              <tr v-for="(result, index) in resultList" :key="result.id" class="text-start">
                <td class="text-center">
                  <button @click="openDetectModal(index)" class="btn btn-outline-primary btn-sm"><i class="fas fa-link-horizontal fa-fw"></i></button>
                </td>
                <td>{{ result.albumName }}</td>
                <td>{{ result.userName }}</td>
                <td>{{ result.userSalesName }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row" style="position: fixed; bottom: 40px; left: 270px; right: 30px;">
          <div class="col-6 d-flex justify-content-start">
            <span v-if="resultResult && resultResult.allRecords > 0">{{ resultResult.allRecords.toLocaleString() }}件の一致データ</span>
            <span v-else style="margin-top: 20px;">一致データが存在しません</span>
          </div>
          <div v-if="resultResult && resultResult.allRecords > 0" class="col-6 d-flex justify-content-end">
            <nav v-if="resultResult" aria-label="Page navigation">
              <ul class="pagination">
                <li v-if="store.page !== 0" class="page-item"><button @click="firstPage" class="page-link" href="#"><i class="fas fa-fast-backward"></i></button></li>
                <li v-if="store.page !== 0" class="page-item"><button @click="prevPage" class="page-link" href="#"><i class="fas fa-chevron-left"></i></button></li>
                <li class="page-item"><span class="page-link">{{ store.page + 1 }}/{{ resultResult.allPages }}ページ</span></li>
                <li v-if="store.page !== resultResult.allPages - 1" class="page-item"><button @click="nextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button></li>
                <li v-if="store.page !== resultResult.allPages - 1" class="page-item"><button @click="lastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="detectModalDiv">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">利用種別不明解消</h5>
          </div>
          <div class="modal-body text-start">
            <div v-if="detectForm.albumName" class="form-label align-bottom">アルバム名</div>
            <div v-if="detectForm.albumName" class="form-edit-line">
              {{ detectForm.albumName }}
            </div>
            <div v-if="detectForm.userName" class="form-label align-bottom">利用者</div>
            <div v-if="detectForm.userName" class="form-edit-line">
              {{ detectForm.userName }}
            </div>
            <div class="form-label align-bottom">利用種別</div>
            <div class="form-edit-line">
              <select v-model="useTypeId" class="form-select">
                <option v-for="useType in useTypeList" :key="useType.id" :value="useType.id">{{useType.name}}</option>
              </select>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="saveUseType" class="btn btn-outline-primary"><i class="fas fa-save" style="margin-right: 5px;"></i>登録</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="processModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">処理中</h5>
          </div>
          <div class="modal-body text-start">
            {{ processMessage }}
          </div>
        </div>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import footerComponent from '@/components/Footer'
import navComponent from '@/components/Nav'
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useTypeStore } from './store'
import { errorFunction } from './function'
import { useTypeFunction } from '../usetype/function'
import { Modal } from 'bootstrap'
import { albumFunction } from '../album/function'
import { redCompanyFunction } from '@/functions/common'

export default defineComponent({
  components: { navComponent, footerComponent },
  setup () {
    const router = useRouter()
    const auth = authFunction()
    const session = sessionStore()
    const processMessage = ref('')
    const processModalDiv = ref(null)
    let processModal = null
    // 検索
    const store = useTypeStore()
    const errorFunc = errorFunction()
    const resultResult = ref(null)
    const resultList = ref([])
    const detectModalDiv = ref(null)
    let detectModal = null
    const detectForm = ref({})
    const useTypeFunc = useTypeFunction()
    const useTypeList = ref([])
    const useTypeId = ref(null)
    const albumFunc = albumFunction()
    const companyFunc = redCompanyFunction()
    // メソッド
    const find = async () => {
      resultResult.value = await errorFunc.findUseTypeError(session.session.company.id, store)
      resultList.value = resultResult.value.list
    }
    const clearSearch = () => {
      errorFunc.clear(store)
      find()
    }
    const firstPage = () => {
      store.page = 0
      find()
    }
    const prevPage = () => {
      store.page = store.page - 1
      find()
    }
    const nextPage = () => {
      store.page = store.page + 1
      find()
    }
    const lastPage = () => {
      store.page = resultResult.value.allPages - 1
      find()
    }
    const openDetectModal = async (index) => {
      detectForm.value = resultList.value[index]
      useTypeList.value = await useTypeFunc.find(session.session.company.id)
      useTypeId.value = null
      if (useTypeList.value.length > 0) {
        useTypeId.value = useTypeList.value[0].id
      }
      detectModal.show()
    }
    const saveUseType = async () => {
      processMessage.value = '利用種別設定中です。しばらくこのままでお待ちください。'
      processModal.show()
      detectModal.hide()
      await errorFunc.saveUseType(detectForm.value.albumId, detectForm.value.userSalesId, useTypeId.value)
      const term = await companyFunc.getCurrentDivTerm(session.session.company.id)
      albumFunc.matching(detectForm.value.albumId, term.id, async function () {
        console.log('matching end')
        await albumFunc.calculateAsync(detectForm.value.albumId)
        await find()
        processModal.hide()
      })
    }
    onMounted(async () => {
      processModal = new Modal(processModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      detectModal = new Modal(detectModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      session.session = await auth.getSession()
      await find()
    })
    return {
      router,
      processMessage,
      processModalDiv,
      store,
      resultResult,
      resultList,
      find,
      clearSearch,
      firstPage,
      prevPage,
      nextPage,
      lastPage,
      detectModalDiv,
      detectForm,
      openDetectModal,
      useTypeList,
      useTypeId,
      saveUseType
    }
  }
})
</script>
