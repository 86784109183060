<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/red/payment/data/definition/list"><i class="fas fa-database fa-fw" style="margin-right: 5px;"></i>分配データ定義一覧</router-link>
                  </li>
                  <li v-if="form && form.id" class="breadcrumb-item">
                    <router-link :to="'/red/payment/data/definition/disp/' + form.id"><i class="fas fa-eye" style="margin-right: 5px;"></i>閲覧</router-link>
                  </li>
                  <li v-if="form && form.id" class="breadcrumb-item active" aria-current="page"><i class="fas fa-edit" style="margin-right: 5px;"></i>編集</li>
                  <li v-else class="breadcrumb-item active" aria-current="page"><i class="fas fa-plus" style="margin-right: 5px;"></i>追加</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <button @click="save" class="btn btn-primary" style="margin-left: 5px;"><i class="fas fa-save fa-fw" style="margin-right: 5px;"></i>保存</button>
              <button v-if="showDeleteButton && form && form.id && form.deletable" @click="openDeleteModal" class="btn btn-danger" style="margin-left: 5px;"><i class="fas fa-trash-alt fa-fw" style="margin-right: 5px;"></i>削除</button>
              <button v-if="form && form.id && form.deletable" @click="showDeleteButton = !showDeleteButton" class="btn" style="padding-right: 0;"><i class="fas fa-caret-left"></i></button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="form" class="text-start" style="position: fixed; top: 65px; left: 270px; right: 30px; bottom: 50px; overflow: auto;">
        <div class="container">
          <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>名前</div>
          <div class="form-edit-line">
            <input v-model="form.name" type="text" class="form-control">
          </div>
          <div class="form-label align-bottom">データ定義</div>
          <div>
            <div v-for="(item, index) in form.itemList" :key="index" class="input-group" style="margin-bottom: 5px;" draggable="true"
                 @dragstart="dragList($event, index)" @drop="dropList($event, index)" @dragover.prevent @dragenter.prevent>
              <span class="input-group-text" style="flex: 0 1 60px;">{{index + 1}}</span>
              <select v-model="item.category" @change="changeCategory(index)" class="form-select" style="flex: 0 1 120px;">
                <option value="name">名称系</option>
                <option value="code">コード系</option>
                <option value="type">区分系</option>
                <option value="date">日付系</option>
                <option value="number">数字系</option>
              </select>
              <select v-model="item.itemType" class="form-select">
                <template v-if="item.category === 'name'">
                  <option v-for="itemType in nameItemTypeList" :key="itemType.key" :value="itemType.key">{{itemType.name}}</option>
                </template>
                <template v-if="item.category === 'code'">
                  <option v-for="itemType in codeItemTypeList" :key="itemType.key" :value="itemType.key">{{itemType.name}}</option>
                </template>
                <template v-if="item.category === 'type'">
                  <option v-for="itemType in typeItemTypeList" :key="itemType.key" :value="itemType.key">{{itemType.name}}</option>
                </template>
                <template v-if="item.category === 'date'">
                  <option v-for="itemType in dateItemTypeList" :key="itemType.key" :value="itemType.key">{{itemType.name}}</option>
                </template>
                <template v-if="item.category === 'number'">
                  <option v-for="itemType in numberItemTypeList" :key="itemType.key" :value="itemType.key">{{itemType.name}}</option>
                </template>
              </select>
              <button @click="form.itemList.splice(index, 1)" class="btn btn-danger"><i class="fas fa-trash-alt fa-fw"></i></button>
              <span class="input-group-text" style="background-color: inherit; border: none; cursor: grab;"><i class="fas fa-grip-vertical fa-fw"></i></span>
            </div>
            <div style="margin-top: 10px;"><button @click="addItem" class="btn btn-primary"><i class="fas fa-plus fa-fw" style="margin-right: 5px;"></i>追加</button></div>
          </div>
        </div>
        <div style="height: 50px;"></div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="deleteModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">定義の削除</h5>
          </div>
          <div v-if="form" class="modal-body text-start">
            <div class="alert alert-danger">
              以下の分配データ定義を削除しようとしています。削除すると、定義情報の確認ができなくなり、該当定義が紐づいている権利者の分配データフォーマットが標準タイプに変更されます。</div>
            <div class="form-label align-bottom">名前</div>
            <div class="form-edit-line">
              {{ form.name }}
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary">
                <i class="fas fa-times fa-fw"></i>
              </button>
            </div>
            <div>
              <button @click="executeDelete" type="button" class="btn btn-danger" style="margin-right: 5px;">
                <i class="fas fa-trash-alt" style="margin-right: 5px;"></i>注意事項を理解した上で削除を実行する
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-danger" role="alert" aria-live="assertive" aria-atomic="true"
      ref="errorToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">エラー</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(error, index) in errors" :key="index">{{ error }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { exFunction } from '@/functions/exception'
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import navComponent from '@/components/Nav'
import axios from 'axios'
import { Modal, Toast } from 'bootstrap'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import footerComponent from '@/components/Footer.vue'
import { paymentDataDefinitionFunction } from './function'

export default defineComponent({
  components: { footerComponent, navComponent },
  setup () {
    const auth = authFunction()
    const ex = exFunction()
    const session = sessionStore()
    const route = useRoute()
    const router = useRouter()
    const definitionFunc = paymentDataDefinitionFunction()
    const errors = ref([])
    const errorToastDiv = ref(null)
    let errorToast = null
    const deleteModalDiv = ref(null)
    let deleteModal = null
    const showDeleteButton = ref(false)
    // Form
    const form = ref({
      itemList: []
    })
    // メソッド
    const save = async () => {
      errors.value = []
      if (!form.value.name) {
        errors.value.push('名前を入力してください')
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        if (!form.value.id) {
          const response = await axios.post(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/payment/data/definition', form.value, {
            headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
          }).catch(error => {
            ex.identifyEx(error)
            errors.value = ['システムエラーが発生したため保存されませんでした']
            errorToast.show()
          })
          form.value.id = response.data
        } else {
          await axios.put(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/payment/data/definition/' + form.value.id, form.value, {
            headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
          }).catch(error => {
            ex.identifyEx(error)
            errors.value = ['システムエラーが発生したため保存されませんでした']
            errorToast.show()
          })
        }
        await router.push('/red/payment/data/definition/disp/' + form.value.id)
      }
    }
    const openDeleteModal = () => {
      deleteModal.show()
    }
    const executeDelete = async () => {
      await axios.delete(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/payment/data/definition/' + form.value.id, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      })
      deleteModal.hide()
      await router.push('/red/payment/data/definition/list')
    }
    const init = async (id) => {
      showDeleteButton.value = false
      if (route.path.includes('/add')) {
        form.value = {
          id: null,
          name: '',
          itemList: []
        }
      } else {
        form.value = await definitionFunc.get(id)
        for (const item of form.value.itemList) {
          item.category = getCategory(item.itemType)
        }
      }
    }
    const addItem = () => {
      form.value.itemList.push({
        category: 'name',
        itemType: 'albumName'
      })
    }
    const dragList = (event, dragIndex) => {
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.setData('drag-index', dragIndex)
    }
    const dropList = (event, dropIndex) => {
      const dragIndex = event.dataTransfer.getData('drag-index')
      const deleteList = form.value.itemList.splice(dragIndex, 1)
      form.value.itemList.splice(dropIndex, 0, deleteList[0])
    }
    const changeCategory = (index) => {
      const item = form.value.itemList[index]
      if (item.category === 'name') {
        item.itemType = 'albumName'
      }
      if (item.category === 'code') {
        item.itemType = 'catalogNo'
      }
      if (item.category === 'type') {
        item.itemType = 'useType'
      }
      if (item.category === 'date') {
        item.itemType = 'usedYear'
      }
      if (item.category === 'number') {
        item.itemType = 'revenue'
      }
    }
    const getCategory = (key) => {
      let category = ''
      if (nameItemTypeList.some(x => x.key === key)) {
        category = 'name'
      }
      if (codeItemTypeList.some(x => x.key === key)) {
        category = 'code'
      }
      if (typeItemTypeList.some(x => x.key === key)) {
        category = 'type'
      }
      if (dateItemTypeList.some(x => x.key === key)) {
        category = 'date'
      }
      if (numberItemTypeList.some(x => x.key === key)) {
        category = 'number'
      }
      return category
    }
    const nameItemTypeList = [
      { key: 'title', name: 'アルバムタイトル／原盤名' },
      { key: 'albumName', name: 'アルバムタイトル' },
      { key: 'masterName', name: '原盤名' },
      { key: 'albumArtistName', name: 'アルバムアーティスト' },
      { key: 'masterArtistName', name: '原盤アーティスト' },
      { key: 'projectName', name: 'プロジェクト' },
      { key: 'userName', name: '利用者' },
      { key: 'userSalesName', name: '頒布形態' },
      { key: 'rightsHolderName', name: '権利者名' }
    ]
    const codeItemTypeList = [
      { key: 'catalogNo', name: '品番' },
      { key: 'janCode', name: 'JANコード' },
      { key: 'userAlbumCode', name: '利用者アルバムコード' },
      { key: 'isrc', name: 'ISRC' },
      { key: 'masterCode', name: '原盤コード' },
      { key: 'userMasterCode', name: '利用者原盤コード' },
      { key: 'rightsHolderCode', name: '権利者コード' }
    ]
    const typeItemTypeList = [
      { key: 'albumOrMaster', name: 'アルバム／原盤' },
      { key: 'useTypeName', name: '利用種別' },
      { key: 'royaltyTypeName', name: '印税種別' },
      { key: 'calcType', name: '計算式' }
    ]
    const dateItemTypeList = [
      { key: 'usedYear', name: '利用年' },
      { key: 'usedMonth', name: '利用月' }
    ]
    const numberItemTypeList = [
      { key: 'revenue', name: '売上' },
      { key: 'deemedRevenue', name: 'みなし売上' },
      { key: 'income', name: '受領額' },
      { key: 'deemedIncome', name: 'みなし受領額' },
      { key: 'usedNo', name: '使用数' },
      { key: 'usedNo', name: '使用数' },
      { key: 'shipRate', name: '出庫率' },
      { key: 'calcTargetNo', name: '計算対象数' },
      { key: 'price', name: '販売価格' },
      { key: 'deemedPrice', name: 'みなし販売価格' },
      { key: 'jacketDeductionRate', name: 'ジャケ代控除率' },
      { key: 'jacketAmount', name: 'ジャケ代控除額' },
      { key: 'divRate', name: '印税率／分配率' },
      { key: 'unitPrice', name: '印税単価' },
      { key: 'topOff', name: 'トップオフ額' },
      { key: 'basePayment', name: '支払額（按分前）' },
      { key: 'portion', name: 'ポーション' },
      { key: 'albumRecordCount', name: '収録曲数' },
      { key: 'shareRate', name: 'メンバー按分比' },
      { key: 'payment', name: '支払額' },
      { key: 'taxRate', name: '消費税率' }
    ]
    onMounted(async () => {
      session.session = await auth.getSession()
      errorToast = new Toast(errorToastDiv.value, {})
      deleteModal = new Modal(deleteModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      await init(route.params.id)
    })
    return {
      router,
      errors,
      errorToastDiv,
      deleteModalDiv,
      showDeleteButton,
      form,
      save,
      openDeleteModal,
      executeDelete,
      addItem,
      dragList,
      dropList,
      nameItemTypeList,
      changeCategory,
      codeItemTypeList,
      typeItemTypeList,
      numberItemTypeList,
      dateItemTypeList
    }
  }
})
</script>
