<template>
  <div>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-4 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item active" aria-current="page">
                    <i class="fas fa-database fa-fw" style="margin-right: 5px;"></i> [管理者用] RED移行
                  </li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <button @click="convertHeader" type="button" class="btn btn-primary" style="margin-right: 5px;"><i class="fas fa-file-import fa-fw" style="margin-right: 5px;"></i>明細ヘッダ移行</button>
              <button @click="indexRebuild" type="button" class="btn btn-primary" style="margin-right: 5px;"><i class="fas fa-file-import fa-fw" style="margin-right: 5px;"></i>移行後インデックス構築</button>
              <button @click="convert" type="button" class="btn btn-primary"><i class="fas fa-file-import fa-fw" style="margin-right: 5px;"></i>データ移行開始</button>
            </div>
          </div>
        </div>
        <div class="text-start" style="position: fixed; top: 70px; left: 270px; right: 30px; bottom: 120px; overflow: auto;">
          <div class="container">
            <div class="form-label align-bottom">移行対象</div>
            <div class="form-edit-line">
              <div class="form-check form-check-inline">
                <input v-model="convertTarget" class="form-check-input" type="radio" name="convertTarget" id="convertTargetAll" value="all">
                <label class="form-check-label" for="convertTargetAll">contanoから全マスタ</label>
              </div>
              <div class="form-check form-check-inline">
                <input v-model="convertTarget" class="form-check-input" type="radio" name="convertTarget" id="convertTargetRh" value="rh">
                <label class="form-check-label" for="convertTargetRh">Excelから権利者</label>
              </div>
            </div>
            <div v-if="convertTarget === 'all'" class="form-label align-bottom">移行元会社（contano）</div>
            <div v-if="convertTarget === 'all'" class="form-edit-line">
              <select v-model="sco0010Id" class="form-select">
                <option v-for="sco0010 in sco0010List" :key="sco0010.sco0010Id" :value="sco0010.sco0010Id">
                  {{sco0010.dpCompanyName}}
                </option>
              </select>
            </div>
            <div class="form-label align-bottom">移行先会社（RED）</div>
            <div class="form-edit-line">
              <select v-model="companyId" class="form-select">
                <option v-for="company in companyList" :key="company.id" :value="company.id">
                  {{company.nameDp}}（契約：{{company.contractName}}）
                </option>
              </select>
            </div>
            <div class="form-label align-bottom">権利者データファイル</div>
            <div class="form-edit-line">
              <input class="form-control" type="file" id="rhFile" accept=".xlsx" ref="rhFile">
            </div>
            <div class="form-label align-bottom">初期化</div>
            <div class="form-edit-line">
              <div class="form-check form-switch">
                <input v-model="initData" class="form-check-input" type="checkbox" id="initCheck">
                <label class="form-check-label" for="initCheck">データを全て削除してから移行する</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="processModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">処理中</h5>
          </div>
          <div class="modal-body text-start">
            {{processMessage}}
          </div>
        </div>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-success" role="alert" aria-live="assertive" aria-atomic="true" ref="messageToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-info-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">メッセージ</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(message, index) in messages" :key="index">{{message}}</p>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-danger" role="alert" aria-live="assertive" aria-atomic="true" ref="errorToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">エラー</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(error, index) in errors" :key="index">{{error}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import { defineComponent, onMounted, ref } from 'vue'
import { Modal, Toast } from 'bootstrap'
import navComponent from '@/components/Nav.vue'
import axios from 'axios'

export default defineComponent({
  components: { navComponent },
  setup () {
    const auth = authFunction()
    const session = sessionStore()
    const processMessage = ref('')
    const processModalDiv = ref(null)
    const messages = ref([])
    const errors = ref([])
    const messageToastDiv = ref(null)
    const errorToastDiv = ref(null)
    let processModal = null
    let messageToast = null
    let errorToast = null
    const convertTarget = ref('all')
    const initData = ref(false)
    const sco0010Id = ref(null)
    const companyId = ref(null)
    const sco0010List = ref([])
    const companyList = ref([])
    const rhFile = ref(null)
    const indexRebuild = async () => {
      processMessage.value = 'インデックス構築です。しばらくこのままでお待ちください。'
      processModal.show()
      const { data } = await axios.get(process.env.VUE_APP_RED_CONV_ENDPOINT + '/company/' + companyId.value, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      })
      const contractId = data.contractId
      console.log('start artist')
      await axios.post(process.env.VUE_APP_RED_CONV_ENDPOINT + '/index/artist', {}, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
        params: {
          companyId: companyId.value
        }
      })
      console.log('start project')
      await axios.post(process.env.VUE_APP_RED_CONV_ENDPOINT + '/index/project', {}, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
        params: {
          companyId: companyId.value
        }
      })
      console.log('start bank')
      await axios.post(process.env.VUE_APP_RED_CONV_ENDPOINT + '/index/bank', {}, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
        params: {
          companyId: companyId.value
        }
      })
      console.log('start addressee')
      await axios.post(process.env.VUE_APP_RED_CONV_ENDPOINT + '/index/addressee', {}, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
        params: {
          contractId: contractId
        }
      })
      console.log('start payee')
      await axios.post(process.env.VUE_APP_RED_CONV_ENDPOINT + '/index/payee', {}, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
        params: {
          contractId: contractId
        }
      })
      console.log('start album')
      await axios.post(process.env.VUE_APP_RED_CONV_ENDPOINT + '/index/album', {}, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
        params: {
          companyId: companyId.value
        }
      })
      console.log('start master')
      await axios.post(process.env.VUE_APP_RED_CONV_ENDPOINT + '/index/master', {}, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
        params: {
          companyId: companyId.value
        }
      })
      console.log('start rightsholder')
      await axios.post(process.env.VUE_APP_RED_CONV_ENDPOINT + '/index/rightsholder', {}, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
        params: {
          companyId: companyId.value
        }
      })
      console.log('start contract')
      await axios.post(process.env.VUE_APP_RED_CONV_ENDPOINT + '/index/contract', {}, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
        params: {
          companyId: companyId.value
        }
      })
      console.log('start user')
      await axios.post(process.env.VUE_APP_RED_CONV_ENDPOINT + '/index/user', {}, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
        params: {
          companyId: companyId.value
        }
      })
      messages.value = ['インデックス構築が完了しました']
      messageToast.show()
      processModal.hide()
    }
    const convert = async () => {
      errors.value = []
      processMessage.value = 'データ移行中です。しばらくこのままでお待ちください。'
      processModal.show()
      if (convertTarget.value === 'all') {
        const { data } = await axios.get(process.env.VUE_APP_RED_CONV_ENDPOINT + '/company/' + companyId.value, {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
        })
        const contractId = data.contractId
        await axios.post(process.env.VUE_APP_RED_CONV_ENDPOINT + '/convert', {}, {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
          params: {
            sco0010Id: sco0010Id.value,
            companyId: companyId.value,
            contractId: contractId,
            init: initData.value
          }
        }).then(() => {
          messages.value = ['データ移行が完了しました']
          messageToast.show()
        }).catch(error => {
          console.error(error)
          errors.value.push('データ移行でエラーが発生しました')
          errorToast.show()
        })
      } else {
        const file = rhFile.value.files[0]
        if (file) {
          const formData = new FormData()
          formData.append('uploadFile', file)
          await axios.post(process.env.VUE_APP_RED_CONV_ENDPOINT + '/convert/rightsholder', formData, {
            headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
            params: {
              companyId: companyId.value,
              init: initData.value
            }
          }).then(() => {
            messages.value = ['データ移行が完了しました']
            messageToast.show()
          }).catch(error => {
            console.error(error)
            errors.value.push('データ移行でエラーが発生しました')
            errorToast.show()
          })
          rhFile.value.value = null
        } else {
          errors.value.push('ファイルを選択してください')
          errorToast.show()
        }
      }
      processModal.hide()
    }
    const convertHeader = async () => {
      processMessage.value = 'データ移行中です。しばらくこのままでお待ちください。'
      processModal.show()
      await axios.post(process.env.VUE_APP_RED_CONV_ENDPOINT + '/convert/payment', {}, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
        params: {
          companyId: 'd74f8263-d982-436a-9996-5c7031f1bed5',
          divTermId: '3e5ee988-b814-46fa-9aa3-3e9236a26e47',
          sco0010Id: 30,
          divYear: 2023,
          divMonth: 2
        }
      }).catch(error => {
        console.log('header error')
        console.error(error)
      })
      processModal.hide()
    }
    onMounted(async () => {
      messageToast = new Toast(messageToastDiv.value, {})
      errorToast = new Toast(errorToastDiv.value, {})
      processModal = new Modal(processModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      session.session = await auth.getSession()
      axios.get(process.env.VUE_APP_RED_CONV_ENDPOINT + '/sco0010/list', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        sco0010List.value = response.data
        if (sco0010List.value.length > 0) {
          sco0010Id.value = sco0010List.value[0].sco0010Id
        }
      })
      axios.get(process.env.VUE_APP_RED_CONV_ENDPOINT + '/company/list', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        console.log(response)
        companyList.value = response.data
        if (companyList.value.length > 0) {
          companyId.value = companyList.value[0].id
        }
      })
    })
    return {
      messages,
      errors,
      processMessage,
      processModalDiv,
      messageToastDiv,
      errorToastDiv,
      convertTarget,
      sco0010List,
      companyList,
      sco0010Id,
      companyId,
      initData,
      rhFile,
      convert,
      convertHeader,
      indexRebuild
    }
  }
})
</script>
