import axios from 'axios'
import { authFunction } from '@/functions/auth'
import { exFunction } from '@/functions/exception'

export const paymentPdfDefinitionFunction = () => {
  const auth = authFunction()
  const ex = exFunction()
  const get = async (id) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/payment/pdf/definition/' + id, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const find = async () => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/payment/pdf/definition/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const insert = async (form) => {
    const { data } = await axios.post(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/payment/pdf/definition', form, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const update = async (id, form) => {
    await axios.put(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/payment/pdf/definition/' + id, form, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
  }
  const remove = async (id) => {
    await axios.delete(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/payment/data/definition/' + id, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
  }
  const findPdfFormat = async () => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/payment/pdf/format/all', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    })
    return data
  }
  const findPdfFormatItem = async (pdfFormatId) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/payment/pdf/format/' + pdfFormatId + '/item/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    })
    return data
  }
  const getPdfFormatItem = async (pdfFormatId, columnNo) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/payment/pdf/format/' + pdfFormatId + '/item/' + columnNo, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    })
    return data
  }
  return { get, find, insert, update, remove, findPdfFormat, findPdfFormatItem, getPdfFormatItem }
}
