<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/red/rightsholder/list"><i class="fas fa-copyright fa-fw" style="margin-right: 5px;"></i>
                      権利者一覧
                    </router-link>
                  </li>
                  <li v-if="form && form.id" class="breadcrumb-item">
                    <router-link :to="'/red/rightsholder/disp/' + form.id"><i class="fas fa-eye" style="margin-right: 5px;"></i>閲覧</router-link>
                  </li>
                  <li v-if="form && form.id" class="breadcrumb-item active" aria-current="page"><i class="fas fa-edit" style="margin-right: 5px;"></i>編集</li>
                  <li v-else class="breadcrumb-item active" aria-current="page"><i class="fas fa-plus" style="margin-right: 5px;"></i>追加</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <button @click="save" class="btn btn-primary" style="margin-left: 5px;"><i class="fas fa-save fa-fw" style="margin-right: 5px;"></i>保存</button>
              <button v-if="showDeleteButton" @click="openDeleteModal" class="btn btn-danger" style="margin-left: 5px;"><i class="fas fa-trash-alt fa-fw" style="margin-right: 5px;"></i>削除</button>
              <button v-if="form && form.id" @click="showDeleteButton = !showDeleteButton" class="btn" style="padding-right: 0;"><i class="fas fa-caret-left"></i></button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="form" class="text-start" style="position: fixed; top: 65px; left: 270px; right: 30px; bottom: 50px; overflow: auto;">
        <div class="container">
          <div class="form-label align-bottom">グループ</div>
          <div class="form-edit-line">
            <div class="form-check form-switch">
              <input v-model="form.groupRightsHolder" @change="changeGroup" class="form-check-input" type="checkbox" id="groupRightsHolder">
              <label class="form-check-label" for="groupRightsHolder">グループ権利者</label>
            </div>
          </div>
          <div class="form-label align-bottom">コード</div>
          <div class="form-edit-line">
            <input v-model="form.code" type="text" class="form-control" maxlength="20" style="width: 230px;">
          </div>
          <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>名前</div>
          <div class="form-edit-line">
            <input v-model="form.name" type="text" class="form-control">
          </div>
          <div class="form-label align-bottom">カナ</div>
          <div class="form-edit-line">
            <input v-model="form.kana" type="text" class="form-control">
          </div>
          <div v-if="!form.groupRightsHolder">
            <div class="form-label align-bottom">居住地</div>
            <div class="form-edit-line">
              <div class="form-check form-check-inline">
                <input v-model="form.domesticOrOversees" class="form-check-input" type="radio" name="domesticOrOversees" id="domesticOrOversees_d" value="domestic">
                <label class="form-check-label" for="domesticOrOversees_d">日本</label>
              </div>
              <div class="form-check form-check-inline">
                <input v-model="form.domesticOrOversees" class="form-check-input" type="radio" name="domesticOrOversees" id="domesticOrOversees_o" value="oversees">
                <label class="form-check-label" for="domesticOrOversees_o">その他</label>
              </div>
            </div>
            <div v-if="form.domesticOrOversees === 'domestic'" class="form-label align-bottom">住所</div>
            <div v-if="form.domesticOrOversees === 'domestic'" class="form-edit-line">
              <div class="input-group">
                <span class="input-group-text">〒</span>
                <input v-model="form.postalCode" @keydown.enter="getAddress(form.postalCode, '')" type="text" class="form-control" style="flex: 0 1 100px;" maxlength="7">
                <span class="input-group-text" style="font-size: 90%;">（「-」なし7桁）</span>
                <button @click="getAddress(form.postalCode, '')" class="btn btn-outline-primary"><i class="fas fa-search fa-fw"></i></button>
              </div>
            </div>
            <div v-if="form.domesticOrOversees === 'domestic'" class="form-edit-line">
              <div class="input-group">
                <span class="input-group-text">都道府県</span>
                <input v-model="form.prefecture" type="text" class="form-control" style="flex: 0 1 200px;">
              </div>
            </div>
            <div v-if="form.domesticOrOversees === 'domestic'" class="form-edit-line">
              <div class="input-group">
                <span class="input-group-text">市区町村</span>
                <input v-model="form.city" type="text" class="form-control" style="flex: 0 1 200px;">
              </div>
            </div>
            <div v-if="form.domesticOrOversees === 'domestic'" class="form-edit-line">
              <div class="input-group">
                <span class="input-group-text" style="flex: 0 1 90px;">町域</span>
                <input v-model="form.townArea" type="text" class="form-control">
              </div>
            </div>
            <div v-if="!form.groupRightsHolder && form.domesticOrOversees === 'domestic'" class="form-edit-line">
              <div class="input-group">
                <span class="input-group-text" style="flex: 0 1 90px;">建物</span>
                <input v-model="form.building" type="text" class="form-control">
              </div>
            </div>
            <div v-if="form.domesticOrOversees === 'oversees'" class="form-label align-bottom">住所</div>
            <div v-if="form.domesticOrOversees === 'oversees'" class="form-edit-line">
              <textarea v-model="form.addressEn" class="form-control" rows="4"></textarea>
            </div>
            <div class="form-label align-bottom">電話番号</div>
            <div class="form-edit-line">
              <input v-model="form.tel" type="text" class="form-control" style="width: 200px;">
            </div>
            <div class="form-label align-bottom">メールアドレス</div>
            <div class="form-edit-line">
              <input v-model="form.email" type="text" class="form-control">
            </div>
          </div>
          <div v-if="!form.groupRightsHolder" class="accordion" id="accordionCalc" style="margin-top: 15px;">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseCalc" aria-expanded="true" aria-controls="collapseCalc">
                  計算
                </button>
              </h2>
              <div id="collapseCalc" class="accordion-collapse collapse show" data-bs-parent="#accordionCalc">
                <div class="accordion-body">
                  <div v-if="!form.groupRightsHolder" class="form-label align-bottom">支払／保留</div>
                  <div v-if="!form.groupRightsHolder" class="form-edit-line">
                    <div class="input-group">
                      <select v-model="form.payOrCarryForward" class="form-select" style="flex: 0 1 100px;">
                        <option value="pay">支払</option>
                        <option value="carry_forward">保留</option>
                      </select>
                      <span v-if="form.payOrCarryForward === 'carry_forward'" class="input-group-text">理由</span>
                      <input v-if="form.payOrCarryForward === 'carry_forward'" v-model="form.carryForwardReason" type="text" class="form-control">
                    </div>
                  </div>
                  <div v-if="!form.groupRightsHolder" class="form-label align-bottom">消費税</div>
                  <div v-if="!form.groupRightsHolder" class="form-edit-line">
                    <select v-model="form.taxation" class="form-select" style="width: 100px;">
                      <option value="true">課税</option>
                      <option value="false">非課税</option>
                    </select>
                  </div>
                  <div v-if="!form.groupRightsHolder" class="form-label align-bottom">源泉税率</div>
                  <div v-if="!form.groupRightsHolder" class="form-edit-line">
                    <div class="input-group">
                      <select v-model="form.withholdingTaxation" class="form-select" style="flex: 0 1 250px;">
                        <option value="personal">個人（10.21% 〜 20.42%）</option>
                        <option value="fix">定率</option>
                        <option value="none">非課税</option>
                      </select>
                      <input v-if="form.withholdingTaxation === 'fix'" v-model="form.withholding_rate" type="number" class="form-control" style="flex: 0 1 80px;">
                      <span v-if="form.withholdingTaxation === 'fix'" class="input-group-text">%</span>
                    </div>
                  </div>
                  <div v-if="!form.groupRightsHolder" class="form-label align-bottom">管理手数料率</div>
                  <div v-if="!form.groupRightsHolder" class="form-edit-line">
                    <div class="input-group">
                      <input v-model="form.managementFeeRate" type="number" class="form-control" style="flex: 0 1 80px;">
                      <span class="input-group-text">%</span>
                    </div>
                  </div>
                  <div v-if="!form.groupRightsHolder" class="form-label align-bottom">少額保留下限額</div>
                  <div v-if="!form.groupRightsHolder" class="form-edit-line">
                    <div class="input-group">
                      <span class="input-group-text">￥</span>
                      <input v-model="form.carriedForwardLine" type="number" class="form-control" style="flex: 0 1 120px;">
                    </div>
                  </div>
                  <div v-if="!form.groupRightsHolder" class="form-label align-bottom">期設定</div>
                  <table v-if="form.divTermList && !form.groupRightsHolder" class="table table-responsive" style="max-width: 400px;">
                    <thead>
                    <tr>
                      <th style="width: 40px;">期</th>
                      <th>利用月</th>
                      <th style="width: 80px;">支払対象</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="divTermSetting in form.divTermList" :key="divTermSetting.divTerm">
                      <td>{{divTermSetting.divTerm}}</td>
                      <td>
                        <span v-if="divTermSetting.startMonthYearType === 'previous'">前年</span>
                        <span v-if="divTermSetting.startMonthYearType === 'current'">当年</span>
                        {{divTermSetting.startMonth}}月
                        〜
                        <span v-if="divTermSetting.endMonthYearType === 'current'">当年</span>
                        <span v-if="divTermSetting.endMonthYearType === 'next'">翌年</span>
                        {{divTermSetting.endMonth}}月
                      </td>
                      <td class="text-center">
                        <div class="form-check form-switch">
                          <input v-model="divTermSetting.target" class="form-check-input" type="checkbox" id="divTarget">
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!form.groupRightsHolder" class="accordion" id="accordionStatement" style="margin-top: 15px;">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseStatement" aria-expanded="true" aria-controls="collapseStatement">
                  支払計算書
                </button>
              </h2>
              <div id="collapseStatement" class="accordion-collapse collapse show" data-bs-parent="#accordionStatement">
                <div class="accordion-body">
                  <div v-if="!form.groupRightsHolder" class="form-label align-bottom">計算書言語</div>
                  <div v-if="!form.groupRightsHolder" class="form-edit-line">
                    <select v-model="form.statementLang" class="form-select" style="width: 150px;">
                      <option value="japanese">日本語</option>
                      <option value="english">英語</option>
                    </select>
                  </div>
                  <div v-if="!form.groupRightsHolder" class="form-label align-bottom">明細PDFフォーマット</div>
                  <div v-if="!form.groupRightsHolder" class="form-edit-line">
                    <select v-model="form.paymentPdfDefinitionId" class="form-select">
                      <option v-for="pdfFormat in paymentPdfDefinitionList" :key="pdfFormat.id" :value="pdfFormat.id">{{pdfFormat.name}}</option>
                    </select>
                  </div>
                  <div v-if="!form.groupRightsHolder" class="form-label align-bottom">明細データフォーマット</div>
                  <div v-if="!form.groupRightsHolder" class="form-edit-line">
                    <select v-model="form.paymentDataDefinitionId" class="form-select">
                      <option v-for="definition in paymentDataDefinitionList" :key="definition.id" :value="definition.id">{{definition.name}}</option>
                    </select>
                  </div>
                  <div v-if="!form.groupRightsHolder" class="form-label align-bottom">0円明細</div>
                  <div v-if="!form.groupRightsHolder" class="form-edit-line">
                    <select v-model="form.statementDetailZeroOmit" class="form-select" style="width: 150px;">
                      <option value="false">出力する</option>
                      <option value="true">出力しない</option>
                    </select>
                  </div>
                  <div v-if="!form.groupRightsHolder" class="form-label align-bottom">明細書PDF一括DLの対象</div>
                  <div v-if="!form.groupRightsHolder" class="form-edit-line">
                    <select v-model="form.omitAllPdf" class="form-select" style="width: 150px;">
                      <option value="false">対象とする</option>
                      <option value="true">対象外とする</option>
                    </select>
                  </div>
                  <div v-if="!form.groupRightsHolder" class="form-label align-bottom">支払予定日の設定</div>
                  <div v-if="!form.groupRightsHolder" class="form-edit-line">
                    <div class="form-check form-switch">
                      <input v-model="form.usePaymentDateSetting" class="form-check-input" type="checkbox" id="usePaymentDateSetting">
                      <label class="form-check-label" for="usePaymentDateSetting">権利者で個別設定</label>
                    </div>
                  </div>
                  <div v-if="form.usePaymentDateSetting && !form.groupRigthtsHolder" class="form-edit-line">
                    <div class="input-group">
                      <select v-model="form.paymentDateType" class="form-select" style="flex: 0 1 90px;">
                        <option value="last">末日</option>
                        <option value="exact">指定</option>
                      </select>
                      <input v-if="form.paymentDateType !== 'last'" v-model="form.paymentDate" type="number" class="form-control" style="flex: 0 1 90px;">
                      <span v-if="form.paymentDateType !== 'last'" class="input-group-text">日</span>
                      <select v-model="form.paymentOnHoliday" class="form-select">
                        <option value="before">休日の場合は前営業日</option>
                        <option value="after">休日の場合は翌営業日</option>
                      </select>
                    </div>
                  </div>
                  <div class="accordion" id="accordionInvoice">
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseInvoice" aria-expanded="true" aria-controls="collapseInvoice">
                          インボイス制度
                        </button>
                      </h2>
                      <div id="collapseInvoice" class="accordion-collapse collapse show" data-bs-parent="#accordionInvoice">
                        <div class="accordion-body">
                          <div class="form-label align-bottom">課税・免税事業者</div>
                          <div class="form-edit-line">
                            <select v-model="form.taxationType" class="form-select">
                              <option value="taxation">課税事業者</option>
                              <option value="exemption">免税事業者</option>
                            </select>
                          </div>
                          <div v-if="form.taxationType === 'exemption'" class="form-label align-bottom">課税事業者への変更予定</div>
                          <div v-if="form.taxationType === 'exemption'" class="form-edit-line">
                            <select v-model="form.toTaxationSchedule" class="form-select">
                              <option value="true">あり</option>
                              <option value="false">なし</option>
                            </select>
                          </div>
                          <div v-if="form.taxationType === 'taxation'" class="form-label align-bottom">インボイス事業者登録番号</div>
                          <div v-if="form.taxationType === 'taxation'" class="form-edit-line">
                            <div class="input-group">
                              <span class="input-group-text">T</span>
                              <input v-model="form.invoiceNo" type="text" class="form-control" style="flex: 0 1 160px;" maxlength="13">
                              <button @click="checkInvoiceNo" class="btn btn-outline-primary">存在確認</button>
                            </div>
                          </div>
                          <div v-if="form.taxationType === 'exemption'" class="form-label align-bottom">免税事業者に対する扱い</div>
                          <div v-if="form.taxationType === 'exemption'" class="form-edit-line">
                            <div class="form-check form-switch">
                              <input v-model="form.useRightsHolderSetting" class="form-check-input" type="checkbox" id="useRightsHolderSetting">
                              <label class="form-check-label" for="useRightsHolderSetting">権利者で個別設定</label>
                            </div>
                          </div>
                          <div v-if="form.taxationType === 'exemption' && form.useRightsHolderSetting" class="form-label align-bottom">免税事業者に対する消費税相当額の扱い</div>
                          <div v-if="form.taxationType === 'exemption' && form.useRightsHolderSetting" class="form-edit-line">
                            <select v-model="form.invoiceExemptionTaxPayable" class="form-select">
                              <option value="pay">課税事業者と同様に全額支払う</option>
                              <option value="deduct">消費税相当額を支払額から控除する</option>
                            </select>
                          </div>
                          <div v-if="form.taxationType === 'exemption' && form.useRightsHolderSetting && form.invoiceExemptionTaxPayable === 'deduct'" class="form-label align-bottom">少額特例への対応</div>
                          <div v-if="form.taxationType === 'exemption' && form.useRightsHolderSetting && form.invoiceExemptionTaxPayable === 'deduct'" class="form-edit-line">
                            <select v-model="form.invoiceSmallException" class="form-select">
                              <option value="adapt">対応する</option>
                              <option value="not_adapt">対応しない</option>
                            </select>
                          </div>
                          <div v-if="form.taxationType === 'exemption' && form.useRightsHolderSetting && form.invoiceExemptionTaxPayable === 'deduct'" class="form-label align-bottom">経過措置への対応</div>
                          <div v-if="form.taxationType === 'exemption' && form.useRightsHolderSetting && form.invoiceExemptionTaxPayable === 'deduct'" class="form-edit-line">
                            <select v-model="form.invoiceTransitionalMeasures" class="form-select">
                              <option value="adapt">対応する</option>
                              <option value="not_adapt">対応しない</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion" id="accordionSend" style="margin-top: 10px;">
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSend" aria-expanded="true" aria-controls="collapseSend">
                          送付先
                        </button>
                      </h2>
                      <div id="collapseSend" class="accordion-collapse collapse show" data-bs-parent="#accordionSend">
                        <div class="accordion-body">
                          <div v-if="!form.groupRightsHolder" class="form-label align-bottom">計算書送付方法</div>
                          <div v-if="!form.groupRightsHolder" class="form-edit-line">
                            <select v-model="form.statementSendType" class="form-select" style="width: 150px;">
                              <option value="online">オンライン</option>
                              <option value="paper">郵送</option>
                            </select>
                          </div>
                          <div v-if="!form.groupRightsHolder && form.statementSendType === 'online'" class="form-label align-bottom">
                            GREEN送付先
                            <button @click="openDestinationSearch" class="btn btn-outline-primary btn-sm"><i class="fas fa-plus"></i></button>
                          </div>
                          <div v-if="!form.groupRightsHolder && form.statementSendType === 'online'" class="form-edit-line">
                            <table class="table table-responsive">
                              <tbody>
                              <tr v-for="(destination, index) in form.destinationList" :key="index" :class="{'deleted-row': destination.deleted}">
                                <td>{{destination.destinationName}}</td>
                                <td style="width: 60px;" class="text-end">
                                  <button @click="destination.deleted = !destination.deleted" class="btn btn-outline-danger btn-sm"><i class="fas fa-trash-alt"></i></button>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="form-edit-line">
                            <div class="form-check form-check-inline">
                              <input v-model="form.statementAddresseeType" class="form-check-input" type="radio" name="statementAddresseeType" id="statement_input" value="input">
                              <label class="form-check-label" for="statement_input">入力</label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input v-model="form.statementAddresseeType" class="form-check-input" type="radio" name="statementAddresseeType" id="statement_master" value="master">
                              <label class="form-check-label" for="statement_master">送付先選択</label>
                            </div>
                          </div>
                          <div v-if="form.statementAddresseeType === 'master'" class="form-label align-bottom">送付先</div>
                          <div v-if="form.statementAddresseeType === 'master'" class="form-edit-line">
                            <div class="input-group">
                              <input v-model="form.statementAddresseeName" type="text" class="form-control" readonly="readonly">
                              <button v-if="form.statementAddresseeId" @click="clearSelectedAddressee('STATEMENT')" class="btn btn-outline-primary"><i
                                  class="fas fa-undo"></i></button>
                              <button @click="openAddresseeSearch('STATEMENT')" class="btn btn-primary"><i class="fas fa-search"></i></button>
                            </div>
                          </div>
                          <div v-if="form.statementAddresseeType === 'input'" style="margin-bottom: 5px;">
                            <button @click="copyStatement" class="btn btn-outline-primary"><i class="fas fa-copy" style="margin-right: 5px;"></i>基本情報からコピー</button>
                          </div>
                          <div v-if="form.statementAddresseeType === 'input'" class="form-label align-bottom">名前</div>
                          <div v-if="form.statementAddresseeType === 'input'" class="form-edit-line">
                            <input v-model="form.statementName" type="text" class="form-control">
                          </div>
                          <div v-if="form.statementAddresseeType === 'input'" class="form-label align-bottom">敬称</div>
                          <div v-if="form.statementAddresseeType === 'input'" class="form-edit-line">
                            <div class="input-group" style="width: 200px;">
                              <select v-model="form.statementHonorificPosition" @change="changeHonorificPosition('STATEMENT')" class="form-select">
                                <option value="">なし</option>
                                <option value="BEFORE">前</option>
                                <option value="AFTER">後</option>
                              </select>
                              <select v-if="form.statementHonorificPosition === 'BEFORE'" v-model="form.statementHonorificTitle" class="form-select">
                                <option value="Mr.">Mr.</option>
                                <option value="Ms.">Ms.</option>
                                <option value="Mrs.">Mrs.</option>
                                <option value="Miss">Miss</option>
                                <option value="Sir">Sir</option>
                                <option value="Mstr.">Mstr.</option>
                                <option value="Esq.">Esq.</option>
                                <option value="Mx.">Mx.</option>
                                <option value="Dr.">Dr.</option>
                                <option value="Prof.">Prof.</option>
                              </select>
                              <select v-if="form.statementHonorificPosition === 'AFTER'" v-model="form.statementHonorificTitle" class="form-select">
                                <option value="様">様</option>
                                <option value="殿">殿</option>
                                <option value="さん">さん</option>
                                <option value="先生">先生</option>
                                <option value="御中">御中</option>
                                <option value="行">行</option>
                                <option value="宛">宛</option>
                                <option value="各位">各位</option>
                              </select>
                            </div>
                          </div>
                          <div v-if="form.statementAddresseeType === 'input'" class="form-label align-bottom">居住地</div>
                          <div v-if="form.statementAddresseeType === 'input'" class="form-edit-line">
                            <div class="form-check form-check-inline">
                              <input v-model="form.statementDomesticOrOversees" class="form-check-input" type="radio" name="statementDomesticOrOversees" id="statementDomesticOrOversees_d" value="domestic">
                              <label class="form-check-label" for="statementDomesticOrOversees_d">日本</label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input v-model="form.statementDomesticOrOversees" class="form-check-input" type="radio" name="statementDomesticOrOversees" id="statementDomesticOrOversees_o" value="oversees">
                              <label class="form-check-label" for="statementDomesticOrOversees_o">その他</label>
                            </div>
                          </div>
                          <div v-if="form.statementAddresseeType === 'input' && form.statementDomesticOrOversees === 'domestic'" class="form-label align-bottom">住所</div>
                          <div v-if="form.statementAddresseeType === 'input' && form.statementDomesticOrOversees === 'domestic'" class="form-edit-line">
                            <div class="input-group">
                              <span class="input-group-text">〒</span>
                              <input v-model="form.statementPostalCode" @keydown.enter="getAddress(form.statementPostalCode, 'STATEMENT')" type="text" class="form-control" style="flex: 0 1 100px;" maxlength="7">
                              <span class="input-group-text" style="font-size: 90%;">（「-」なし7桁で入力）</span>
                              <button @click="getAddress" class="btn btn-outline-primary"><i class="fas fa-search fa-fw"></i></button>
                            </div>
                          </div>
                          <div v-if="form.statementAddresseeType === 'input' && form.statementDomesticOrOversees === 'domestic'" class="form-edit-line">
                            <div class="input-group">
                              <span class="input-group-text">都道府県</span>
                              <input v-model="form.statementPrefecture" type="text" class="form-control" style="flex: 0 1 200px;">
                            </div>
                          </div>
                          <div v-if="form.statementAddresseeType === 'input' && form.statementDomesticOrOversees === 'domestic'" class="form-edit-line">
                            <div class="input-group">
                              <span class="input-group-text">市区町村</span>
                              <input v-model="form.statementCity" type="text" class="form-control" style="flex: 0 1 200px;">
                            </div>
                          </div>
                          <div v-if="form.statementAddresseeType === 'input' && form.statementDomesticOrOversees === 'domestic'" class="form-edit-line">
                            <div class="input-group">
                              <span class="input-group-text" style="flex: 0 1 90px;">町域</span>
                              <input v-model="form.statementTownArea" type="text" class="form-control">
                            </div>
                          </div>
                          <div v-if="form.statementAddresseeType === 'input' && form.statementDomesticOrOversees === 'domestic'" class="form-edit-line">
                            <div class="input-group">
                              <span class="input-group-text" style="flex: 0 1 90px;">建物</span>
                              <input v-model="form.statementBuilding" type="text" class="form-control">
                            </div>
                          </div>
                          <div v-if="form.statementAddresseeType === 'input' && form.statementDomesticOrOversees === 'oversees'" class="form-label align-bottom">住所</div>
                          <div v-if="form.statementAddresseeType === 'input' && form.statementDomesticOrOversees === 'oversees'" class="form-edit-line">
                            <textarea v-model="form.statementAddressEn" class="form-control" rows="4"></textarea>
                          </div>
                          <div v-if="form.statementAddresseeType === 'input'" class="form-label align-bottom">電話番号</div>
                          <div v-if="form.statementAddresseeType === 'input'" class="form-edit-line">
                            <input v-model="form.statementTel" type="text" class="form-control" style="width: 200px;">
                          </div>
                          <div v-if="form.statementAddresseeType === 'input'" class="form-label align-bottom">メールアドレス</div>
                          <div v-if="form.statementAddresseeType === 'input'" class="form-edit-line">
                            <input v-model="form.statementEmail" type="text" class="form-control">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="!form.groupRightsHolder" class="form-label align-bottom" style="margin-top: 10px;">挨拶文</div>
                  <div v-if="!form.groupRightsHolder" class="form-edit-line">
                    <textarea v-model="form.statementGreeting" class="form-control" rows="5"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!form.groupRightsHolder" class="accordion" id="accordionPaymentRecord" style="margin-top: 15px;">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePaymentRecord" aria-expanded="true" aria-controls="collapsePaymentRecord">
                  支払調書
                </button>
              </h2>
              <div id="collapsePaymentRecord" class="accordion-collapse collapse show" data-bs-parent="#accordionPaymentRecord">
                <div class="accordion-body">
                  <div class="form-edit-line">
                    <div class="form-check form-check-inline">
                      <input v-model="form.paymentAddresseeType" class="form-check-input" type="radio" name="paymentAddresseeType" id="payment_input" value="input">
                      <label class="form-check-label" for="payment_input">入力</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input v-model="form.paymentAddresseeType" class="form-check-input" type="radio" name="paymentAddresseeType" id="payment_master" value="master">
                      <label class="form-check-label" for="payment_master">送付先選択</label>
                    </div>
                  </div>
                  <div v-if="form.paymentAddresseeType === 'master'" class="form-label align-bottom">送付先</div>
                  <div v-if="form.paymentAddresseeType === 'master'" class="form-edit-line">
                    <div class="input-group">
                      <input v-model="form.paymentAddresseeName" type="text" class="form-control" readonly="readonly">
                      <button v-if="form.paymentAddresseeId" @click="clearSelectedAddressee('PAYMENT')" class="btn btn-outline-primary"><i class="fas fa-undo"></i></button>
                      <button @click="openAddresseeSearch('PAYMENT')" class="btn btn-primary"><i class="fas fa-search"></i></button>
                    </div>
                  </div>
                  <div v-if="form.paymentAddresseeType === 'input'" style="margin-bottom: 5px;">
                    <button @click="copyPayment" class="btn btn-outline-primary"><i class="fas fa-copy" style="margin-right: 5px;"></i>基本情報からコピー</button>
                  </div>
                  <div v-if="form.paymentAddresseeType === 'input'" class="form-label align-bottom">名前</div>
                  <div v-if="form.paymentAddresseeType === 'input'" class="form-edit-line">
                    <input v-model="form.paymentName" type="text" class="form-control">
                  </div>
                  <div v-if="form.paymentAddresseeType === 'input'" class="form-label align-bottom">敬称</div>
                  <div v-if="form.paymentAddresseeType === 'input'" class="form-edit-line">
                    <div class="input-group" style="width: 200px;">
                      <select v-model="form.paymentHonorificPosition" @change="changeHonorificPosition('PAYMENT')" class="form-select">
                        <option value="">なし</option>
                        <option value="BEFORE">前</option>
                        <option value="AFTER">後</option>
                      </select>
                      <select v-if="form.paymentHonorificPosition === 'BEFORE'" v-model="form.paymentHonorificTitle" class="form-select">
                        <option value="Mr.">Mr.</option>
                        <option value="Ms.">Ms.</option>
                        <option value="Mrs.">Mrs.</option>
                        <option value="Miss">Miss</option>
                        <option value="Sir">Sir</option>
                        <option value="Mstr.">Mstr.</option>
                        <option value="Esq.">Esq.</option>
                        <option value="Mx.">Mx.</option>
                        <option value="Dr.">Dr.</option>
                        <option value="Prof.">Prof.</option>
                      </select>
                      <select v-if="form.paymentHonorificPosition === 'AFTER'" v-model="form.paymentHonorificTitle" class="form-select">
                        <option value="様">様</option>
                        <option value="殿">殿</option>
                        <option value="さん">さん</option>
                        <option value="先生">先生</option>
                        <option value="御中">御中</option>
                        <option value="行">行</option>
                        <option value="宛">宛</option>
                        <option value="各位">各位</option>
                      </select>
                    </div>
                  </div>
                  <div v-if="form.paymentAddresseeType === 'input'" class="form-label align-bottom">住所</div>
                  <div v-if="form.paymentAddresseeType === 'input'" class="form-edit-line">
                    <div class="input-group">
                      <span class="input-group-text">〒</span>
                      <input v-model="form.paymentPostalCode" @keydown.enter="getAddress(form.paymentPostalCode, 'PAYMENT')" type="text" class="form-control" style="flex: 0 1 100px;" maxlength="7">
                      <span class="input-group-text" style="font-size: 90%;">（「-」なし7桁で入力）</span>
                      <button @click="getAddress" class="btn btn-outline-primary"><i class="fas fa-search fa-fw"></i></button>
                    </div>
                  </div>
                  <div v-if="form.paymentAddresseeType === 'input'" class="form-edit-line">
                    <div class="input-group">
                      <span class="input-group-text">都道府県</span>
                      <input v-model="form.paymentPrefecture" type="text" class="form-control" style="flex: 0 1 200px;">
                    </div>
                  </div>
                  <div v-if="form.paymentAddresseeType === 'input'" class="form-edit-line">
                    <div class="input-group">
                      <span class="input-group-text">市区町村</span>
                      <input v-model="form.paymentCity" type="text" class="form-control" style="flex: 0 1 200px;">
                    </div>
                  </div>
                  <div v-if="form.paymentAddresseeType === 'input'" class="form-edit-line">
                    <div class="input-group">
                      <span class="input-group-text" style="flex: 0 1 90px;">町域</span>
                      <input v-model="form.paymentTownArea" type="text" class="form-control">
                    </div>
                  </div>
                  <div v-if="form.paymentAddresseeType === 'input'" class="form-edit-line">
                    <div class="input-group">
                      <span class="input-group-text" style="flex: 0 1 90px;">建物</span>
                      <input v-model="form.paymentBuilding" type="text" class="form-control">
                    </div>
                  </div>
                  <div class="form-label align-bottom">法人番号</div>
                  <div class="form-edit-line">
                    <input v-model="form.corporateNumber" type="text" class="form-control" style="width: 200px;" maxlength="13">
                  </div>
                  <div v-if="form.paymentAddresseeType === 'input'" class="form-label align-bottom">電話番号</div>
                  <div v-if="form.paymentAddresseeType === 'input'" class="form-edit-line">
                    <input v-model="form.paymentTel" type="text" class="form-control" style="width: 200px;">
                  </div>
                  <div v-if="!form.groupRightsHolder" class="form-label align-bottom">挨拶文</div>
                  <div v-if="!form.groupRightsHolder" class="form-edit-line">
                    <textarea v-model="form.paymentGreeting" class="form-control" rows="5"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!form.groupRightsHolder" class="accordion" id="accordionBank" style="margin-top: 15px;">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseBank" aria-expanded="true" aria-controls="collapseBank">
                  振込先
                </button>
              </h2>
              <div id="collapseBank" class="accordion-collapse collapse show" data-bs-parent="#accordionBank">
                <div class="accordion-body">
                  <div class="form-edit-line">
                    <div class="form-check form-check-inline">
                      <input v-model="form.payeeType" class="form-check-input" type="radio" name="payeeType" id="payee_input" value="input">
                      <label class="form-check-label" for="payee_input">入力</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input v-model="form.payeeType" class="form-check-input" type="radio" name="payeeType" id="payee_master" value="master">
                      <label class="form-check-label" for="payee_master">振込先選択</label>
                    </div>
                  </div>
                  <div v-if="form.payeeType === 'master'" class="form-label align-bottom">振込先</div>
                  <div v-if="form.payeeType === 'master'" class="form-edit-line">
                    <div class="input-group">
                      <input v-model="form.payeeName" type="text" class="form-control" readonly="readonly">
                      <button v-if="form.payeeId" @click="clearSelectedPayee" class="btn btn-outline-primary"><i class="fas fa-undo"></i></button>
                      <button @click="openPayeeSearch" class="btn btn-primary"><i class="fas fa-search"></i></button>
                    </div>
                  </div>
                  <div v-if="form.payeeType === 'input'" class="form-label align-bottom">銀行所在地</div>
                  <div v-if="form.payeeType === 'input'" class="form-edit-line">
                    <div class="form-check form-check-inline">
                      <input v-model="form.bankDomesticOrOversees" class="form-check-input" type="radio" name="bankDomesticOrOversees" id="bankDomesticOrOversees_d" value="domestic">
                      <label class="form-check-label" for="bankDomesticOrOversees_d">日本</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input v-model="form.bankDomesticOrOversees" class="form-check-input" type="radio" name="bankDomesticOrOversees" id="bankDomesticOrOversees_o" value="oversees">
                      <label class="form-check-label" for="bankDomesticOrOversees_o">その他</label>
                    </div>
                  </div>
                  <div v-if="form.payeeType === 'input' && form.bankDomesticOrOversees === 'domestic'" class="form-label align-bottom">銀行</div>
                  <div v-if="form.payeeType === 'input' && form.bankDomesticOrOversees === 'domestic'" class="form-edit-line">
                    <div class="input-group">
                      <input v-model="form.bankCode" type="text" class="form-control" style="flex: 0 1 75px;" readonly>
                      <input v-model="form.bankName" type="text" class="form-control" style="flex: 0 1 120px;" readonly>
                      <input v-model="form.branchCode" type="text" class="form-control" style="flex: 0 1 60px;" readonly>
                      <input v-model="form.branchName" type="text" class="form-control" style="flex: 0 1 130px;" readonly>
                      <button v-if="!form.branchId" @click="openBankSearch" class="btn btn-outline-primary"><i class="fas fa-search fa-fw"></i></button>
                      <button v-if="form.branchId" @click="clearBank" class="btn btn-outline-primary"><i class="fas fa-undo fa-fw"></i></button>
                    </div>
                  </div>
                  <div v-if="form.payeeType === 'input' && form.bankDomesticOrOversees === 'domestic'" class="form-label align-bottom">預金種目</div>
                  <div v-if="form.payeeType === 'input' && form.bankDomesticOrOversees === 'domestic'" class="form-edit-line">
                    <select v-model="form.depositItem" class="form-select" style="width: 100px;">
                      <option value="1">普通</option>
                      <option value="2">当座</option>
                      <option value="4">貯蓄</option>
                      <option value="9">その他</option>
                    </select>
                  </div>
                  <div v-if="form.payeeType === 'input' && form.bankDomesticOrOversees === 'domestic'" class="form-label align-bottom">口座番号</div>
                  <div v-if="form.payeeType === 'input' && form.bankDomesticOrOversees === 'domestic'" class="form-edit-line">
                    <input v-model="form.accountNo" type="text" class="form-control" style="width: 100px;" maxlength="7">
                  </div>
                  <div v-if="form.payeeType === 'input' && form.bankDomesticOrOversees === 'domestic'" class="form-label align-bottom">口座名義</div>
                  <div v-if="form.payeeType === 'input' && form.bankDomesticOrOversees === 'domestic'" class="form-edit-line">
                    <input v-model="form.accountName" type="text" class="form-control">
                  </div>
                  <div v-if="form.payeeType === 'input' && form.bankDomesticOrOversees === 'domestic'" class="form-label align-bottom">口座名義カナ</div>
                  <div v-if="form.payeeType === 'input' && form.bankDomesticOrOversees === 'domestic'" class="form-edit-line">
                    <input v-model="form.accountKana" type="text" class="form-control">
                  </div>
                  <div v-if="form.payeeType === 'input' && form.bankDomesticOrOversees === 'oversees'" class="form-label align-bottom">口座</div>
                  <div v-if="form.payeeType === 'input' && form.bankDomesticOrOversees === 'oversees'" class="form-edit-line">
                    <textarea v-model="form.overseesAccount" class="form-control" rows="3"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!form.groupRightsHolder && form.id" class="accordion" id="accordionSuspension" style="margin-top: 15px;">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSuspension" aria-expanded="false" aria-controls="collapseSuspension">
                  保留状況
                </button>
              </h2>
              <div id="collapseSuspension" class="accordion-collapse collapse" data-bs-parent="#accordionSuspension">
                <div class="accordion-body">
                  <table class="table table-responsive" style="max-width: 300px;">
                    <tbody>
                    <tr v-for="suspension in form.suspensionList" :key="suspension.id">
                      <td class="align-middle">{{suspension.rate}}%</td>
                      <td class="text-end" style="width: 180px;">
                        <div class="input-group">
                          <span class="input-group-text">￥</span>
                          <input v-model="suspension.suspensionAmount" class="form-control" type="number" style="flex: 0 1 120px;">
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!form.groupRightsHolder" class="accordion" id="accordionAdvance" style="margin-top: 15px;">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAdvance" aria-expanded="false" aria-controls="collapseAdvance">
                  アドバンス
                </button>
              </h2>
              <div id="collapseAdvance" class="accordion-collapse collapse" data-bs-parent="#accordionAdvance">
                <div class="accordion-body">
                  <button @click="addAdvance" class="btn btn-outline-primary btn-sm" style="margin-left: 5px;"><i class="fas fa-plus fa-fw" style="margin-right: 5px;"></i>追加</button>
                  <table v-if="!form.groupRightsHolder && advanceList && advanceList.length > 0" class="table table-responsive">
                    <thead>
                      <tr>
                        <th style="width: 150px;">種類</th>
                        <th style="width: 110px;">税</th>
                        <th style="width: 125px;">金額</th>
                        <th style="width: 125px;">相殺</th>
                        <th style="width: 47px;"></th>
                        <th style="width: 20px;"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(ad, index) in advanceList" :key="index" :class="{'deleted-row': ad.deleted}" draggable="true" @dragstart="dragList($event, index)" @drop="dropList($event, index)" @dragover.prevent @dragenter.prevent>
                        <td style="padding: 8px 3px 8px 3px">
                          <div class="input-group">
                            <select v-model="ad.advanceType" class="form-select" style="flex: 0 1 115px;">
                              <option value="rights_holder">権利者</option>
                            </select>
<!--                            <button v-if="ad.advanceType === 'album'" @click="openAlbumSearch(index)" class="btn btn-sm btn-outline-primary"><i class="fas fa-cog"></i></button>-->
<!--                            <button v-if="ad.advanceType === 'master'" @click="openMasterSearch(index)" class="btn btn-sm btn-outline-primary"><i class="fas fa-cog"></i></button>-->
                          </div>
                        </td>
                        <td style="padding: 8px 3px 8px 3px">
                          <select v-model="ad.taxation" class="form-select" style="width: 100px;">
                            <option value="false">非課税</option>
                            <option value="true">課税</option>
                          </select>
                        </td>
                        <td style="padding: 8px 3px 8px 3px">
                          <input v-model="ad.paymentAmount" type="number" class="form-control" style="width: 115px;">
                        </td>
                        <td style="padding: 8px 3px 8px 3px">
                          <input v-model="ad.offsetAmount" type="number" class="form-control" style="width: 115px;">
                        </td>
                        <td style="vertical-align: middle;" class="text-end">
                          <button @click="ad.deleted = !ad.deleted" class="btn btn-outline-danger btn-sm"><i class="fas fa-trash-alt"></i></button>
                        </td>
                        <td style="cursor: grab; vertical-align: middle;"><i class="fas fa-grip-vertical"></i></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div v-if="form.groupRightsHolder" class="accordion" id="accordionMember" style="margin-top: 15px;">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseMember" aria-expanded="false" aria-controls="collapseMember">
                  メンバー
                </button>
              </h2>
              <div id="collapseMember" class="accordion-collapse collapse" data-bs-parent="#accordionMember">
                <div class="accordion-body">
                  <button @click="openMemberSearch" class="btn btn-outline-primary btn-sm" style="margin-left: 5px;"><i class="fas fa-edit fa-fw" style="margin-right: 5px;"></i>メンバー編集</button>
                  <table v-if="form && form.groupRightsHolder && form.memberList" class="table table-responsive">
                    <thead>
                      <tr>
                        <th>権利者</th>
                        <th>比率</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="member in form.memberList" :key="member.memberRightsHolderId" :class="{ 'deleted-row': member.deleted }">
                        <td style="vertical-align: middle;">
                          <p v-if="member.memberCode" style="margin: 0 0 3px 0; font-size: 80%;">{{member.memberCode}}</p>
                          <p style="margin: 0;">{{ member.memberName }}</p>
                        </td>
                        <td style="width: 90px;">
                          <input v-model="member.share" type="number" class="form-control" style="width: 80px;">
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="form-label align-bottom" style="margin-top: 10px;">メモ</div>
          <div class="form-edit-line">
            <textarea v-model="form.memo" class="form-control" rows="8"></textarea>
          </div>
          <div style="height: 50px;"></div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="bankSearchModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">銀行検索</h5>
          </div>
          <div class="modal-body text-start">
            <div>
              <div class="input-group" style="margin-top: 15px;">
                <input @keypress.enter="bankCondition.page = 0; findBank();" v-model="bankCondition.searchString" type="text" class="form-control" placeholder="検索条件">
                <button v-show="bankCondition.searchString" @click="bankClearSearch" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
                <button @click="bankCondition.page = 0; findBank();" class="btn btn-primary"><i class="fas fa-search"></i></button>
              </div>
              <table v-if="bankResult && bankResult.allRecords > 0" class="table table-responsive table-hover">
                <thead>
                <tr>
                  <th style="width: 55px;"></th>
                  <th style="width: 120px;">銀行コード</th>
                  <th>銀行名</th>
                  <th style="width: 120px;">支店コード</th>
                  <th>支店名</th>
                </tr>
                </thead>
                <tbody v-if="bankList">
                <tr v-for="(bank, index) in bankList" :key="bank.branchId" class="text-start">
                  <td>
                    <button @click="selectBank(index)" class="btn btn-outline-primary btn-sm"><i class="fas fa-hand-pointer fa-fw"></i></button>
                  </td>
                  <td>{{ bank.bankCode }}</td>
                  <td>{{ bank.bankName }}</td>
                  <td>{{ bank.branchCode }}</td>
                  <td>{{ bank.branchName }}</td>
                </tr>
                </tbody>
              </table>
              <div class="row">
                <div class="col-6 d-flex justify-content-start">
                  <span v-if="bankResult && bankResult.allRecords > 0">{{ bankResult.allRecords }}件の一致データ</span>
                  <span v-else style="margin-top: 20px;">一致データが存在しません</span>
                </div>
                <div v-if="bankResult && bankResult.allRecords > 0" class="col-6 d-flex justify-content-end">
                  <nav v-if="bankResult" aria-label="Page navigation">
                    <ul class="pagination">
                      <li v-if="bankCondition.page !== 0" class="page-item"><button @click="bankFirstPage" class="page-link" href="#"><i class="fas fa-fast-backward"></i></button></li>
                      <li v-if="bankCondition.page !== 0" class="page-item"><button @click="bankPrevPage" class="page-link" href="#"><i class="fas fa-chevron-left"></i></button></li>
                      <li class="page-item"><span class="page-link">{{ bankCondition.page + 1 }}/{{ bankResult.allPages }}ページ</span>
                      </li>
                      <li v-if="bankCondition.page !== bankResult.allPages - 1" class="page-item"><button @click="bankNextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button></li>
                      <li v-if="bankCondition.page !== bankResult.allPages - 1" class="page-item"><button @click="bankLastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button></li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i
                class="fas fa-times fa-fw"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="addresseeSearchModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">送付先検索</h5>
          </div>
          <div class="modal-body text-start">
            <div>
              <div class="input-group" style="margin-top: 15px;">
                <input @keypress.enter="addresseeCondition.page = 0; findAddressee();" v-model="addresseeCondition.searchString" type="text" class="form-control" placeholder="検索条件">
                <button v-show="addresseeCondition.searchString" @click="addresseeClearSearch" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
                <button @click="addresseeCondition.page = 0; findAddressee();" class="btn btn-primary"><i class="fas fa-search"></i></button>
              </div>
              <table v-if="addresseeResult && addresseeResult.allRecords > 0" class="table table-responsive table-hover">
                <tbody v-if="addresseeList">
                  <tr v-for="(addressee, index) in addresseeList" :key="addressee.id" class="text-start align-middle">
                    <td style="width: 55px;">
                      <button @click="selectAddressee(index)" class="btn btn-outline-primary btn-sm"><i class="fas fa-hand-pointer fa-fw"></i></button>
                    </td>
                    <td>{{ addressee.name }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="row">
                <div class="col-6 d-flex justify-content-start">
                  <span v-if="addresseeResult && addresseeResult.allRecords > 0">{{ addresseeResult.allRecords }}件の一致データ</span>
                  <span v-else style="margin-top: 20px;">一致データが存在しません</span>
                </div>
                <div v-if="addresseeResult && addresseeResult.allRecords > 0" class="col-6 d-flex justify-content-end">
                  <nav v-if="addresseeResult" aria-label="Page navigation">
                    <ul class="pagination">
                      <li v-if="addresseeCondition.page !== 0" class="page-item"><button @click="addresseeFirstPage" class="page-link" href="#"><i class="fas fa-fast-backward"></i></button></li>
                      <li v-if="addresseeCondition.page !== 0" class="page-item"><button @click="addresseePrevPage" class="page-link" href="#"><i class="fas fa-chevron-left"></i></button></li>
                      <li class="page-item"><span class="page-link">{{ addresseeCondition.page + 1 }}/{{ addresseeResult.allPages }}ページ</span>
                      </li>
                      <li v-if="addresseeCondition.page !== addresseeResult.allPages - 1" class="page-item"><button @click="addresseeNextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button></li>
                      <li v-if="addresseeCondition.page !== addresseeResult.allPages - 1" class="page-item"><button @click="addresseeLastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button></li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i
                class="fas fa-times fa-fw"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="payeeSearchModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">振込先検索</h5>
          </div>
          <div class="modal-body text-start">
            <div>
              <div class="input-group" style="margin-top: 15px;">
                <input @keypress.enter="payeeCondition.page = 0; findPayee();" v-model="payeeCondition.searchString" type="text" class="form-control" placeholder="検索条件">
                <button v-show="payeeCondition.searchString" @click="payeeClearSearch" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
                <button @click="payeeCondition.page = 0; findPayee();" class="btn btn-primary"><i class="fas fa-search"></i></button>
              </div>
              <table v-if="payeeResult && payeeResult.allRecords > 0" class="table table-responsive table-hover">
                <tbody v-if="payeeList">
                <tr v-for="(payee, index) in payeeList" :key="payee.id" class="text-start">
                  <td><a @click="selectPayee(index)" href="#">{{ payee.name }}</a></td>
                </tr>
                </tbody>
              </table>
              <div class="row">
                <div class="col-6 d-flex justify-content-start">
                  <span v-if="payeeResult && payeeResult.allRecords > 0">{{ payeeResult.allRecords }}件の一致データ</span>
                  <span v-else style="margin-top: 20px;">一致データが存在しません</span>
                </div>
                <div v-if="payeeResult && payeeResult.allRecords > 0" class="col-6 d-flex justify-content-end">
                  <nav v-if="payeeResult" aria-label="Page navigation">
                    <ul class="pagination">
                      <li v-if="payeeCondition.page !== 0" class="page-item"><button @click="payeeFirstPage" class="page-link" href="#"><i class="fas fa-fast-backward"></i></button></li>
                      <li v-if="payeeCondition.page !== 0" class="page-item"><button @click="payeePrevPage" class="page-link" href="#"><i class="fas fa-chevron-left"></i></button></li>
                      <li class="page-item"><span class="page-link">{{ payeeCondition.page + 1 }}/{{ payeeResult.allPages }}ページ</span>
                      </li>
                      <li v-if="payeeCondition.page !== payeeResult.allPages - 1" class="page-item"><button @click="payeeNextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button></li>
                      <li v-if="payeeCondition.page !== payeeResult.allPages - 1" class="page-item"><button @click="payeeLastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button></li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i
                class="fas fa-times fa-fw"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="destinationSearchModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">送付先検索</h5>
          </div>
          <div class="modal-body text-start">
            <div>
              <div class="input-group" style="margin-top: 15px;">
                <input @keydown.enter="destinationCondition.page = 0; findDestination();" v-model="destinationCondition.searchString" type="text" class="form-control" placeholder="検索条件">
                <button v-show="destinationCondition.searchString" @click="destinationClearSearch" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
                <button @click="destinationCondition.page = 0; findDestination();" class="btn btn-primary"><i class="fas fa-search"></i></button>
              </div>
              <table v-if="destinationResult && destinationResult.allRecords > 0" class="table table-responsive table-hover">
                <tbody v-if="destinationList">
                <tr v-for="(destination, index) in destinationList" :key="destination.id" class="text-start">
                  <td><a @click="selectDestination(index)" href="#">{{ destination.name }}</a></td>
                </tr>
                </tbody>
              </table>
              <div class="row">
                <div class="col-6 d-flex justify-content-start">
                  <span v-if="destinationResult && destinationResult.allRecords > 0">{{ destinationResult.allRecords }}件の一致データ</span>
                  <span v-else style="margin-top: 20px;">一致データが存在しません</span>
                </div>
                <div v-if="destinationResult && destinationResult.allRecords > 0" class="col-6 d-flex justify-content-end">
                  <nav v-if="destinationResult" aria-label="Page navigation">
                    <ul class="pagination">
                      <li v-if="destinationCondition.page !== 0" class="page-item"><button @click="destinationFirstPage" class="page-link" href="#"><i class="fas fa-fast-backward"></i></button></li>
                      <li v-if="destinationCondition.page !== 0" class="page-item"><button @click="destinationPrevPage" class="page-link" href="#"><i class="fas fa-chevron-left"></i></button></li>
                      <li class="page-item"><span class="page-link">{{ destinationCondition.page + 1 }}/{{ destinationResult.allPages }}ページ</span>
                      </li>
                      <li v-if="destinationCondition.page !== destinationResult.allPages - 1" class="page-item"><button @click="destinationNextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button></li>
                      <li v-if="destinationCondition.page !== destinationResult.allPages - 1" class="page-item"><button @click="destinationLastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button></li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i
                class="fas fa-times fa-fw"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="memberModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">メンバー選択</h5>
          </div>
          <div class="modal-body text-start">
            <div class="row">
              <div class="col-lg-6">
                <div class="input-group" style="margin-top: 15px;">
                  <input @keypress.enter="memberCondition.page = 0; memberFind();" v-model="memberCondition.searchString" type="text" class="form-control" placeholder="検索条件">
                  <button v-show="memberCondition.searchString" @click="memberClearSearch" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
                  <button @click="memberCondition.page = 0; memberFind();" class="btn btn-primary"><i class="fas fa-search"></i></button>
                </div>
                <table v-if="memberSearchResult && memberSearchResult.allRecords > 0" class="table table-responsive table-hover">
                  <tbody v-if="memberSearchList">
                  <tr v-for="(member, index) in memberSearchList" :key="member.id" class="text-start">
                    <td style="width: 55px;">
                      <button @click="selectMember(index)" class="btn btn-outline-primary btn-sm"><i class="fas fa-hand-pointer fa-fw"></i></button>
                    </td>
                    <td style="vertical-align: middle;">
                      <p v-if="member.code" style="margin: 0 0 3px 0; font-size: 80%;">{{member.code}}</p>
                      <p style="margin: 0;">{{ member.name }}</p>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div class="row">
                  <div class="col-6 d-flex justify-content-start">
                    <span v-if="memberSearchResult && memberSearchResult.allRecords > 0">{{ memberSearchResult.allRecords }}件の一致データ</span>
                    <span v-else style="margin-top: 20px;">一致データが存在しません</span>
                  </div>
                  <div v-if="memberSearchResult && memberSearchResult.allRecords > 0" class="col-6 d-flex justify-content-end">
                    <nav v-if="memberSearchResult" aria-label="Page navigation">
                      <ul class="pagination">
                        <li v-if="memberCondition.page !== 0" class="page-item"><button @click="memberFirstPage" class="page-link" href="#"><i class="fas fa-fast-backward"></i></button></li>
                        <li v-if="memberCondition.page !== 0" class="page-item"><button @click="memberPrevPage" class="page-link" href="#"><i class="fas fa-chevron-left"></i></button></li>
                        <li class="page-item"><span class="page-link">{{ memberCondition.page + 1 }}/{{ memberSearchResult.allPages }}ページ</span></li>
                        <li v-if="memberCondition.page !== memberSearchResult.allPages - 1" class="page-item"><button @click="memberNextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button>
                        </li>
                        <li v-if="memberCondition.page !== memberSearchResult.allPages - 1" class="page-item"><button @click="memberLastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <table v-if="form && form.memberList" class="table table-responsive">
                  <tbody>
                  <tr v-for="member in form.memberList" :key="member.member_id" :class="{ 'deleted-row': member.deleted }">
                    <td style="vertical-align: middle;">
                      <p v-if="member.code" style="margin: 0 0 3px 0; font-size: 80%;">{{member.memberCode}}</p>
                      <p style="margin: 0;">{{ member.memberName }}</p>
                    </td>
                    <td style="width: 60px; vertical-align: middle;" class="text-end">
                      <button @click="member.deleted = !member.deleted" class="btn btn-outline-danger btn-sm"><i class="fas fa-trash-alt"></i></button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="deleteModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">権利者の削除</h5>
          </div>
          <div v-if="form" class="modal-body text-start">
            <div class="alert alert-danger">
              以下の権利者を削除しようとしています。削除すると、権利者情報の確認ができなくなり、該当権利者が紐づいている支払実績情報も全て削除されます。削除をすると元に戻せなくなりますので十分注意した上で実行してください。
            </div>
            <div class="form-label align-bottom">名前</div>
            <div class="form-edit-line">
              {{ form.name }}
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="executeDelete" type="button" class="btn btn-danger" style="margin-right: 5px;"><i
                class="fas fa-trash-alt" style="margin-right: 5px;"></i>注意事項を理解した上で削除を実行する</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-success" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="false" ref="messageToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090; width: 600px;">
      <div class="toast-header">
        <i class="fas fa-info-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">メッセージ</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(message, index) in messages" :key="index"><span v-html="message.replaceAll('\n', '<br>')"></span></p>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-danger" role="alert" aria-live="assertive" aria-atomic="true"
      ref="errorToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">エラー</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(error, index) in errors" :key="index">{{ error }}</p>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="processModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">処理中</h5>
          </div>
          <div class="modal-body text-start">
            {{ processMessage }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { exFunction } from '@/functions/exception'
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import navComponent from '@/components/Nav'
import { Modal, Toast } from 'bootstrap'
import { defineComponent, onMounted, onUnmounted, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import footerComponent from '@/components/Footer.vue'
import { rightsHolderFunction } from './function'
import { redCompanyFunction, postalFunction, bankFunction } from '@/functions/common'
import { addresseeFunction } from '../../common/addressee/function'
import { payeeFunction } from '../../common/payee/function'
import { destinationFunction } from '../../green/destination/function'
import { paymentDataDefinitionFunction } from '../paymentdatadefinition/function'
import { paymentPdfDefinitionFunction } from '../paymentpdfdefinition/function'
import { paymentFunction } from '../payment/function'
import { addresseeStore, payeeStore, bankStore, memberStore, destinationStore } from './store'

export default defineComponent({
  components: { footerComponent, navComponent },
  setup () {
    const ex = exFunction()
    const auth = authFunction()
    const session = sessionStore()
    const router = useRouter()
    const route = useRoute()
    const messages = ref([])
    const messageToastDiv = ref(null)
    let messageToast = null
    const errors = ref([])
    const errorToastDiv = ref(null)
    let errorToast = null
    const processModalDiv = ref(null)
    let processModal = null
    const processMessage = ref('')
    const addressMode = ref('RESIDENCE')
    // Form
    const rightsHolderFunc = rightsHolderFunction()
    const paymentFunc = paymentFunction()
    const addresseeFunc = addresseeFunction()
    const payeeFunc = payeeFunction()
    const companyFunc = redCompanyFunction()
    const addresseeCondition = addresseeStore()
    const payeeCondition = payeeStore()
    const form = ref(null)
    const rightAreaMode = ref('STATEMENT')
    const deleteModalDiv = ref(null)
    let deleteModal = null
    const showDeleteButton = ref(false)
    const divTermList = ref([])
    const postalFunc = postalFunction()
    // 銀行検索
    const bankFunc = bankFunction()
    const bankSearchModalDiv = ref(null)
    let bankSearchModal = null
    const bankCondition = bankStore()
    const bankResult = ref(null)
    const bankList = ref([])
    // 送付先検索
    const addresseeMode = ref('')
    const addresseeSearchModalDiv = ref(null)
    let addresseeSearchModal = null
    const addresseeResult = ref(null)
    const addresseeList = ref([])
    // 振込先検索
    const payeeSearchModalDiv = ref(null)
    let payeeSearchModal = null
    const payeeResult = ref(null)
    const payeeList = ref([])
    // 送付先検索
    const destinationFunc = destinationFunction()
    const destinationCondition = destinationStore()
    const destinationSearchModalDiv = ref(null)
    let destinationSearchModal = null
    const destinationResult = ref(null)
    const destinationList = ref([])
    // メンバー選択
    const memberModalDiv = ref(null)
    let memberModal = null
    const memberCondition = memberStore()
    const memberSearchResult = ref(null)
    const memberSearchList = ref([])
    // アドバンス追加
    const advanceList = ref([])
    // データ定義
    const paymentPdfDefinitionFunc = paymentPdfDefinitionFunction()
    const paymentPdfDefinitionList = ref([])
    const paymentDataDefinitionFunc = paymentDataDefinitionFunction()
    const paymentDataDefinitionList = ref([])
    // メソッド
    const save = async () => {
      errors.value = []
      if (!form.value.name) {
        errors.value.push('名前を入力してください')
      }
      if (form.value.statementAddresseeType === 'master') {
        if (!form.value.statementAddresseeId) {
          errors.value.push('計算書の送付先を選択してください')
        }
      }
      if (form.value.paymentAddresseeType === 'master') {
        if (!form.value.paymentAddresseeId) {
          errors.value.push('調書の送付先を選択してください')
        }
      }
      if (form.value.payeeType === 'master') {
        if (!form.value.payeeId) {
          errors.value.push('振込先を選択してください')
        }
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        processMessage.value = '保存処理中です。しばらくこのままでお待ちください。'
        processModal.show()
        if (form.value.statementAddresseeType === 'input') {
          form.value.statementAddresseeId = null
        }
        if (form.value.paymentAddresseeType === 'input') {
          form.value.paymentAddresseeId = null
        }
        if (form.value.payeeType === 'input') {
          form.value.payeeId = null
        }
        if (!form.value.paymentDataDefinitionId) {
          form.value.paymentDataDefinitionId = null
        }
        if (!form.value.paymentPdfDefinitionId) {
          form.value.paymentPdfDefinitionId = null
        }
        try {
          let targetList = []
          for (const term of form.value.divTermList) {
            if (term.target) {
              targetList.push(term.companyDivTermSettingId)
              term.targetDivTermIdList = targetList
              targetList = []
            } else {
              targetList.push(term.companyDivTermSettingId)
            }
          }
          if (targetList.length > 0) {
            for (const term of form.value.divTermList) {
              if (term.target) {
                term.targetDivTermIdList = term.targetDivTermIdList.concat(targetList)
                break
              } else {
                targetList.push(term.companyDivTermSettingId)
              }
            }
          }
          console.log(form.value.divTermList)
          if (!form.value.id) {
            form.value.id = await rightsHolderFunc.insert(session.session.company.id, form.value)
          } else {
            await rightsHolderFunc.update(form.value.id, form.value)
          }
          rightsHolderFunc.indexUpdate(form.value.id)
          // let offsetPriority = 1
          // for (const ad of advanceList.value) {
          //   if (!ad.id) {
          //     if (!ad.deleted) {
          //       ad.offset_priority = offsetPriority
          //       await rightsHolderFunc.insertAdvance(form.value.id, ad)
          //       offsetPriority++
          //     }
          //   } else {
          //     if (ad.deleted) {
          //       await rightsHolderFunc.removeAdvance(ad.id)
          //     } else {
          //       ad.offset_priority = offsetPriority
          //       await rightsHolderFunc.updateAdvance(ad.id, ad)
          //       offsetPriority++
          //     }
          //   }
          // }
          for (const destination of form.value.destinationList) {
            if (!destination.deleted) {
              await rightsHolderFunc.insertDestination(destination.destinationId, form.value.id)
            } else {
              await rightsHolderFunc.removeDestination(destination.destinationId, form.value.id)
            }
          }
        } catch (error) {
          await ex.identifyEx(error)
          errors.value = ['システムエラーが発生したため保存されませんでした']
          errorToast.show()
        }
        processModal.hide()
        await router.push('/red/rightsholder/disp/' + form.value.id)
      }
    }
    const openDeleteModal = () => {
      deleteModal.show()
    }
    const executeDelete = async () => {
      errors.value = []
      const oldCount = await paymentFunc.countOldStatement(form.value.id)
      console.log(oldCount)
      if (oldCount > 0) {
        errors.value.push('既に支払済の明細が存在するため削除できません')
        errorToast.show()
      } else {
        await paymentFunc.deleteCurrentStatement(form.value.id)
        await rightsHolderFunc.remove(form.value.id)
        deleteModal.hide()
        await router.push('/red/rightsholder/list')
      }
    }
    const changeHonorificPosition = (type) => {
      if (type === 'STATEMENT') {
        if (form.value.statementHonorificPosition === 'BEFORE') {
          form.value.statementHonorificTitle = 'Mr.'
        } else {
          form.value.statementHonorificTitle = '様'
        }
      } else {
        if (form.value.paymentHonorificPosition === 'BEFORE') {
          form.value.paymentHonorificTitle = 'Mr.'
        } else {
          form.value.paymentHonorificTitle = '様'
        }
      }
    }
    const getAddress = async (postalCode, mode) => {
      if (postalCode) {
        const postal = await postalFunc.getAddress(postalCode)
        if (mode === 'STATEMENT') {
          form.value.statementPrefecture = postal.prefecture
          form.value.statementCity = postal.city
          form.value.statementTownArea = postal.townArea
        } else if (mode === 'PAYMENT') {
          form.value.paymentPrefecture = postal.prefecture
          form.value.paymentCity = postal.city
          form.value.paymentTownArea = postal.townArea
        } else {
          form.value.prefecture = postal.prefecture
          form.value.city = postal.city
          form.value.townArea = postal.townArea
        }
      }
    }
    const copyStatement = () => {
      form.value.statementName = form.value.name
      form.value.statementPostalCode = form.value.postalCode
      form.value.statementPrefecture = form.value.prefecture
      form.value.statementCity = form.value.city
      form.value.statementTownArea = form.value.townArea
      form.value.statementBuilding = form.value.building
      form.value.statementTel = form.value.tel
      form.value.statementEmail = form.value.email
    }
    const copyPayment = () => {
      form.value.paymentName = form.value.name
      form.value.paymentPostalCode = form.value.postalCode
      form.value.paymentPrefecture = form.value.prefecture
      form.value.paymentCity = form.value.city
      form.value.paymentTownArea = form.value.townArea
      form.value.paymentBuilding = form.value.building
      form.value.paymentTel = form.value.tel
    }
    const findBank = async () => {
      bankResult.value = await bankFunc.findBranchWithBank(bankCondition)
      bankList.value = bankResult.value.list
    }
    const bankClearSearch = () => {
      bankCondition.searchString = ''
      bankCondition.page = 0
      findBank()
    }
    const bankFirstPage = () => {
      bankCondition.page = 0
      findBank()
    }
    const bankPrevPage = () => {
      bankCondition.page = bankCondition.page - 1
      findBank()
    }
    const bankNextPage = () => {
      bankCondition.page = bankCondition.page + 1
      findBank()
    }
    const bankLastPage = () => {
      bankCondition.page = bankResult.value.allPages - 1
      findBank()
    }
    const openBankSearch = () => {
      bankCondition.searchString = ''
      bankCondition.page = 0
      bankResult.value = null
      bankList.value = []
      bankSearchModal.show()
    }
    const selectBank = (index) => {
      const bank = bankList.value[index]
      form.value.bankCode = bank.bankCode
      form.value.bankName = bank.bankName
      form.value.branchId = bank.branchId
      form.value.branchCode = bank.branchCode
      form.value.branchName = bank.branchName
      bankSearchModal.hide()
    }
    const clearBank = () => {
      form.value.bankCode = ''
      form.value.bankName = ''
      form.value.branchId = null
      form.value.branchCode = ''
      form.value.branchName = ''
    }
    const findAddressee = async () => {
      addresseeResult.value = await addresseeFunc.find(addresseeStore())
      addresseeList.value = addresseeResult.value.list
    }
    const addresseeClearSearch = () => {
      addresseeCondition.searchString = ''
      addresseeCondition.page = 0
      findAddressee()
    }
    const addresseeFirstPage = () => {
      addresseeCondition.page = 0
      findAddressee()
    }
    const addresseePrevPage = () => {
      addresseeCondition.page = addresseeCondition.page - 1
      findAddressee()
    }
    const addresseeNextPage = () => {
      addresseeCondition.page = addresseeCondition.page + 1
      findAddressee()
    }
    const addresseeLastPage = () => {
      addresseeCondition.page = addresseeResult.value.allPages - 1
      findAddressee()
    }
    const openAddresseeSearch = (mode) => {
      addresseeMode.value = mode
      addresseeCondition.searchString = ''
      addresseeCondition.page = 0
      addresseeResult.value = null
      addresseeList.value = []
      addresseeSearchModal.show()
    }
    const selectAddressee = (index) => {
      const addressee = addresseeList.value[index]
      if (addresseeMode.value === 'STATEMENT') {
        form.value.statementAddresseeName = addressee.name
        form.value.statementAddresseeId = addressee.id
      } else if (addresseeMode.value === 'PAYMENT') {
        form.value.paymentAddresseeName = addressee.name
        form.value.paymentAddresseeId = addressee.id
      }
      addresseeSearchModal.hide()
    }
    const clearSelectedAddressee = (mode) => {
      if (mode === 'STATEMENT') {
        form.value.statementAddresseeName = ''
        form.value.statementAddresseeId = ''
      } else if (addresseeMode.value === 'PAYMENT') {
        form.value.paymentAddresseeName = ''
        form.value.paymentAddresseeId = ''
      }
    }
    const findPayee = async () => {
      payeeResult.value = await payeeFunc.find(payeeCondition)
      payeeList.value = payeeResult.value.list
    }
    const payeeClearSearch = () => {
      payeeCondition.searchString = ''
      payeeCondition.page = 0
      findPayee()
    }
    const payeeFirstPage = () => {
      payeeCondition.page = 0
      findPayee()
    }
    const payeePrevPage = () => {
      payeeCondition.page = payeeCondition.page - 1
      findPayee()
    }
    const payeeNextPage = () => {
      payeeCondition.page = payeeCondition.page + 1
      findPayee()
    }
    const payeeLastPage = () => {
      payeeCondition.page = payeeResult.value.allPages - 1
      findPayee()
    }
    const openPayeeSearch = () => {
      payeeCondition.searchString = ''
      payeeCondition.page = 0
      payeeResult.value = null
      payeeList.value = []
      payeeSearchModal.show()
    }
    const selectPayee = (index) => {
      const payee = payeeList.value[index]
      form.value.payeeName = payee.name
      form.value.payeeId = payee.id
      payeeSearchModal.hide()
    }
    const clearSelectedPayee = () => {
      form.value.payeeName = ''
      form.value.payeeId = ''
    }
    const findDestination = async () => {
      destinationResult.value = await destinationFunc.find(destinationCondition)
      destinationList.value = destinationResult.value.list
    }
    const destinationClearSearch = () => {
      destinationCondition.searchString = ''
      destinationCondition.page = 0
      findDestination()
    }
    const destinationFirstPage = () => {
      destinationCondition.page = 0
      findDestination()
    }
    const destinationPrevPage = () => {
      destinationCondition.page = destinationCondition.page - 1
      findDestination()
    }
    const destinationNextPage = () => {
      destinationCondition.page = destinationCondition.page + 1
      findDestination()
    }
    const destinationLastPage = () => {
      destinationCondition.page = destinationResult.value.allPages - 1
      findDestination()
    }
    const openDestinationSearch = () => {
      destinationCondition.searchString = ''
      destinationCondition.page = 0
      destinationResult.value = null
      destinationList.value = []
      destinationSearchModal.show()
    }
    const selectDestination = (index) => {
      const destination = destinationList.value[index]
      form.value.destinationList.push({
        destinationId: destination.id,
        destinationName: destination.name
      })
      destinationSearchModal.hide()
    }
    const openMemberSearch = () => {
      memberSearchResult.value = null
      memberSearchList.value = []
      memberCondition.page = 0
      memberCondition.searchString = ''
      memberModal.show()
    }
    const memberFind = async () => {
      memberSearchResult.value = await rightsHolderFunc.find(session.session.company.id, memberCondition)
      memberSearchList.value = memberSearchResult.value.list
    }
    const memberClearSearch = () => {
      memberCondition.searchString = ''
      memberCondition.page = 0
      memberFind()
    }
    const memberFirstPage = () => {
      memberCondition.page = 0
      memberFind()
    }
    const memberPrevPage = () => {
      memberCondition.page = memberCondition.page - 1
      memberFind()
    }
    const memberNextPage = () => {
      memberCondition.page = memberCondition.page + 1
      memberFind()
    }
    const memberLastPage = () => {
      memberCondition.page = memberSearchResult.value.allPages - 1
      memberFind()
    }
    const selectMember = (index) => {
      const member = memberSearchList.value[index]
      console.log(member)
      if (!(form.value.memberList.some(x => x.memberRightsHolderId === member.id))) {
        if (!form.value.memberList) {
          form.value.memberList = []
        }
        form.value.memberList.push({
          memberRightsHolderId: member.id,
          memberName: member.name,
          memberCode: member.code,
          share: 1
        })
      }
    }
    const initAdd = async () => {
      form.value = {
        domesticOrOversees: 'domestic',
        statementDomesticOrOversees: 'domestic',
        payment_domesticOrOversees: 'domestic',
        statementHonorificPosition: 'AFTER',
        statementHonorificTitle: '様',
        paymentHonorificPosition: 'AFTER',
        paymentHonorificTitle: '様',
        statementLang: 'japanese',
        statementHeader_type: 'united',
        statementDetailZeroOmit: false,
        statementAddresseeType: 'input',
        paymentAddresseeType: 'input',
        statementAddresseeId: null,
        paymentAddresseeId: null,
        payeeType: 'input',
        payeeId: null,
        bankDomesticOrOversees: 'domestic',
        depositItem: '1',
        taxation: true,
        withholdingTaxation: 'personal',
        withholdingRate: 0,
        carriedForwardLine: 0,
        payOrCarryForward: 'pay',
        managementFeeRate: 0,
        statementSendType: 'online',
        taxationType: 'taxation',
        toTaxationSchedule: true,
        useRightsHolderSetting: false,
        invoiceExemptionTaxPayable: 'pay',
        invoiceSmallException: 'adapt',
        invoiceTransitionalMeasures: 'adapt',
        omitAllPdf: false,
        paymentOnHoliday: 'before',
        paymentDateType: 'last',
        paymentDataDefinitionId: '',
        paymentPdfDefinitionId: '',
        destinationList: [],
        memberList: [],
        divTermList: divTermList.value
      }
      for (const term of form.value.divTermList) {
        term.target = true
        term.companyDivTermSettingId = term.id
      }
      const company = await companyFunc.get(session.session.company.id)
      form.value.carriedForwardLine = company.defaultCarriedForwardLine
      form.value.statementDetailZeroOmit = company.statementDetailZeroOmit
      form.value.statementGreeting = company.statementGreeting
      form.value.paymentGreeting = company.paymentGreeting
    }
    const initEdit = async (id) => {
      form.value = {
        id: id
      }
      form.value = await rightsHolderFunc.get(form.value.id)
      if (!form.value.paymentDataDefinitionId) {
        form.value.paymentDataDefinitionId = ''
      }
      form.value.destinationList = []
      if (form.value.statementAddresseeType === 'master') {
        const addressee = await addresseeFunc.get(form.value.statementAddresseeId)
        form.value.statementAddresseeName = addressee.name
      }
      if (form.value.paymentAddresseeType === 'master') {
        const addressee = await addresseeFunc.get(form.value.paymentAddresseeId)
        form.value.paymentAddresseeName = addressee.name
      }
      if (form.value.payeeType === 'master') {
        const payee = await payeeFunc.get(form.value.payeeId)
        form.value.payeeName = payee.name
      }
      // form.value.destinationList = await rightsHolderFunc.findDestination(form.value.id)
      advanceList.value = await rightsHolderFunc.findAdvance(form.value.id)
    }
    const changeGroup = () => {
      if (form.value.groupRightsHolder) {
        rightAreaMode.value = 'MEMBER'
      } else {
        rightAreaMode.value = 'STATEMENT'
      }
    }
    const addAdvance = () => {
      advanceList.value.push({
        advanceType: 'rights_holder',
        taxation: false
      })
    }
    const dragList = (event, dragIndex) => {
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.setData('drag-index', dragIndex)
    }
    const dropList = (event, dropIndex) => {
      const dragIndex = parseInt(event.dataTransfer.getData('drag-index'))
      const deleteList = advanceList.value.splice(dragIndex, 1)
      advanceList.value.splice(dropIndex, 0, deleteList[0])
    }
    const checkInvoiceNo = async () => {
      errors.value = []
      if (!form.value.invoiceNo) {
        errors.value.push('事業者登録番号を入力してください')
      } else {
        if (isNaN(form.value.invoiceNo)) {
          errors.value.push('事業者登録番号は半角数字で入力してください')
        }
        if (form.value.invoiceNo.length !== 13) {
          errors.value.push('事業者登録番号は13桁で入力してください')
        }
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        processMessage.value = '事業者登録番号のチェックをおこなっています。しばらくお待ちください。'
        processModal.show()
        const invoice = rightsHolderFunc.getInvoice(form.value.invoiceNo)
        if (invoice && invoice.count > 0) {
          const announcement = invoice.announcement[0]
          messages.value = ['該当の番号で国税庁に登録されている方は以下の通りです。\n名前 : ' + announcement.name + '\n住所 : ' + announcement.address]
          messageToast.show()
        } else {
          errors.value = ['国税庁のデータベースに登録されていない番号です。正しく入力されているか確認してください。']
          errorToast.show()
        }
        processModal.hide()
      }
    }
    onMounted(async () => {
      session.session = await auth.getSession()
      errorToast = new Toast(errorToastDiv.value, {})
      messageToast = new Toast(messageToastDiv.value, {})
      processModal = new Modal(processModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      deleteModal = new Modal(deleteModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      bankSearchModal = new Modal(bankSearchModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      addresseeSearchModal = new Modal(addresseeSearchModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      payeeSearchModal = new Modal(payeeSearchModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      destinationSearchModal = new Modal(destinationSearchModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      memberModal = new Modal(memberModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      divTermList.value = await companyFunc.findDivTermSetting(session.session.company.id)
      paymentPdfDefinitionList.value = await paymentPdfDefinitionFunc.find()
      paymentDataDefinitionList.value = await paymentDataDefinitionFunc.find()
      if (route.path.includes('/add')) {
        await initAdd()
        form.value.paymentPdfDefinitionId = paymentPdfDefinitionList.value.find(x => x.defaultDefinition).id
        form.value.paymentDataDefinitionId = paymentDataDefinitionList.value.find(x => x.defaultDefinition).id
      } else {
        await initEdit(route.params.id)
      }
    })
    onUnmounted(() => {
      errorToast.hide()
      messageToast.hide()
      processModal.hide()
      deleteModal.hide()
      bankSearchModal.hide()
      addresseeSearchModal.hide()
      payeeSearchModal.hide()
      destinationSearchModal.hide()
      memberModal.hide()
    })
    return {
      router,
      errors,
      errorToastDiv,
      messages,
      messageToastDiv,
      processModalDiv,
      processMessage,
      deleteModalDiv,
      showDeleteButton,
      addressMode,
      form,
      rightAreaMode,
      bankSearchModalDiv,
      bankCondition,
      bankResult,
      bankList,
      addresseeMode,
      addresseeSearchModalDiv,
      addresseeResult,
      addresseeList,
      payeeSearchModalDiv,
      payeeResult,
      payeeList,
      destinationSearchModalDiv,
      destinationCondition,
      destinationResult,
      destinationList,
      memberModalDiv,
      memberSearchResult,
      memberSearchList,
      save,
      getAddress,
      copyStatement,
      copyPayment,
      findBank,
      bankClearSearch,
      bankFirstPage,
      bankPrevPage,
      bankNextPage,
      bankLastPage,
      openBankSearch,
      selectBank,
      clearBank,
      findAddressee,
      addresseeClearSearch,
      addresseeFirstPage,
      addresseePrevPage,
      addresseeNextPage,
      addresseeLastPage,
      openAddresseeSearch,
      selectAddressee,
      clearSelectedAddressee,
      findPayee,
      payeeClearSearch,
      payeeFirstPage,
      payeePrevPage,
      payeeNextPage,
      payeeLastPage,
      openPayeeSearch,
      selectPayee,
      clearSelectedPayee,
      findDestination,
      destinationClearSearch,
      destinationFirstPage,
      destinationPrevPage,
      destinationNextPage,
      destinationLastPage,
      openDestinationSearch,
      selectDestination,
      openDeleteModal,
      executeDelete,
      changeHonorificPosition,
      changeGroup,
      openMemberSearch,
      memberFind,
      memberClearSearch,
      memberFirstPage,
      memberPrevPage,
      memberNextPage,
      memberLastPage,
      selectMember,
      divTermList,
      advanceList,
      addAdvance,
      dragList,
      dropList,
      checkInvoiceNo,
      addresseeCondition,
      payeeCondition,
      memberCondition,
      paymentDataDefinitionList,
      paymentPdfDefinitionList
    }
  }
})
</script>
