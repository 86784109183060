<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-6 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-database fa-fw" style="margin-right: 5px;"></i>分配データ定義一覧</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <router-link to="/red/payment/data/definition/add" class="btn btn-outline-primary"><i class="fas fa-plus fa-fw" style="margin-right: 5px;"></i>追加</router-link>
            </div>
          </div>
        </div>
        <div style="position: fixed; top: 60px; left: 270px; right: 30px; bottom: 120px; overflow: auto;">
          <table class="table table-responsive table-hover">
            <thead>
              <tr>
                <th style="width: 55px;"></th>
                <th>名前</th>
                <th style="width: 100px;"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(definition, index) in definitionList" :key="index" class="text-start align-middle">
                <td class="text-center">
                  <router-link :to="'/red/payment/data/definition/disp/' + definition.id" class="btn btn-outline-primary btn-sm"><i class="fas fa-magnifying-glass-plus fa-fw"></i></router-link>
                </td>
                <td>{{definition.name}}</td>
                <td class="text-end">
                  <span v-if="definition.defaultDefinition" class="badge bg-success">デフォルト</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row" style="position: fixed; bottom: 40px; left: 270px; right: 30px;">
          <div class="col-6 d-flex justify-content-start">
            <span v-if="definitionList.length > 0">{{ definitionList.length.toLocaleString() }}件の一致データ</span>
            <span v-else style="margin-top: 20px;">一致データが存在しません</span>
          </div>
        </div>
      </div>
    </main>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import footerComponent from '@/components/Footer'
import navComponent from '@/components/Nav'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { paymentDataDefinitionFunction } from './function'

export default defineComponent({
  components: { navComponent, footerComponent },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const auth = authFunction()
    const session = sessionStore()
    // 検索
    const definitionFunc = paymentDataDefinitionFunction()
    const definitionList = ref([])
    // メソッド
    const find = async () => {
      definitionList.value = await definitionFunc.find()
    }
    onMounted(async () => {
      session.session = await auth.getSession()
      await find()
    })
    return {
      router,
      route,
      definitionList,
      find
    }
  }
})
</script>
<style scoped>
.no-data-row {
  background-color: #dddddd;
}
</style>
