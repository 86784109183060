<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/red/contract/list"><i class="fas fa-calendar-lines-pen fa-fw" style="margin-right: 5px;"></i>
                      契約書一覧
                    </router-link>
                  </li>
                  <li v-if="form && form.id" class="breadcrumb-item">
                    <router-link :to="'/red/contract/disp/' + form.id"><i class="fas fa-eye" style="margin-right: 5px;"></i>閲覧</router-link>
                  </li>
                  <li v-if="form && form.id" class="breadcrumb-item active" aria-current="page"><i class="fas fa-edit" style="margin-right: 5px;"></i>編集</li>
                  <li v-else class="breadcrumb-item active" aria-current="page"><i class="fas fa-plus" style="margin-right: 5px;"></i>追加</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <button @click="save" class="btn btn-primary" style="margin-left: 5px;"><i class="fas fa-save fa-fw"></i></button>
              <button v-if="showDeleteButton" @click="openDeleteModal" class="btn btn-danger" style="margin-left: 5px;"><i class="fas fa-trash-alt fa-fw"></i></button>
              <button v-if="form && form.id" @click="showDeleteButton = !showDeleteButton" class="btn" style="padding-right: 0;"><i class="fas fa-caret-left"></i></button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="form" class="text-start" style="position: fixed; top: 65px; left: 270px; right: 30px; bottom: 50px; overflow: auto;">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>名前</div>
              <div class="form-edit-line">
                <input v-model="form.name" type="text" class="form-control">
              </div>
              <div class="form-label align-bottom">管理コード</div>
              <div class="form-edit-line">
                <input v-model="form.code" type="text" class="form-control" maxlength="20" style="width: 250px;">
              </div>
              <div class="form-label align-bottom">権利者</div>
              <div class="form-edit-line">
                <div class="input-group">
                  <input v-model="form.rightsHolderName" type="text" class="form-control" readonly="readonly">
                  <button v-if="form.rightsHolderId" @click="clearSelectedRightsHolder" class="btn btn-outline-primary"><i
                      class="fas fa-undo"></i></button>
                  <button @click="openRightsHolderSearch" class="btn btn-primary"><i class="fas fa-search"></i></button>
                </div>
              </div>
              <div class="form-label align-bottom">ステータス</div>
              <div class="form-edit-line">
                <select v-model="form.status" class="form-select">
                  <option value="agree">契約中</option>
                  <option value="not_agree">未契約（保留）</option>
                  <option value="not_pay">全て自社取分とする</option>
                </select>
              </div>
  <!--          <div class="form-label align-bottom">免税事業者に対する扱い</div>-->
  <!--          <div class="form-edit-line">-->
  <!--            <div class="form-check form-switch">-->
  <!--              <input v-model="form.use_contract_setting" class="form-check-input" type="checkbox" id="use_rights_holder_setting">-->
  <!--              <label class="form-check-label" for="use_rights_holder_setting">契約書で個別設定</label>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--          <div v-show="form.use_contract_setting" class="form-label align-bottom">免税事業者に対する消費税相当額の扱い</div>-->
  <!--          <div v-show="form.use_contract_setting" class="form-edit-line">-->
  <!--            <select v-model="form.invoice_exemption_tax_payable" class="form-select">-->
  <!--              <option value="pay">課税事業者と同様に全額支払う</option>-->
  <!--              <option value="deduct">消費税相当額を支払額から控除する</option>-->
  <!--            </select>-->
  <!--          </div>-->
  <!--          <div v-show="form.use_contract_setting && form.invoice_exemption_tax_payable === 'deduct'" class="form-label align-bottom">少額特例への対応</div>-->
  <!--          <div v-show="form.use_contract_setting && form.invoice_exemption_tax_payable === 'deduct'" class="form-edit-line">-->
  <!--            <select v-model="form.invoice_small_exception" class="form-select">-->
  <!--              <option value="adapt">対応する</option>-->
  <!--              <option value="not_adapt">対応しない</option>-->
  <!--            </select>-->
  <!--          </div>-->
  <!--          <div v-show="form.use_contract_setting && form.invoice_exemption_tax_payable === 'deduct'" class="form-label align-bottom">経過措置への対応</div>-->
  <!--          <div v-show="form.use_contract_setting && form.invoice_exemption_tax_payable === 'deduct'" class="form-edit-line">-->
  <!--            <select v-model="form.invoice_transitional_measures" class="form-select">-->
  <!--              <option value="adapt">対応する</option>-->
  <!--              <option value="not_adapt">対応しない</option>-->
  <!--            </select>-->
  <!--          </div>-->
              <div class="card" style="margin-bottom: 10px;">
                <div class="card-header">
                  支払条件
                  <button @click="openConditionModal(null)" class="btn btn-outline-primary btn-sm" style="margin-left: 5px;"><i class="fas fa-plus"></i></button>
                </div>
                <div class="card-body" style="padding: 10px 5px 5px 10px;">
                  <table v-if="form.conditionList && form.conditionList.length > 0" class="table table-responsive">
                    <thead>
                      <tr>
                        <th>利用種別</th>
                        <th>頒布形態</th>
                        <th>印税種別</th>
                        <th v-show="form.status !== 'not_pay'">料率</th>
                        <th style="width: 40px;"></th>
                        <th style="width: 40px;"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(condition, index) in form.conditionList" :key="index" :class="{ 'deleted-row': condition.deleted }">
                        <td>
                          <span v-if="condition.useTypeId && condition.useTypeId !== 'none'">{{condition.useTypeName}}</span>
                          <span v-else>-</span>
                        </td>
                        <td>
                          <span v-if="condition.useTypeId && condition.useTypeId !== 'none' && condition.userSalesId && condition.userSalesId !== 'none'">{{condition.userSalesName}}</span>
                          <span v-else>-</span>
                        </td>
                        <td>{{condition.royaltyTypeName}}</td>
                        <td v-show="form.status !== 'not_pay'">
                          <span v-if="condition.calcType === 'unit_rate' || condition.calcType === 'div_rate' || condition.calcType === 'after_rate'">
                            <span v-if="condition.royaltyRateType === 'decimal'">{{condition.royaltyRate}}%</span>
                            <span v-else>{{condition.royaltyRateC}} / {{condition.royaltyRateM}}</span>
                          </span>
                        </td>
                        <td class="text-center" style="padding-left: 0!important; padding-right: 0!important;">
                          <button @click="openConditionModal(index)" class="btn btn-outline-primary btn-sm"><i class="fas fa-edit"></i></button>
                        </td>
                        <td class="text-center" style="padding-left: 0!important; padding-right: 0!important;">
                          <button @click="condition.deleted = !condition.deleted" class="btn btn-outline-danger btn-sm"><i class="fas fa-trash-alt"></i></button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="form-label align-bottom">メモ</div>
              <div class="form-edit-line">
                <textarea v-model="form.memo" class="form-control" rows="8"></textarea>
              </div>
              <div style="height: 50px;"></div>
            </div>
            <div class="col-lg-6">
              <ul class="nav nav-tabs" style="margin-bottom: 10px;">
                <li class="nav-item">
                  <a @click="rightAreaMode = 'MASTER'" class="nav-link" :class="{ 'active': rightAreaMode === 'MASTER' }" aria-current="page" href="#">
                    原盤
                    <button v-if="rightAreaMode === 'MASTER'" @click="openMasterSearch" class="btn btn-outline-primary btn-sm" style="margin-left: 5px;"><i class="fas fa-edit"></i></button>
                  </a>
                </li>
                <li class="nav-item">
                  <a @click="rightAreaMode = 'ALBUM'" class="nav-link" :class="{ 'active': rightAreaMode === 'ALBUM' }" aria-current="page" href="#">
                    アルバム
                    <button v-if="rightAreaMode === 'ALBUM'" @click="openAlbumSearch" class="btn btn-outline-primary btn-sm" style="margin-left: 5px;"><i class="fas fa-edit"></i></button>
                  </a>
                </li>
                <li class="nav-item">
                  <a @click="rightAreaMode = 'SUSPENSION'" v-if="suspensionList.length > 0" class="nav-link" :class="{ 'active': rightAreaMode === 'SUSPENSION' }" aria-current="page" href="#">
                    保留期
                  </a>
                </li>
              </ul>
              <div v-if="rightAreaMode === 'SUSPENSION'">
                <table class="table table-responsive">
                  <tbody>
                    <tr v-for="suspension in suspensionList" :key="suspension.id">
                      <td>{{suspension.divYear}}年{{suspension.divTerm}}期</td>
                      <td class="text-end">
                        <div class="form-check form-check-inline">
                          <input v-model="suspension.retroactiveTarget" class="form-check-input" type="radio" name="suspensionTarget" id="suspensionTargetPay" value="true">
                          <label class="form-check-label" for="suspensionTargetPay">解除時支払対象</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input v-model="suspension.retroactiveTarget" class="form-check-input" type="radio" name="suspensionTarget" id="suspensionTargetHold" value="false">
                          <label class="form-check-label" for="suspensionTargetHold">解除しても支払わない</label>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-if="rightAreaMode === 'ALBUM'">
                <div v-if="form.id" class="input-group">
                  <input @keydown.enter="albumList = []; albumPage = 0; findContractAlbum" v-model="albumSearchString" type="text" class="form-control" placeholder="検索条件">
                  <button @click="albumList = []; albumSearchString = ''; albumPage = 0; findContractAlbum();" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
                  <button @click="albumList = []; albumPage = 0; findContractAlbum" class="btn btn-primary"><i class="fas fa-search"></i></button>
                </div>
                <table v-if="albumList.length > 0" class="table table-responsive">
                  <tbody>
                    <tr v-for="album in albumList" :key="album.id" :class="{ 'deleted-row': (album && album.deleted) }" class="align-middle">
                      <td style="vertical-align: middle;">
                        <p v-if="album.catalogNo" style="margin: 0 0 3px 0; font-size: 80%;"><span v-html="album.catalogNo"></span></p>
                        <p style="margin: 0;">{{ album.name }}</p>
                        <p v-if="album.artistName" style="margin: 3px 0 0 0; font-size: 80%;">アーティスト : {{album.artistName}}</p>
                      </td>
                      <td class="text-center" style="width: 55px;">
                        <button @click="album.deleted = !album.deleted" class="btn btn-outline-danger btn-sm"><i class="fas fa-trash-alt fa-fw"></i></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-if="rightAreaMode === 'MASTER'">
                <div v-if="form.id" class="input-group">
                  <input @keydown.enter="masterList = []; masterPage = 0; findContractMaster();" v-model="masterSearchString" type="text" class="form-control" placeholder="検索条件">
                  <button @click="masterList = []; masterSearchString = ''; masterPage = 0; findContractMaster();" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
                  <button @click="masterList = []; masterPage = 0; findContractMaster();" class="btn btn-primary"><i class="fas fa-search"></i></button>
                </div>
                <table v-if="masterList" class="table table-responsive">
                  <tbody>
                    <tr v-for="master in masterList" :key="master.id" :class="{ 'deleted-row': (master && master.deleted) }" class="align-middle">
                      <td style="vertical-align: middle;">
                        <p v-if="master.isrc" style="margin: 0 0 3px 0; font-size: 80%;"><span v-html="master.isrc"></span></p>
                        <p style="margin: 0;">{{ master.displayName }}</p>
                        <p v-if="master.artistName" style="margin: 3px 0 0 0; font-size: 80%;">アーティスト : {{master.artistName}}</p>
                      </td>
                      <td class="text-center" style="width: 55px;">
                        <button @click="master.deleted = !master.deleted" class="btn btn-outline-danger btn-sm"><i class="fas fa-trash-alt fa-fw"></i></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="deleteModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">契約書の削除</h5>
          </div>
          <div v-if="form" class="modal-body text-start">
            <div class="alert alert-danger">以下の契約書を削除しようとしています。削除すると、契約書情報の確認ができなくなり、該当契約書が紐づいている契約書、アルバム、契約書の契約書情報がクリアされます。</div>
            <div class="form-label align-bottom">名前</div>
            <div class="form-edit-line">
              {{ form.name }}
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="executeDelete" type="button" class="btn btn-danger" style="margin-right: 5px;">
                <i class="fas fa-trash-alt" style="margin-right: 5px;"></i>注意事項を理解した上で削除を実行する
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="rightsHolderModalDiv">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">権利者選択</h5>
          </div>
          <div class="modal-body text-start">
            <div class="input-group" style="margin-top: 15px;">
              <input @keypress.enter="rightsHolderCondition.page = 0; rightsHolderFind();" v-model="rightsHolderCondition.searchString" type="text" class="form-control" placeholder="検索条件">
              <button v-show="rightsHolderCondition.searchString" @click="rightsHolderClearSearch" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
              <button @click="rightsHolderCondition.page = 0; rightsHolderFind();" class="btn btn-primary"><i class="fas fa-search"></i></button>
            </div>
            <table v-if="rightsHolderSearchResult && rightsHolderSearchResult.allRecords > 0" class="table table-responsive table-hover">
              <thead>
                <tr>
                  <th>名前</th>
                  <th style="width: 70px;"></th>
                </tr>
              </thead>
              <tbody v-if="rightsHolderSearchList">
                <tr v-for="(rightsHolder, index) in rightsHolderSearchList" :key="rightsHolder.id" class="text-start">
                  <td>{{ rightsHolder.name }}</td>
                  <td class="text-end">
                    <button @click="selectRightsHolder(index)" class="btn btn-outline-primary btn-sm"><i class="fas fa-plus"></i></button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="row">
              <div class="col-6 d-flex justify-content-start">
                <span v-if="rightsHolderSearchResult && rightsHolderSearchResult.allRecords > 0">{{ rightsHolderSearchResult.allRecords }}件の一致データ</span>
                <span v-else style="margin-top: 20px;">一致データが存在しません</span>
              </div>
              <div v-if="rightsHolderSearchResult && rightsHolderSearchResult.allRecords > 0" class="col-6 d-flex justify-content-end">
                <nav v-if="rightsHolderSearchResult" aria-label="Page navigation">
                  <ul class="pagination">
                    <li v-if="rightsHolderCondition.page !== 0" class="page-item"><button @click="rightsHolderFirstPage" class="page-link" href="#"><i class="fas fa-fast-backward"></i></button></li>
                    <li v-if="rightsHolderCondition.page !== 0" class="page-item"><button @click="rightsHolderPrevPage" class="page-link" href="#"><i class="fas fa-chevron-left"></i></button></li>
                    <li class="page-item"><span class="page-link">{{ rightsHolderCondition.page + 1 }}/{{ rightsHolderSearchResult.allPages }}ページ</span></li>
                    <li v-if="rightsHolderCondition.page !== rightsHolderSearchResult.allPages - 1" class="page-item"><button @click="rightsHolderNextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button>
                    </li>
                    <li v-if="rightsHolderCondition.page !== rightsHolderSearchResult.allPages - 1" class="page-item"><button @click="rightsHolderLastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="albumModalDiv">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">アルバム選択</h5>
          </div>
          <div class="modal-body text-start">
            <div class="input-group" style="margin-top: 15px;">
              <input @keypress.enter="albumSearchList = []; albumCondition.page = 0; albumFind();" v-model="albumCondition.searchString" type="text" class="form-control" placeholder="検索条件">
              <button v-show="albumCondition.searchString" @click="albumSearchList = []; albumCondition.searchString = ''; albumCondition.page = 0; albumFind();" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
              <button @click="albumSearchList = []; albumCondition.page = 0; albumFind();" class="btn btn-primary"><i class="fas fa-search"></i></button>
            </div>
            <div class="text-end" style="margin-top: 10px;">
              <span v-if="albumSearchResult && albumSearchResult.allRecords > 0">{{ albumSearchResult.allRecords.toLocaleString() }}件の一致データ</span>
              <span v-else style="margin-top: 20px;">一致データが存在しません</span>
            </div>
            <table v-if="albumSearchResult && albumSearchResult.allRecords > 0" class="table table-responsive table-hover">
              <thead>
                <tr>
                  <th style="width: 55px;"></th>
                  <th style="width: 150px;">品番</th>
                  <th>タイトル</th>
                  <th>アーティスト</th>
                </tr>
              </thead>
              <tbody v-if="albumSearchList">
                <tr v-for="album in albumSearchList" :key="album.id" @click="album.selected = !album.selected" class="text-start align-middle" :class="{ 'selected-row': album.selected }">
                  <td style="width: 55px; vertical-align: middle;" class="text-end">
                    <span v-if="!album.selected"><button class="btn btn-light"><i class="far fa-square"></i></button></span>
                    <span v-if="album.selected"><button class="btn btn-light"><i class="far fa-check-square"></i></button></span>
                  </td>
                  <td style="width: 150px;"><span v-html="album.catalogNo"></span></td>
                  <td>
                    {{ album.name }}
                  </td>
                  <td>{{album.artistName}}</td>
                </tr>
              </tbody>
              <tfoot v-if="albumSearchResult.allRecords > albumSearchList.length">
                <tr>
                  <td colspan="5">
                    <div class="d-grid gap-2">
                      <button @click="albumCondition.page = albumSearchResult.page + 1; albumFind();" class="btn btn-primary"><i class="fas fa-arrow-down" style="margin-right: 5px;"></i>もっと読み込む</button>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="selectAlbum" class="btn btn-outline-primary"><i class="fas fa-plus fa-fw" style="margin-right: 5px;"></i>追加</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="masterModalDiv">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">原盤選択</h5>
          </div>
          <div class="modal-body text-start">
            <div class="input-group" style="margin-top: 15px;">
              <input @keypress.enter="masterSearchList = []; masterCondition.page = 0; masterFind();" v-model="masterCondition.searchString" type="text" class="form-control" placeholder="検索条件">
              <button v-show="masterCondition.searchString" @click="masterSearchList = []; masterCondition.searchString = ''; masterCondition.page = 0; masterFind();" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
              <button @click="masterSearchList = []; masterCondition.page = 0; masterFind();" class="btn btn-primary"><i class="fas fa-search"></i></button>
            </div>
            <div class="text-end" style="margin-top: 10px;">
              <span v-if="masterSearchResult && masterSearchResult.allRecords > 0">{{ masterSearchResult.allRecords.toLocaleString() }}件の一致データ</span>
              <span v-else style="margin-top: 20px;">一致データが存在しません</span>
            </div>
            <table v-if="masterSearchResult && masterSearchResult.allRecords > 0" class="table table-responsive table-hover">
              <thead>
                <tr>
                  <th style="width: 55px;"></th>
                  <th style="width: 150px;">ISRC</th>
                  <th>原盤名</th>
                  <th>アーティスト</th>
                </tr>
              </thead>
              <tbody v-if="masterSearchList">
                <tr v-for="master in masterSearchList" :key="master.id" class="text-start align-middle" @click="master.selected = !master.selected" :class="{ 'selected-row': master.selected }">
                  <td style="width: 55px; vertical-align: middle;" class="text-end">
                    <span v-if="!master.selected"><button class="btn btn-light"><i class="far fa-square"></i></button></span>
                    <span v-if="master.selected"><button class="btn btn-light"><i class="far fa-check-square"></i></button></span>
                  </td>
                  <td style="width: 150px;">
                    <span v-html="master.isrc"></span>
                  </td>
                  <td>{{master.displayName}}</td>
                  <td>{{master.artistName}}</td>
                </tr>
              </tbody>
              <tfoot v-if="masterSearchResult.allRecords > masterSearchList.length">
                <tr>
                  <td colspan="5">
                    <div class="d-grid gap-2">
                      <button @click="masterCondition.page = masterSearchResult.page + 1; masterFind();" class="btn btn-primary"><i class="fas fa-arrow-down" style="margin-right: 5px;"></i>もっと読み込む</button>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="selectMaster" class="btn btn-outline-primary"><i class="fas fa-plus fa-fw" style="margin-right: 5px;"></i>追加</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="conditionModalDiv">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">支払条件</h5>
          </div>
          <div class="modal-body text-start">
            <div class="form-label align-bottom">利用種別</div>
            <div class="form-edit-line">
              <select v-model="conditionForm.useTypeId" @change="changeUseType" class="form-select">
                <option value="none">-</option>
                <option v-for="useType in conditionForm.useTypeList" :key="useType.id" :value="useType.id">
                  {{useType.name}}
                </option>
              </select>
            </div>
            <div v-if="conditionForm.useTypeId !== 'none'" class="form-label align-bottom">頒布形態</div>
            <div v-if="conditionForm.useTypeId !== 'none'" class="form-edit-line">
              <select v-model="conditionForm.userSalesId" class="form-select">
                <option value="none">-</option>
                <option v-for="userSales in conditionForm.userSalesList" :key="userSales.id" :value="userSales.id">
                  {{userSales.name}}
                </option>
              </select>
            </div>
            <div v-show="form && form.status !== 'not_pay'">
              <div class="form-label align-bottom">印税種別</div>
              <div class="form-edit-line">
                <select v-model="conditionForm.royaltyTypeId" @change="changeUseType" class="form-select">
                  <option v-for="royaltyType in conditionForm.royaltyTypeList" :key="royaltyType.id" :value="royaltyType.id">
                    {{royaltyType.name}}
                  </option>
                </select>
              </div>
              <div class="form-label align-bottom">計算式</div>
              <div class="form-edit-line">
                <select v-model="conditionForm.calcType" class="form-select">
                  <option value="unit_rate">単価計算</option>
                  <option value="unit_price">固定単価</option>
                  <option value="div_rate">分配率（受領額）</option>
                  <option value="div_rate_revenue">分配率（売上）</option>
                  <option value="fix_price">固定金額</option>
                  <option value="after_rate">手残り計算（受領額）</option>
                  <option value="after_rate_revenue">手残り計算（売上）</option>
                </select>
              </div>
              <div class="form-edit-line">
                ※計算方法 :
                <span v-if="conditionForm.calcType === 'unit_rate'">算出単価（(価格 - ジャケット代) x 印税率）x 計算対象数（使用数 x 出庫率）</span>
                <span v-if="conditionForm.calcType === 'unit_price'">固定単価 x 計算対象数（使用数 x 出庫率）</span>
                <span v-if="conditionForm.calcType === 'div_rate'">(受領額 - トップオフ) x 分配率</span>
                <span v-if="conditionForm.calcType === 'div_rate_revenue'">(売上 - トップオフ) x 分配率</span>
                <span v-if="conditionForm.calcType === 'fix_price'">指定した金額</span>
                <span v-if="conditionForm.calcType === 'after_rate'">手残り額（受領額 - トップオフ - その他の支払）x 分配率</span>
                <span v-if="conditionForm.calcType === 'after_rate_revenue'">手残り額（売上 - トップオフ - その他の支払）x 分配率</span>
              </div>
              <div v-if="conditionForm.calcType === 'unit_rate'" class="form-label align-bottom">
                印税単価ベース価格
              </div>
              <div v-if="conditionForm.calcType === 'unit_rate'" class="form-edit-line">
                <select v-model="conditionForm.unitPriceBasePrice" class="form-select">
                  <option value="sales_price_out_tax">税抜販売価格</option>
                  <option value="sales_price_in_tax">税込販売価格</option>
                  <option value="whole_price_out_tax">税抜卸価格</option>
                  <option value="whole_price_in_tax">税込卸価格</option>
                </select>
              </div>
              <div v-if="conditionForm.calcType === 'unit_rate'" class="form-label align-bottom">
                ジャケット代ベース価格
              </div>
              <div v-if="conditionForm.calcType === 'unit_rate'" class="form-edit-line">
                <select v-model="conditionForm.jacketDeductionBasePrice" class="form-select">
                  <option value="sales_price_out_tax">税抜販売価格</option>
                  <option value="sales_price_in_tax">税込販売価格</option>
                  <option value="whole_price_out_tax">税抜卸価格</option>
                  <option value="whole_price_in_tax">税込卸価格</option>
                </select>
              </div>
              <div v-show="conditionForm.calcType === 'unit_rate' || conditionForm.calcType === 'div_rate' || conditionForm.calcType === 'div_rate_revenue' || conditionForm.calcType === 'after_rate' || conditionForm.calcType === 'after_rate_revenue'" class="form-label align-bottom">
                <span v-if="conditionForm.calcType === 'unit_rate'">印税率</span>
                <span v-if="conditionForm.calcType === 'div_rate' || conditionForm.calcType === 'div_rate_revenue' || conditionForm.calcType === 'after_rate'">分配率</span>
              </div>
              <div v-show="conditionForm.calcType === 'unit_rate' || conditionForm.calcType === 'div_rate' || conditionForm.calcType === 'div_rate_revenue' || conditionForm.calcType === 'after_rate' || conditionForm.calcType === 'after_rate_revenue'" class="form-edit-line">
                <div class="input-group">
                  <select v-model="conditionForm.royaltyRateType" class="form-select" style="flex: 0 1 100px;">
                    <option value="decimal">百分率</option>
                    <option value="fraction">分数</option>
                  </select>
                  <input v-if="conditionForm.royaltyRateType === 'decimal'" v-model="conditionForm.royaltyRate" type="number" class="form-control" min="0" style="flex: 0 1 150px;">
                  <span v-if="conditionForm.royaltyRateType === 'decimal'" class="input-group-text">%</span>
                  <input v-if="conditionForm.royaltyRateType === 'fraction'" v-model="conditionForm.royaltyRateC" type="number" class="form-control" min="0" style="flex: 0 1 120px;">
                  <span v-if="conditionForm.royaltyRateType === 'fraction'" class="input-group-text">/</span>
                  <input v-if="conditionForm.royaltyRateType === 'fraction'" v-model="conditionForm.royaltyRateM" type="number" class="form-control" min="0" style="flex: 0 1 120px;">
                </div>
              </div>
              <div v-show="conditionForm.calcType === 'unit_price'" class="form-label align-bottom">固定単価</div>
              <div v-show="conditionForm.calcType === 'unit_price'" class="form-edit-line">
                <div class="input-group">
                  <span v-if="conditionForm.royaltyRateType === 'decimal'" class="input-group-text">￥</span>
                  <input v-if="conditionForm.royaltyRateType === 'decimal'" v-model="conditionForm.unitPrice" type="number" class="form-control" min="0" style="flex: 0 1 180px;">
                </div>
              </div>
              <div v-show="conditionForm.calcType === 'fix_price'" class="form-label align-bottom">固定金額</div>
              <div v-show="conditionForm.calcType === 'fix_price'" class="form-edit-line">
                <div class="input-group">
                  <span v-if="conditionForm.royaltyRateType === 'decimal'" class="input-group-text">￥</span>
                  <input v-if="conditionForm.royaltyRateType === 'decimal'" v-model="conditionForm.fixPrice" type="number" class="form-control" min="0" style="flex: 0 1 180px;">
                </div>
              </div>
              <div v-show="conditionForm.calcType === 'fix_price'" class="form-label align-bottom">支払回数</div>
              <div v-show="conditionForm.calcType === 'fix_price'" class="form-edit-line">
                <select v-model="conditionForm.fixPricePayCountType" class="form-select">
                  <option value="one">1回</option>
                  <option value="forever">無制限</option>
                </select>
              </div>
              <div v-show="conditionForm.calcType === 'div_rate' || conditionForm.calcType === 'div_rate_revenue' || conditionForm.calcType === 'after_rate' || conditionForm.calcType === 'after_rate_revenue'" class="form-label align-bottom">
                トップオフ料率
                <button @click="addTopOff" class="btn btn-outline-primary btn-sm"><i class="fas fa-plus fa-fw" style="margin-right: 5px;"></i>追加</button>
              </div>
              <div v-show="conditionForm.calcType === 'div_rate' || conditionForm.calcType === 'div_rate_revenue' || conditionForm.calcType === 'after_rate' || conditionForm.calcType === 'after_rate_revenue'" class="form-edit-line">
                <table class="table table-responsive">
                  <tbody>
                    <tr v-for="(topOff, index) in conditionForm.topOffList" :key="index" draggable="true"
                        @dragstart="dragList($event, index)" @drop="dropList($event, index)" @dragover.prevent
                        @dragenter.prevent :class="{'deleted-row': topOff.deleted}">
                      <td>
                        <input v-model="topOff.title" type="text" class="form-control" placeholder="用途／目的">
                      </td>
                      <td style="width: 90px;">
                        <select v-model="topOff.topOffType" class="form-select">
                          <option value="rate">率</option>
                          <option value="amount">額</option>
                        </select>
                      </td>
                      <td v-if="topOff.topOffType === 'rate'" style="width: 120px;">
                        <select v-model="topOff.topOffRateType" class="form-select">
                          <option value="decimal">百分率</option>
                          <option value="fraction">分数</option>
                        </select>
                      </td>
                      <td v-if="topOff.topOffType === 'rate'">
                        <div class="input-group">
                          <input v-if="topOff.topOffRateType === 'decimal'" v-model="topOff.topOffRate" type="number" class="form-control" min="0" style="flex: 0 1 100px;">
                          <span v-if="topOff.topOffRateType === 'decimal'" class="input-group-text">%</span>
                          <input v-if="topOff.topOffRateType === 'fraction'" v-model="topOff.topOffRateC" type="number" class="form-control" min="0" style="flex: 0 1 80px;">
                          <span v-if="topOff.topOffRateType === 'fraction'" class="input-group-text">/</span>
                          <input v-if="topOff.topOffRateType === 'fraction'" v-model="topOff.topOffRateM" type="number" class="form-control" min="0" style="flex: 0 1 80px;">
                        </div>
                      </td>
                      <td v-if="topOff.topOffType === 'amount'" colspan="2">
                        <div class="input-group">
                          <span class="input-group-text">￥</span>
                          <input v-model="topOff.topOffAmount" type="number" class="form-control" min="0" style="flex: 0 1 150px;">
                        </div>
                      </td>
                      <td class="text-center" style="vertical-align: middle;">
                        <button @click="topOff.deleted = !topOff.deleted" class="btn btn-outline-danger btn-sm"><i class="fas fa-trash-alt"></i></button>
                      </td>
                      <td style="width: 20px; cursor: grab; vertical-align: middle;"><i class="fas fa-grip-vertical"></i></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-show="conditionForm.calcType === 'unit_rate'" class="form-label align-bottom">ジャケット代控除率</div>
              <div v-show="conditionForm.calcType === 'unit_rate'" class="form-edit-line">
                <div class="input-group">
                  <select v-model="conditionForm.jacketDeductionRateType" class="form-select" style="flex: 0 1 100px;">
                    <option value="decimal">百分率</option>
                    <option value="fraction">分数</option>
                  </select>
                  <input v-if="conditionForm.jacketDeductionRateType === 'decimal'" v-model="conditionForm.jacketDeductionRate" type="number" class="form-control" min="0" style="flex: 0 1 150px;">
                  <span v-if="conditionForm.jacketDeductionRateType === 'decimal'" class="input-group-text">%</span>
                  <input v-if="conditionForm.jacketDeductionRateType === 'fraction'" v-model="conditionForm.jacketDeductionRateC" type="number" class="form-control" min="0" style="flex: 0 1 120px;">
                  <span v-if="conditionForm.jacketDeductionRateType === 'fraction'" class="input-group-text">/</span>
                  <input v-if="conditionForm.jacketDeductionRateType === 'fraction'" v-model="conditionForm.jacketDeductionRateM" type="number" class="form-control" min="0" style="flex: 0 1 120px;">
                </div>
              </div>
              <div v-show="conditionForm.calcType === 'unit_rate' || conditionForm.calcType === 'unit_price'" class="form-label align-bottom">出庫率</div>
              <div v-show="conditionForm.calcType === 'unit_rate' || conditionForm.calcType === 'unit_price'" class="form-edit-line">
                <div class="input-group">
                  <select v-model="conditionForm.shipRateType" class="form-select" style="flex: 0 1 100px;">
                    <option value="decimal">百分率</option>
                    <option value="fraction">分数</option>
                  </select>
                  <input v-if="conditionForm.shipRateType === 'decimal'" v-model="conditionForm.shipRate" type="number" class="form-control" min="0" style="flex: 0 1 150px;">
                  <span v-if="conditionForm.shipRateType === 'decimal'" class="input-group-text">%</span>
                  <input v-if="conditionForm.shipRateType === 'fraction'" v-model="conditionForm.shipRateC" type="number" class="form-control" min="0" style="flex: 0 1 120px;">
                  <span v-if="conditionForm.shipRateType === 'fraction'" class="input-group-text">/</span>
                  <input v-if="conditionForm.shipRateType === 'fraction'" v-model="conditionForm.shipRateM" type="number" class="form-control" min="0" style="flex: 0 1 120px;">
                </div>
              </div>
              <div class="form-label align-bottom">適用条件</div>
              <div class="form-edit-line">
                <div class="form-check">
                  <input v-model="conditionForm.slide" class="form-check-input" type="checkbox" id="slide">
                  <label class="form-check-label" for="slide">
                    以下の条件でのみ適用する
                  </label>
                </div>
              </div>
              <div v-if="conditionForm.slide" class="form-edit-line">
                <select v-model="conditionForm.slideTarget" class="form-select">
                  <option value="ship_to">累計使用数が指定数以下／未満</option>
                  <option value="ship_from">累計使用数が指定数以上／より大きい</option>
                  <option value="ship_duration">累計使用数が指定の範囲</option>
                  <option value="amount_to">累計受領額（税抜）が指定数以下／未満</option>
                  <option value="amount_from">累計受領額（税抜）が指定数以上／より大きい</option>
                  <option value="amount_duration">累計受領額（税抜）が指定の範囲</option>
                </select>
              </div>
              <div v-if="conditionForm.slide" class="form-edit-line">
                <div class="input-group">
                  <input v-if="conditionForm.slideTarget.indexOf('from') !== -1 || conditionForm.slideTarget.indexOf('duration') !== -1" v-model="conditionForm.slideStartNo" type="number" class="form-control" style="flex: 0 1 150px;">
                  <select v-if="conditionForm.slideTarget.indexOf('from') !== -1 || conditionForm.slideTarget.indexOf('duration') !== -1" v-model="conditionForm.slideStartNoType" class="form-select" style="flex: 0 1 130px;">
                    <option value="gte">以上</option>
                    <option value="gt">より大きい</option>
                  </select>
                  <input v-if="conditionForm.slideTarget.indexOf('to') !== -1 || conditionForm.slideTarget.indexOf('duration') !== -1" v-model="conditionForm.slideEndNo" type="number" class="form-control" style="flex: 0 1 150px;">
                  <select v-if="conditionForm.slideTarget.indexOf('to') !== -1 || conditionForm.slideTarget.indexOf('duration') !== -1" v-model="conditionForm.slideEndNoType" class="form-select" style="flex: 0 1 130px;">
                    <option value="lte">以下</option>
                    <option value="lt">未満</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="addCondition" class="btn btn-outline-primary"><i class="fas fa-plus"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="loadingModalDiv">
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">読み込み中</h5>
          </div>
          <div class="modal-body text-center">
            <i class="fas fa-spinner fa-spin"></i>
            Loading
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="processModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">処理中</h5>
          </div>
          <div class="modal-body text-start">
            {{ processMessage }}
          </div>
        </div>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-danger" role="alert" aria-live="assertive" aria-atomic="true" ref="errorToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">エラー</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(error, index) in errors" :key="index">{{ error }}</p>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-success" role="alert" aria-live="assertive" aria-atomic="true" ref="messageToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-info-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">メッセージ</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(message, index) in messages" :key="index">{{message}}</p>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import navComponent from '@/components/Nav'
import axios from 'axios'
import Md5 from 'crypto-js/md5'
import { Modal, Toast } from 'bootstrap'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import footerComponent from '@/components/Footer.vue'
import { contractFunction } from './function'
import { rightsHolderFunction } from '../rightsholder/function'
import { useTypeFunction } from '../usetype/function'
import { royaltyTypeFunction } from '../royaltytype/function'
import { userFunction } from '../user/function'
import { masterFunction } from '../master/function'
import { albumFunction } from '../album/function'
import { rightsHolderStore, albumStore, masterStore } from './store'

export default defineComponent({
  components: { footerComponent, navComponent },
  setup () {
    const auth = authFunction()
    const session = sessionStore()
    const route = useRoute()
    const router = useRouter()
    const loadingModalDiv = ref(null)
    let loadingModal = null
    const errors = ref([])
    const errorToastDiv = ref(null)
    let errorToast = null
    const messages = ref([])
    const messageToastDiv = ref(null)
    let messageToast = null
    const processMessage = ref('')
    const processModalDiv = ref(null)
    let processModal = null
    const deleteModalDiv = ref(null)
    let deleteModal = null
    const showDeleteButton = ref(false)
    // Form
    const contractFunc = contractFunction()
    const rightsHolderFunc = rightsHolderFunction()
    const useTypeFunc = useTypeFunction()
    const royaltyTypeFunc = royaltyTypeFunction()
    const userFunc = userFunction()
    const form = ref(null)
    const albumSearchString = ref('')
    const albumPage = ref(0)
    const albumResult = ref({})
    const albumList = ref([])
    const masterSearchString = ref('')
    const masterPage = ref(0)
    const masterResult = ref({})
    const masterList = ref([])
    const rightAreaMode = ref('MASTER')
    // 権利者選択
    const rightsHolderCondition = rightsHolderStore()
    const rightsHolderModalDiv = ref(null)
    let rightsHolderModal = null
    const rightsHolderSearchResult = ref(null)
    const rightsHolderSearchList = ref([])
    // アルバム選択
    const albumFunc = albumFunction()
    const albumCondition = albumStore()
    const albumModalDiv = ref(null)
    let albumModal = null
    const albumSearchResult = ref(null)
    const albumSearchList = ref([])
    // 原盤選択
    const masterFunc = masterFunction()
    const masterCondition = masterStore()
    const masterModalDiv = ref(null)
    let masterModal = null
    const masterSearchResult = ref(null)
    const masterSearchList = ref([])
    // 支払条件
    const conditionModalDiv = ref(null)
    let conditionModal = null
    const conditionForm = ref({})
    const conditionEditIndex = ref(null)
    // 保留
    const suspensionList = ref([])
    // 再計算対象
    const calcTargetAlbumIdList = ref([])
    const calcTargetMasterIdList = ref([])
    // メソッド
    const save = async () => {
      errors.value = []
      if (!form.value.name) {
        errors.value.push('名前を入力してください')
      }
      if (!form.value.rightsHolderId) {
        errors.value.push('権利者を選択してください')
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        processMessage.value = '保存処理中です。しばらくこのままでお待ちください。'
        processModal.show()
        for (const condition of form.value.conditionList) {
          if (condition.useTypeId === 'none') {
            condition.useTypeId = null
          }
          if (condition.userSalesId === 'none') {
            condition.userSalesId = null
          }
        }
        const saveAlbumList = []
        for (const album of albumList.value) {
          if (album.id) {
            if (album.deleted) {
              saveAlbumList.push(album)
              calcTargetAlbumIdList.value.push(album.albumId)
            }
          } else {
            if (!album.deleted) {
              saveAlbumList.push(album)
              calcTargetAlbumIdList.value.push(album.albumId)
            }
          }
        }
        form.value.albumList = saveAlbumList
        const saveMasterList = []
        for (const master of masterList.value) {
          if (master.id) {
            if (master.deleted) {
              saveMasterList.push(master)
              calcTargetMasterIdList.value.push(master.masterId)
            }
          } else {
            if (!master.deleted) {
              saveMasterList.push(master)
              calcTargetMasterIdList.value.push(master.masterId)
            }
          }
        }
        form.value.masterList = saveMasterList
        const oldMd5 = form.value.conditionMd5
        form.value.conditionMd5 = toMd5(form.value.conditionList)
        if (!form.value.id) {
          form.value.id = await contractFunc.insert(session.session.company.id, form.value)
        } else {
          // for (const suspension of suspensionList.value) {
          //   await axios.put(process.env.VUE_APP_RED_ENDPOINT + '/contract/suspension/' + suspension.id + '/' + suspension.retroactive_target, {}, {
          //     headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
          //   })
          // }
          await contractFunc.update(form.value.id, form.value)
        }
        contractFunc.indexUpdate(form.value.id)
        // 再計算
        if (oldMd5 && oldMd5 !== toMd5(form.value.conditionList)) {
          albumList.value.forEach(album => {
            albumFunc.calculate(album.id)
          })
          masterList.value.forEach(master => {
            masterFunc.calculate(master.id)
          })
        } else {
          Array.from(new Set(calcTargetAlbumIdList.value)).forEach(albumId => {
            albumFunc.calculate(albumId)
          })
          Array.from(new Set(calcTargetMasterIdList.value)).forEach(masterId => {
            masterFunc.calculate(masterId)
          })
        }
        await router.push('/red/contract/disp/' + form.value.id)
        processModal.hide()
      }
    }
    const openDeleteModal = () => {
      deleteModal.show()
    }
    const executeDelete = async () => {
      await axios.delete(process.env.VUE_APP_RED_MASTER_ENDPOINT + '/contract/' + form.value.id, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      })
      deleteModal.hide()
      await router.push('/red/contract/list')
    }
    const openRightsHolderSearch = () => {
      rightsHolderSearchResult.value = null
      rightsHolderSearchList.value = []
      rightsHolderCondition.page = 0
      rightsHolderCondition.searchString = ''
      rightsHolderModal.show()
    }
    const rightsHolderFind = async () => {
      rightsHolderSearchResult.value = await rightsHolderFunc.find(session.session.company.id, rightsHolderCondition)
      rightsHolderSearchList.value = rightsHolderSearchResult.value.list
    }
    const rightsHolderClearSearch = () => {
      rightsHolderCondition.searchString = ''
      rightsHolderCondition.page = 0
      rightsHolderFind()
    }
    const rightsHolderFirstPage = () => {
      rightsHolderCondition.page = 0
      rightsHolderFind()
    }
    const rightsHolderPrevPage = () => {
      rightsHolderCondition.page = rightsHolderCondition.page - 1
      rightsHolderFind()
    }
    const rightsHolderNextPage = () => {
      rightsHolderCondition.page = rightsHolderCondition.page + 1
      rightsHolderFind()
    }
    const rightsHolderLastPage = () => {
      rightsHolderCondition.page = rightsHolderSearchResult.value.allPages - 1
      rightsHolderFind()
    }
    const selectRightsHolder = (index) => {
      const rightsHolder = rightsHolderSearchList.value[index]
      form.value.rightsHolderId = rightsHolder.id
      form.value.rightsHolderName = rightsHolder.name
      rightsHolderModal.hide()
    }
    const clearSelectedRightsHolder = () => {
      form.value.rightsHolderId = null
      form.value.rightsHolderName = ''
    }
    const openAlbumSearch = () => {
      albumSearchResult.value = null
      albumSearchList.value = []
      albumCondition.page = 0
      albumCondition.searchString = ''
      albumModal.show()
    }
    const albumFind = async () => {
      albumSearchResult.value = await albumFunc.findNoBreakDown(session.session.company.id, albumCondition)
      albumSearchList.value = albumSearchList.value.concat(albumSearchResult.value.list)
    }
    const selectAlbum = () => {
      albumSearchList.value.forEach(album => {
        if (album.selected) {
          albumList.value.unshift({
            albumId: album.id,
            albumName: album.name,
            catalogNo: album.catalogNo,
            artistName: album.artistName
          })
        }
      })
      albumModal.hide()
    }
    const openMasterSearch = () => {
      masterSearchResult.value = null
      masterSearchList.value = []
      masterCondition.page = 0
      masterCondition.searchString = ''
      masterModal.show()
    }
    const masterFind = async () => {
      masterSearchResult.value = await masterFunc.find(session.session.company.id, masterCondition)
      masterSearchList.value = masterSearchList.value.concat(masterSearchResult.value.list)
    }
    const selectMaster = () => {
      masterSearchList.value.forEach(master => {
        if (master.selected) {
          masterList.value.push({
            masterId: master.id,
            masterName: master.displayName,
            catalogNo: master.catalogNo,
            artistName: master.artistName
          })
        }
      })
      masterModal.hide()
    }
    const init = async (id) => {
      showDeleteButton.value = false
      if (route.path.includes('/add')) {
        form.value = {
          id: null,
          code: '',
          name: '',
          rightsHolderId: null,
          rightsHolderName: '',
          status: 'agree',
          useContractSetting: false,
          invoiceExemptionTaxPayable: 'pay',
          invoiceSmallException: 'adapt',
          invoiceTransitionalMeasures: 'adapt',
          memo: '',
          albumList: [],
          masterList: [],
          conditionList: []
        }
      } else {
        form.value = await contractFunc.get(id)
        await findContractAlbum()
        await findContractMaster()
        if (route.path.includes('/copy')) {
          form.value.id = null
          form.value.code = null
          form.value.name = 'コピー 〜 ' + form.value.name
          messages.value = ['コピーされました。保存ボタンを押すまでは登録されていませんのでご注意ください。']
          messageToast.show()
        }
      }
    }
    const findContractAlbum = async () => {
      albumResult.value = await albumFunc.findByContract(form.value.id, albumSearchString.value, albumPage.value)
      albumList.value = albumList.value.concat(albumResult.value.list)
      for (const album of albumList.value) {
        album.albumId = album.id
      }
    }
    const findContractMaster = async () => {
      masterResult.value = await masterFunc.findByContract(form.value.id, masterSearchString.value, masterPage.value)
      masterList.value = masterList.value.concat(masterResult.value.list)
      for (const master of masterList.value) {
        master.masterId = master.id
        master.masterName = master.displayName
      }
    }
    const openConditionModal = async (index) => {
      conditionEditIndex.value = index
      if (conditionEditIndex.value === null) {
        conditionForm.value = {
          useTypeId: 'none',
          userSalesId: 'none',
          calcType: 'unit_rate',
          royaltyRateType: 'decimal',
          royaltyRate: 0,
          royaltyRateC: 0,
          royaltyRateM: 1,
          unitPrice: 0,
          fixPrice: 0,
          fixPricePayCountType: 'one',
          jacketDeductionRateType: 'decimal',
          jacketDeductionRate: 0,
          jacketDeductionRateC: 0,
          jacketDeductionRateM: 1,
          shipRateType: 'decimal',
          shipRate: 100,
          shipRateC: 1,
          shipRateM: 1,
          slide: false,
          slideTarget: 'ship_to',
          slideStartNo: 0,
          slideStartNoType: 'gte',
          slideEndNo: 0,
          slideEndNoType: 'lt',
          unitPriceBasePrice: 'sales_price_out_tax',
          jacketDeductionBasePrice: 'sales_price_out_tax',
          topOffList: [{
            topOffType: 'rate',
            topOffRateType: 'decimal',
            topOffRate: 0,
            topOffRateC: 0,
            topOffRateM: 1,
            topOffAmount: 0
          }]
        }
      } else {
        conditionForm.value = form.value.conditionList[conditionEditIndex.value]
        console.log(conditionForm.value)
        if (!conditionForm.value.useTypeId) {
          conditionForm.value.useTypeId = 'none'
        }
        if (!conditionForm.value.userSalesId) {
          conditionForm.value.userSalesId = 'none'
        }
        await changeUseType()
      }
      conditionForm.value.useTypeList = await useTypeFunc.find(session.session.company.id)
      conditionForm.value.royaltyTypeList = await royaltyTypeFunc.find(session.session.company.id)
      if (conditionForm.value.royaltyTypeList.length > 0 && !conditionForm.value.royaltyTypeId) {
        conditionForm.value.royaltyTypeId = conditionForm.value.royaltyTypeList[0].id
      }
      conditionModal.show()
    }
    const changeUseType = async () => {
      if (conditionEditIndex.value === null) {
        conditionForm.value.userSalesId = 'none'
      }
      if (conditionForm.value.useTypeId !== 'none') {
        conditionForm.value.userSalesList = await userFunc.findUserSales(conditionForm.value.useTypeId)
      }
    }
    const addCondition = () => {
      getConditionNames(conditionForm.value)
      if (conditionEditIndex.value === null) {
        form.value.conditionList.push(conditionForm.value)
      } else {
        form.value.conditionList[conditionEditIndex.value] = conditionForm.value
      }
      conditionModal.hide()
    }
    const getConditionNames = async (condition) => {
      if (condition.useTypeId && condition.useTypeId !== 'none') {
        const useType = await useTypeFunc.get(condition.useTypeId)
        condition.useTypeName = useType.abbreviation
      } else {
        condition.useTypeName = '-'
      }
      if (condition.userSalesId && condition.userSalesId !== 'none') {
        const userSales = await userFunc.getUserSales(condition.userSalesId)
        condition.userSalesName = userSales.name
      }
      const royaltyType = await royaltyTypeFunc.get(condition.royaltyTypeId)
      condition.royaltyTypeName = royaltyType.abbreviation
    }
    const toMd5 = (o) => {
      // eslint-disable-next-line no-undef
      const copy = structuredClone(o)
      copy.forEach(x => {
        delete x.id
        delete x.createdAt
        delete x.createdBy
        delete x.updatedAt
        delete x.updatedBy
      })
      const json = {
        status: form.value.status,
        list: copy
      }
      return Md5(JSON.stringify(json)).toString()
    }
    const addTopOff = () => {
      conditionForm.value.topOffList.push({
        topOffType: 'rate',
        topOffRateType: 'decimal',
        topOffRate: 0,
        topOffRateC: 0,
        topOffRateM: 1,
        topOffAmount: 0
      })
    }
    const dragList = (event, dragIndex) => {
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.setData('drag-index', dragIndex)
    }
    const dropList = (event, dropIndex) => {
      const dragIndex = parseInt(event.dataTransfer.getData('drag-index'))
      const deleteList = conditionForm.value.topOffList.splice(dragIndex, 1)
      conditionForm.value.topOffList.splice(dropIndex, 0, deleteList[0])
    }
    onMounted(async () => {
      errorToast = new Toast(errorToastDiv.value, {})
      messageToast = new Toast(messageToastDiv.value, {})
      loadingModal = new Modal(loadingModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      processModal = new Modal(processModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      deleteModal = new Modal(deleteModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      rightsHolderModal = new Modal(rightsHolderModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      albumModal = new Modal(albumModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      masterModal = new Modal(masterModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      conditionModal = new Modal(conditionModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      loadingModal.show()
      session.session = await auth.getSession()
      await init(route.params.id)
      loadingModal.hide()
    })
    return {
      router,
      loadingModalDiv,
      errors,
      processMessage,
      processModalDiv,
      errorToastDiv,
      messages,
      messageToastDiv,
      deleteModalDiv,
      showDeleteButton,
      form,
      rightAreaMode,
      rightsHolderModalDiv,
      rightsHolderSearchResult,
      rightsHolderSearchList,
      albumModalDiv,
      albumSearchResult,
      albumSearchList,
      masterModalDiv,
      masterSearchResult,
      masterSearchList,
      save,
      openDeleteModal,
      executeDelete,
      openRightsHolderSearch,
      rightsHolderFind,
      rightsHolderClearSearch,
      rightsHolderFirstPage,
      rightsHolderPrevPage,
      rightsHolderNextPage,
      rightsHolderLastPage,
      selectRightsHolder,
      clearSelectedRightsHolder,
      openAlbumSearch,
      albumFind,
      selectAlbum,
      openMasterSearch,
      masterFind,
      selectMaster,
      conditionModalDiv,
      openConditionModal,
      conditionForm,
      changeUseType,
      addCondition,
      conditionEditIndex,
      suspensionList,
      rightsHolderCondition,
      albumCondition,
      masterCondition,
      albumResult,
      albumList,
      masterResult,
      masterList,
      albumSearchString,
      albumPage,
      findContractAlbum,
      masterSearchString,
      masterPage,
      findContractMaster,
      addTopOff,
      dragList,
      dropList
    }
  }
})
</script>
