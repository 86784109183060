<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/red/master/list"><i class="fas fa-file-music fa-fw" style="margin-right: 5px;"></i>
                      原盤一覧
                    </router-link>
                  </li>
                  <li v-if="form && form.id" class="breadcrumb-item">
                    <router-link :to="'/red/master/disp/' + form.id"><i class="fas fa-eye" style="margin-right: 5px;"></i>閲覧</router-link>
                  </li>
                  <li v-if="form && form.id" class="breadcrumb-item active" aria-current="page"><i class="fas fa-edit" style="margin-right: 5px;"></i>編集</li>
                  <li v-else class="breadcrumb-item active" aria-current="page"><i class="fas fa-plus" style="margin-right: 5px;"></i>追加</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <button @click="save" class="btn btn-primary" style="margin-left: 5px;"><i class="fas fa-save fa-fw" style="margin-right: 5px;"></i>保存</button>
              <button v-if="showDeleteButton" @click="openDeleteModal" class="btn btn-danger" style="margin-left: 5px;"><i class="fas fa-trash-alt fa-fw" style="margin-right: 5px;"></i>削除</button>
              <button v-if="form && form.id" @click="showDeleteButton = !showDeleteButton" class="btn" style="padding-right: 0;"><i class="fas fa-caret-left"></i></button>
            </div>
          </div>
        </div>
        <div v-if="form" class="text-start" style="position: fixed; top: 65px; left: 270px; right: 30px; bottom: 50px; overflow: auto;">
          <div class="container">
            <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>名前 ※明細書に使用</div>
            <div class="form-edit-line">
              <input v-model="form.name" @change="changeName" type="text" class="form-control">
            </div>
            <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>名前（表示用）※検索結果にのみ使用</div>
            <div class="form-edit-line">
              <input v-model="form.displayName" type="text" class="form-control">
            </div>
            <div class="form-label align-bottom">カナ</div>
            <div class="form-edit-line">
              <input v-model="form.kana" type="text" class="form-control">
            </div>
            <div class="form-label align-bottom">英語名</div>
            <div class="form-edit-line">
              <input v-model="form.nameEn" type="text" class="form-control">
            </div>
            <div class="form-label align-bottom">管理コード</div>
            <div class="form-edit-line">
              <input v-model="form.code" type="text" class="form-control" maxlength="20" style="width: 250px;">
            </div>
            <div class="form-label align-bottom">
              <button @click="addIsrc" class="btn btn-outline-primary btn-sm" style="margin-bottom: 5px;"><i class="fas fa-plus"></i></button>
              ISRC
            </div>
            <div v-for="(isrc, index) in form.isrcList" :key="index" class="form-edit-line">
              <div class="input-group">
                <input v-model="isrc.isrc" type="text" class="form-control" style="flex: 0 1 250px;" maxlength="20">
                <button @click="deleteIsrc(index)" class="btn btn-outline-danger"><i class="fas fa-trash-alt"></i></button>
              </div>
            </div>
            <div class="form-label align-bottom">アーティスト</div>
            <div class="form-edit-line">
              <div class="input-group">
                <input v-model="form.artistName" type="text" class="form-control" readonly="readonly">
                <button v-if="form.artistId" @click="clearSelectedArtist" class="btn btn-outline-primary"><i class="fas fa-undo"></i></button>
                <button @click="openArtistSearch" class="btn btn-primary"><i class="fas fa-search"></i></button>
              </div>
            </div>
            <div class="form-label align-bottom">プロジェクト</div>
            <div class="form-edit-line">
              <div class="input-group">
                <input v-model="form.projectName" type="text" class="form-control" readonly="readonly">
                <button v-if="form.projectId" @click="clearSelectedProject" class="btn btn-outline-primary"><i class="fas fa-undo"></i></button>
                <button @click="openProjectSearch" class="btn btn-primary"><i class="fas fa-search"></i></button>
              </div>
            </div>
            <div class="form-label align-bottom">演奏時間</div>
            <div class="form-edit-line">
              <div class="input-group">
                <input v-model="form.playTimeH" type="number" class="form-control" min="0" style="flex: 0 1 65px;">
                <span class="input-group-text">時間</span>
                <input v-model="form.playTimeM" type="number" class="form-control" min="0" max="59" style="flex: 0 1 65px;">
                <span class="input-group-text">分</span>
                <input v-model="form.playTimeS" type="number" class="form-control" min="0" max="59" style="flex: 0 1 65px;">
                <span class="input-group-text">秒</span>
              </div>
            </div>
            <div class="card" style="margin-bottom: 10px;">
              <div class="card-header">計算</div>
              <div class="card-body" style="padding: 10px 5px 5px 10px;">
                <div class="form-label align-bottom">計算対象</div>
                <div class="form-edit-line">
                  <select v-model="form.calculateTarget" class="form-select">
                    <option value="true">分配計算の対象とする</option>
                    <option value="false">分配計算の対象外とし、本原盤に対する入金は全て自社取り分とする</option>
                  </select>
                </div>
                <div v-if="form.calculateTarget === true || form.calculateTarget === 'true'" class="form-label align-bottom">計算保留</div>
                <div v-if="form.calculateTarget === true || form.calculateTarget === 'true'" class="form-edit-line">
                  <select v-model="form.suspension" class="form-select">
                    <option value="false">分配計算の対象とする</option>
                    <option value="true">分配計算を保留する</option>
                  </select>
                </div>
              </div>
            </div>
            <div v-if="form.userCodeList && form.userCodeList.length > 0" class="card" style="margin-bottom: 10px;">
              <div class="card-header">報告コード</div>
              <div class="card-body" style="padding: 5px;">
                <table class="table table-responsive">
                  <tbody>
                  <tr v-for="(userCode, index) in form.userCodeList" :key="index" :class="{ 'deleted-row': userCode.deleted }">
                    <td>{{userCode.userName}}</td>
                    <td>{{userCode.code}}</td>
                    <td style="width: 60px;" class="text-end">
                      <button @click="userCode.deleted = !userCode.deleted" class="btn btn-outline-danger btn-sm"><i class="fas fa-trash-alt"></i></button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-if="form.poolList && form.poolList.length > 0" class="card" style="margin-bottom: 10px;">
              <div class="card-header">保留期</div>
              <div class="card-body" style="padding: 5px;">
                <table class="table table-responsive">
                  <tbody>
                  <tr v-for="pool in form.poolList" :key="pool.id">
                    <td>{{pool.divYear}}年{{pool.divTerm}}期</td>
                    <td class="text-end">
                      <div class="form-check form-check-inline">
                        <input v-model="pool.retroactiveTarget" class="form-check-input" type="radio" name="suspensionTarget" id="suspensionTargetPay" value="true">
                        <label class="form-check-label" for="suspensionTargetPay">解除時支払対象</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input v-model="pool.retroactiveTarget" class="form-check-input" type="radio" name="suspensionTarget" id="suspensionTargetHold" value="false">
                        <label class="form-check-label" for="suspensionTargetHold">解除しても支払わない</label>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="form-label align-bottom">メモ</div>
            <div class="form-edit-line">
              <textarea v-model="form.memo" class="form-control" rows="8"></textarea>
            </div>
            <div style="height: 50px;"></div>
          </div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="deleteModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">原盤の削除</h5>
          </div>
          <div v-if="form" class="modal-body text-start">
            <div class="alert alert-danger">以下の原盤を削除しようとしています。削除すると、原盤情報の確認ができなくなり、該当原盤が紐づいている原盤、アルバム、原盤の原盤情報がクリアされます。また、該当原盤に関連する分配結果も削除されますが、過去の支払明細からは削除されません。
            </div>
            <div class="form-label align-bottom">名前</div>
            <div class="form-edit-line">
              {{ form.name }}
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i
                  class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="executeDelete" type="button" class="btn btn-danger" style="margin-right: 5px;"><i
                  class="fas fa-trash-alt" style="margin-right: 5px;"></i>注意事項を理解した上で削除を実行する</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="artistModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">アーティスト選択</h5>
          </div>
          <div class="modal-body text-start">
            <div class="input-group" style="margin-top: 15px;">
              <input @keypress.enter="artistCondition.page = 0; artistFind();" v-model="artistCondition.searchString" type="text"
                class="form-control" placeholder="検索条件">
              <button v-show="artistCondition.searchString" @click="artistClearSearch" class="btn btn-outline-primary"
                title="クリア"><i class="fas fa-undo"></i></button>
              <button @click="artistCondition.page = 0; artistFind();" class="btn btn-primary"><i
                  class="fas fa-search"></i></button>
            </div>
            <table v-if="artistSearchResult && artistSearchResult.allRecords > 0"
              class="table table-responsive table-hover">
              <thead>
                <tr>
                  <th>名前</th>
                  <th style="width: 70px;"></th>
                </tr>
              </thead>
              <tbody v-if="artistSearchList">
                <tr v-for="(artist, index) in artistSearchList" :key="artist.id" class="text-start">
                  <td>{{ artist.name }}</td>
                  <td class="text-end">
                    <button @click="selectArtist(index)" class="btn btn-outline-primary btn-sm"><i
                        class="fas fa-plus"></i></button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="row">
              <div class="col-6 d-flex justify-content-start">
                <span v-if="artistSearchResult && artistSearchResult.allRecords > 0">{{ artistSearchResult.allRecords }}件の一致データ</span>
                <span v-else style="margin-top: 20px;">一致データが存在しません</span>
              </div>
              <div v-if="artistSearchResult && artistSearchResult.allRecords > 0"
                class="col-6 d-flex justify-content-end">
                <nav v-if="artistSearchResult" aria-label="Page navigation">
                  <ul class="pagination">
                    <li v-if="artistCondition.page !== 0" class="page-item"><button @click="artistFirstPage" class="page-link"
                        href="#"><i class="fas fa-fast-backward"></i></button></li>
                    <li v-if="artistCondition.page !== 0" class="page-item"><button @click="artistPrevPage" class="page-link"
                        href="#"><i class="fas fa-chevron-left"></i></button></li>
                    <li class="page-item"><span class="page-link">{{ artistCondition.page + 1 }}/{{ artistSearchResult.allPages }}ページ</span></li>
                    <li v-if="artistCondition.page !== artistSearchResult.allPages - 1" class="page-item"><button
                        @click="artistNextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button>
                    </li>
                    <li v-if="artistCondition.page !== artistSearchResult.allPages - 1" class="page-item"><button
                        @click="artistLastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i
                  class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="projectModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">プロジェクト選択</h5>
          </div>
          <div class="modal-body text-start">
            <div class="input-group" style="margin-top: 15px;">
              <input @keypress.enter="projectCondition.page = 0; projectFind();" v-model="projectCondition.searchString" type="text" class="form-control" placeholder="検索条件">
              <button v-show="projectCondition.searchString" @click="projectClearSearch" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
              <button @click="projectCondition.page = 0; projectFind();" class="btn btn-primary"><i class="fas fa-search"></i></button>
            </div>
            <table v-if="projectSearchResult && projectSearchResult.allRecords > 0" class="table table-responsive table-hover">
              <thead>
              <tr>
                <th>名前</th>
                <th style="width: 70px;"></th>
              </tr>
              </thead>
              <tbody v-if="projectSearchList">
              <tr v-for="(project, index) in projectSearchList" :key="project.id" class="text-start">
                <td>{{ project.name }}</td>
                <td class="text-end">
                  <button @click="selectProject(index)" class="btn btn-outline-primary btn-sm"><i class="fas fa-plus"></i></button>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="row">
              <div class="col-6 d-flex justify-content-start">
                <span v-if="projectSearchResult && projectSearchResult.allRecords > 0">{{ projectSearchResult.allRecords }}件の一致データ</span>
                <span v-else style="margin-top: 20px;">一致データが存在しません</span>
              </div>
              <div v-if="projectSearchResult && projectSearchResult.allRecords > 0" class="col-6 d-flex justify-content-end">
                <nav v-if="projectSearchResult" aria-label="Page navigation">
                  <ul class="pagination">
                    <li v-if="projectCondition.page !== 0" class="page-item">
                      <button @click="projectFirstPage" class="page-link" href="#"><i class="fas fa-fast-backward"></i></button>
                    </li>
                    <li v-if="projectCondition.page !== 0" class="page-item">
                      <button @click="projectPrevPage" class="page-link" href="#"><i class="fas fa-chevron-left"></i></button>
                    </li>
                    <li class="page-item"><span class="page-link">{{ projectCondition.page + 1 }}/{{ projectSearchResult.allPages }}ページ</span></li>
                    <li v-if="projectCondition.page !== projectSearchResult.allPages - 1" class="page-item">
                      <button @click="projectNextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button>
                    </li>
                    <li v-if="projectCondition.page !== projectSearchResult.allPages - 1" class="page-item">
                      <button @click="projectLastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="processModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">処理中</h5>
          </div>
          <div class="modal-body text-start">
            {{ processMessage }}
          </div>
        </div>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-danger" role="alert" aria-live="assertive" aria-atomic="true"
      ref="errorToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">エラー</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(error, index) in errors" :key="index">{{ error }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import navComponent from '@/components/Nav'
import { Modal, Toast } from 'bootstrap'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import footerComponent from '@/components/Footer.vue'
import { masterFunction } from './function'
import { artistStore, projectStore } from './store'
import { artistFunction } from '../../common/artist/function'
import { projectFunction } from '../../common/project/function'
import { redCompanyFunction } from '@/functions/common'

export default defineComponent({
  components: { footerComponent, navComponent },
  setup () {
    const auth = authFunction()
    const session = sessionStore()
    const route = useRoute()
    const router = useRouter()
    const errors = ref([])
    const errorToastDiv = ref(null)
    let errorToast = null
    const processMessage = ref('')
    const processModalDiv = ref(null)
    let processModal = null
    const deleteModalDiv = ref(null)
    let deleteModal = null
    const showDeleteButton = ref(false)
    const masterFunc = masterFunction()
    const companyFunc = redCompanyFunction()
    // Form
    const form = ref(null)
    const isMakeMaster = ref(false)
    const rightAreaMode = ref('CODE')
    // アーティスト選択
    const artistModalDiv = ref(null)
    let artistModal = null
    const artistFunc = artistFunction()
    const artistCondition = artistStore()
    const artistSearchResult = ref(null)
    const artistSearchList = ref([])
    // プロジェクト選択
    const projectModalDiv = ref(null)
    let projectModal = null
    const projectFunc = projectFunction()
    const projectCondition = projectStore()
    const projectSearchResult = ref(null)
    const projectSearchList = ref([])
    // 保留
    const suspensionList = ref([])
    // メソッド
    const save = async () => {
      errors.value = []
      if (!form.value.name) {
        errors.value.push('名前を入力してください')
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        processMessage.value = '保存処理中です。しばらくこのままでお待ちください。'
        processModal.show()
        if (!form.value.id) {
          form.value.id = await masterFunc.insert(session.session.company.id, form.value)
        } else {
          await masterFunc.update(form.value.id, form.value)
        }
        masterFunc.updateIndex(form.value.id)
        const term = await companyFunc.getCurrentDivTerm(session.session.company.id)
        masterFunc.matching(form.value.id, term.id, function () {
          masterFunc.calculate(form.value.id)
        })
        processModal.hide()
        await router.push('/red/master/disp/' + form.value.id)
      }
    }
    const openDeleteModal = () => {
      deleteModal.show()
    }
    const executeDelete = async () => {
      await masterFunc.remove(form.value.id)
      deleteModal.hide()
      await router.push('/red/master/list')
    }
    const openArtistSearch = () => {
      artistSearchResult.value = null
      artistSearchList.value = []
      artistCondition.page = 0
      artistCondition.searchString = ''
      artistModal.show()
    }
    const artistFind = async () => {
      artistSearchResult.value = await artistFunc.find(artistCondition)
      artistSearchList.value = artistSearchResult.value.list
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
    const artistClearSearch = () => {
      artistCondition.searchString = ''
      artistCondition.page = 0
      artistFind()
    }
    const artistFirstPage = () => {
      artistCondition.page = 0
      artistFind()
    }
    const artistPrevPage = () => {
      artistCondition.page = artistCondition.page - 1
      artistFind()
    }
    const artistNextPage = () => {
      artistCondition.page = artistCondition.page + 1
      artistFind()
    }
    const artistLastPage = () => {
      artistCondition.page = artistSearchResult.value.allPages - 1
      artistFind()
    }
    const selectArtist = (index) => {
      const artist = artistSearchList.value[index]
      form.value.artistId = artist.id
      form.value.artistName = artist.name
      artistModal.hide()
    }
    const clearSelectedArtist = () => {
      form.value.artistId = null
      form.value.artistName = ''
    }
    const openProjectSearch = () => {
      projectSearchResult.value = null
      projectSearchList.value = []
      projectCondition.page = 0
      projectCondition.searchString = ''
      projectModal.show()
    }
    const projectFind = async () => {
      projectSearchResult.value = await projectFunc.find(projectCondition)
      projectSearchList.value = projectSearchResult.value.list
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
    const projectClearSearch = () => {
      projectCondition.searchString = ''
      projectCondition.page = 0
      projectFind()
    }
    const projectFirstPage = () => {
      projectCondition.page = 0
      projectFind()
    }
    const projectPrevPage = () => {
      projectCondition.page = projectCondition.page - 1
      projectFind()
    }
    const projectNextPage = () => {
      projectCondition.page = projectCondition.page + 1
      projectFind()
    }
    const projectLastPage = () => {
      projectCondition.page = projectSearchResult.value.allPages - 1
      projectFind()
    }
    const selectProject = (index) => {
      const project = projectSearchList.value[index]
      form.value.projectId = project.id
      form.value.projectName = project.name
      projectModal.hide()
    }
    const clearSelectedProject = () => {
      form.value.projectId = null
      form.value.projectName = ''
    }
    const changeName = () => {
      if (!form.value.displayName) {
        form.value.displayName = form.value.name
      }
    }
    async function init (id) {
      showDeleteButton.value = false
      if (route.path.includes('/add')) {
        form.value = {
          id: null,
          code: '',
          name: '',
          kana: '',
          nameEn: '',
          isrcList: [{ isrc: '' }],
          memo: '',
          artistId: null,
          artistName: '',
          projectId: null,
          projectName: '',
          calculateTarget: 'true',
          suspension: 'false'
        }
      } else {
        form.value = await masterFunc.get(id)
      }
    }
    const addIsrc = () => {
      form.value.isrcList.push({
        isrc: ''
      })
    }
    const deleteIsrc = (index) => {
      form.value.isrcList.splice(index, 1)
      if (form.value.isrcList.length === 0) {
        addIsrc()
      }
    }
    onMounted(async () => {
      session.session = await auth.getSession()
      errorToast = new Toast(errorToastDiv.value, {})
      processModal = new Modal(processModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      deleteModal = new Modal(deleteModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      artistModal = new Modal(artistModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      projectModal = new Modal(projectModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      await init(route.params.id)
    })
    return {
      router,
      errors,
      errorToastDiv,
      processMessage,
      processModalDiv,
      deleteModalDiv,
      showDeleteButton,
      form,
      isMakeMaster,
      rightAreaMode,
      artistModalDiv,
      artistCondition,
      artistSearchResult,
      artistSearchList,
      projectModalDiv,
      projectCondition,
      projectSearchResult,
      projectSearchList,
      save,
      openDeleteModal,
      executeDelete,
      openArtistSearch,
      artistFind,
      artistClearSearch,
      artistFirstPage,
      artistPrevPage,
      artistNextPage,
      artistLastPage,
      selectArtist,
      clearSelectedArtist,
      openProjectSearch,
      projectFind,
      projectClearSearch,
      projectFirstPage,
      projectPrevPage,
      projectNextPage,
      projectLastPage,
      selectProject,
      clearSelectedProject,
      changeName,
      suspensionList,
      addIsrc,
      deleteIsrc
    }
  }
})
</script>
